import React, { useEffect } from 'react'
import { SalesTable } from 'components/sales/table/sales-table'
import { useSalesReport } from 'hooks/sales/use-sales-report'
import Firebase from 'components/Firebase'
import { useDispatch } from 'react-redux'
import SalesAction from 'actions/SalesAction'
import { formatTime } from 'utils/time-formatter'

interface Props {
  firebase: Firebase;
}

const headerContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '1rem',
}

export const AdminSalesPanel = (props: Props) => {
  const { data, lastUpdated } = useSalesReport(props.firebase)
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(SalesAction.ResetReport());
    }
  }, [])

  return <div>
    <div className={'header-container'}>
      <div><h4>{data?.reportTitle}</h4></div>
      <div className={'grey-text'}>last updated {formatTime(lastUpdated, 'FULL')}</div>
    </div>
    <SalesTable today={data?.today}
                yesterday={data?.yesterday}/>
  </div>
}
