import React, { PropsWithChildren } from 'react'

interface Props {
  condition: boolean;
}

const Visibility = (props: PropsWithChildren<Props>) => {
  return props.condition ? <>{props.children}</> : null;
}

export default Visibility;