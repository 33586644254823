import { SemanticLoader } from 'components/semantic-loader/semantic-loader';
import { cloneDeep } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Divider, Dropdown, Header, Segment, Table } from 'semantic-ui-react';
import {
  closeOrderModal,
  filterStores,
  getStoreDetails,
  getStoreList,
  sortStores,
  storeUpdatePerPage,
} from '../../actions';
import { getSubordinates, getSuperiors } from '../../actions/UserActions';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import { StoreDetailsModal } from '../../components/stores/modal/store-details-modal';
import { StoreTableBody } from '../../components/stores/store-table-body';
import { StoreTableFooter } from '../../components/stores/store-table-footer';
import { StoreTableHeader } from '../../components/stores/store-table-header';
import { PER_PAGE, POLLING_RATE, STATES } from '../../constants/variable';
import { formatTime } from '../../utils/time-formatter';
import { transformModalStatus } from '../../utils/transformers/store-transformers';
import { transformPerPage } from '../../utils/transformers/transformers';
import {
  transformStates,
  transformUserOptions,
} from '../../utils/transformers/user-transformer';

const statesDropdown = () => transformStates(STATES);
const perPageDropdown = () => transformPerPage(PER_PAGE);

const isMY = () => window.location.origin.includes('.com.my');

class StorePageBase extends Component {
  _isMounted = false;

  handleDropdownOnChange = (dropdown, data) => {
    let committing = cloneDeep(this.props.pagination);

    // reset current page to avoid querying page that does not exist
    committing.currentPage = 1;
    switch (dropdown) {
      case 'state':
        committing.filters.state = data.value;
        this.props.filterStores(this.props.firebase, committing);
        break;
      case 'om':
        committing.filters.om = data.value;
        this.props.filterStores(this.props.firebase, committing);
        break;
      case 'dm':
        committing.filters.dm = data.value;
        this.props.filterStores(this.props.firebase, committing);
        break;
      case 'perPage':
        committing.perPage = data.value;
        // NOTE: reset to 1 to avoid loading pages that does not exist
        committing.currentPage = 1;
        this.props.storeUpdatePerPage(this.props.firebase, committing);
        break;
      default:
        console.error('unrecognised dropdown option.');
    }
  };

  handleSort = (sortKey) => {
    let committing = cloneDeep(this.props.pagination);

    const { key, ascending } = this.props.pagination.sort;

    committing.sort.key = sortKey;
    committing.sort.ascending = sortKey === key ? !ascending : true;

    this.props.sortStores(this.props.firebase, committing);
  };

  componentDidMount() {
    this._isMounted = true;

    // get dropdowns
    this.props.getOMs(this.props.firebase);
    this.props.getDMs(this.props.firebase);

    this.props.getStoreList(this.props.firebase, this.props.pagination);

    this.interval = setInterval(() => {
      // if details of modal needs to be refreshed, will need to
      // write another action creator to handle all of it
      this.props.getStoreList(this.props.firebase, this.props.pagination);
    }, POLLING_RATE.TWENTY_SECONDS);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.modal.idx === this.props.modal.idx) {
      return;
    }

    // when modal is closed, and user open with a new idx
    if (prevProps.modal.idx === -1) {
      const store = this.props.stores[this.props.modal.idx];
      this.modalInterval = setInterval(() => {
        this.props.getStoreDetails(this.props.firebase, store.storeId);
      }, POLLING_RATE.TWENTY_SECONDS);
      return;
    }

    // when user close the modal
    if (this.props.modal.idx === -1) {
      clearInterval(this.modalInterval);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.interval);
    clearInterval(this.modalInterval);
  }

  closeModal() {
    this.props.closeOrderModal();
  }

  render() {
    return (
      <React.Fragment>
        <div className={'page-header'}>
          <div className={'pull-left'}>
            <Header size="medium">Store Summary</Header>
          </div>
          <div className={'pull-right grey-text'}>
            {this.props.updated === 0
              ? 'loading...'
              : `last update ${formatTime(this.props.updated)}`}
          </div>
        </div>
        <Segment>
          <div className={'index-header'}>
            <div className={'pull-left'}>
              <Dropdown
                className={'fit-content'}
                value={this.props.pagination.perPage}
                options={perPageDropdown()}
                onChange={(e, d) => this.handleDropdownOnChange('perPage', d)}
                selection
              />
            </div>
            <div className={'pull-right'}>
              {
                isMY() ? <Dropdown
                  className={'index-header-dropdown'}
                  placeholder={'Filter by State'}
                  clearable
                  search
                  selection
                  options={statesDropdown()}
                  value={this.props.pagination.filters.state}
                  onChange={(e, d) => this.handleDropdownOnChange('state', d)}
                /> : null
              }
              <Dropdown
                className={'index-header-dropdown'}
                placeholder={'Filter by OM'}
                clearable
                search
                selection
                options={transformUserOptions(this.props.oms ?? [])}
                value={this.props.pagination.filters.om}
                onChange={(e, d) => this.handleDropdownOnChange('om', d)}
              />
              <Dropdown
                className={'index-header-dropdown'}
                placeholder={'Filter by DM'}
                clearable
                search
                selection
                options={transformUserOptions(this.props.dms ?? [])}
                value={this.props.pagination.filters.dm}
                onChange={(e, d) => this.handleDropdownOnChange('dm', d)}
              />
            </div>
          </div>
          <Divider />
          <SemanticLoader inverted={true} active={this.props.loading}>
            <Table unstackable celled sortable selectable structured>
              <StoreTableHeader
                column={this.props.pagination.sort.key}
                direction={
                  this.props.pagination.sort.ascending
                    ? 'ascending'
                    : 'descending'
                }
                userEmail={this.props.userEmail}
                handleSort={this.handleSort}
              />
              <StoreTableBody
                firebase={this.props.firebase}
                stores={this.props.stores}
              />
              <StoreTableFooter
                firebase={this.props.firebase}
                pagination={this.props.pagination}
              />
            </Table>
          </SemanticLoader>
          <StoreDetailsModal
            status={this.props.modal.idx !== -1}
            loading={transformModalStatus(this.props.detailsStatus)}
            storeInfo={this.props.stores[this.props.modal.idx]} // current available info from list
            storeDetails={this.props.modal.storeDetails} // further queried details
            closeModal={() => this.closeModal()}
            firebase={this.props.firebase}
          />
        </Segment>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stores: state.store.list.stores,
    pagination: state.store.list.pagination,
    error: state.store.list.error,
    modal: state.store.list.modal,
    loading: state.store.list.loading,
    updated: state.store.list.updated,
    // filters
    userTypes: state.user.shared.userTypes,
    userEmail: state.auth.currentUserInfo?.userInfo.email,
    oms: state.user.shared.superiors,
    dms: state.user.shared.subordinates,
    // modal
    detailsStatus: state.store.list.modal.storeDetails.status,
  };
};

const mapActionToProps = {
  getStoreList: getStoreList,
  filterStores: filterStores,
  storeUpdatePerPage: storeUpdatePerPage,
  sortStores: sortStores,
  closeOrderModal: closeOrderModal,
  getStoreDetails: getStoreDetails,

  getOMs: getSuperiors,
  getDMs: getSubordinates,
};

const condition = (authUser) => authUser != null;

const StorePage = compose(
  withFirebase,
  withRouter,
  withAuthorization(condition),
  connect(mapStateToProps, mapActionToProps),
)(StorePageBase);

export default StorePage;
