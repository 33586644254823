import axios, { AxiosResponse } from 'axios'
import ApiReport from 'interfaces/apis/sales/report'
import ApiReportData from 'interfaces/apis/sales/report-data'

const getReport = (date: string) => (token: string) => {
  return axios.get('/report/query', {
    params: {
      date: date,
    },
    headers: {
      Authorization: token,
    },
  }).then((resp: AxiosResponse<ApiReport>) => resp.data);
}

const getFilteredReport = (date: string, filter: string, value: string) => (token: string) => {
  return axios.get('/report/query', {
    params: {
      date: date,
      [filter]: value,
    },
    headers: {
      Authorization: token,
    },
  }).then((resp: AxiosResponse<ApiReportData>) => resp.data);
}

const SalesApiService = {
  getReport: getReport,
  getFilteredReport: getFilteredReport,
}

export default SalesApiService;
