import { Dispatch } from 'redux'
import { DO_PASSWORD_RESET, DO_PASSWORD_RESET_FAIL, DO_PASSWORD_RESET_SUCCESS, RESET_DO_PASSWORD_RESET } from './types'
import { AxiosResponse } from 'axios'

export const doForgetPassword = (firebase: any, email: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: DO_PASSWORD_RESET
  });

  firebase.doPasswordReset(email)
    .then(() => {
      onDoForgetPasswordSuccess(dispatch);
    })
    .catch((error: any) => {
      onDoForgetPasswordFail(dispatch, error);
    })
}

const onDoForgetPasswordSuccess = (dispatch: Dispatch) => {
  dispatch({
    type: DO_PASSWORD_RESET_SUCCESS,
  })
}

const onDoForgetPasswordFail = (dispatch: Dispatch, error: any) => {
  dispatch({
    type: DO_PASSWORD_RESET_FAIL,
    payload: error,
  })
}

export const resetForgetPassword = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RESET_DO_PASSWORD_RESET,
  });
}
