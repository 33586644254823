import { TableHeader } from 'components/table/table-header';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { InStoreOrder } from '../../../interfaces/entities/stores/modal/order';
import { formatTime } from '../../../utils/time-formatter';
import { generateEmptyRow } from '../../table/utils';
import { OrderDetails } from './store-details-modal';

const processTime = (millis: number) =>
  millis === 0 ? 'N/A' : formatTime(millis, 'TIME_ONLY');

const headers = [
  'Order Number',
  'Platform',
  'Customer Name',
  'Contact No',
  'Timed/Now',
  'Makeline Items',
  'Order Time',
  'Collect Time',
  'Order Status',
];

const InStoreOrderRow = ({
  inStore,
  setOrder,
}: {
  inStore: InStoreOrder;
  setOrder: (order: OrderDetails | undefined) => (e: any, d: any) => void;
}) => (
  <Table.Row
    className={'clickable'}
    onClick={setOrder({
      orderNumber: inStore.orderId,
      activities: inStore.activityInfo,
    })}
  >
    <Table.Cell>{inStore.orderId}</Table.Cell>
    <Table.Cell>{inStore.platform}</Table.Cell>
    <Table.Cell>{inStore.customerName}</Table.Cell>
    <Table.Cell>{inStore.contact}</Table.Cell>
    <Table.Cell>{inStore.timedNow}</Table.Cell>
    <Table.Cell>{inStore.pieCount}</Table.Cell>
    <Table.Cell>{processTime(inStore.orderTime)}</Table.Cell>
    <Table.Cell>{processTime(inStore.collectTime)}</Table.Cell>
    <Table.Cell>{inStore.status}</Table.Cell>
  </Table.Row>
);

export const InStoreOrders = ({
  activeInStores,
  setOrder,
}: {
  activeInStores: InStoreOrder[];
  setOrder: (order: OrderDetails | undefined) => (e: any, d: any) => void;
}) => (
  <Table unstackable celled selectable sortable structured>
    <TableHeader headers={headers} alignment={'center'} />
    <Table.Body>
      {activeInStores.length === 0
        ? generateEmptyRow()
        : activeInStores.map((i, idx) => (
            <InStoreOrderRow inStore={i} key={idx} setOrder={setOrder} />
          ))}
    </Table.Body>
  </Table>
);
