import { cloneDeep } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Container, Divider, Header, Segment } from 'semantic-ui-react';
import { getStoreNames } from '../../actions';
import {
  getSuperiors,
  getUserTypes,
  resetAddUser,
} from '../../actions/UserActions';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import { AddUserForm } from '../../components/users/add/add-user-form';
import { Alert } from '../../components/utils/alerts';
import { isAdmin } from '../../utils/access-control';

class AddUserPageBase extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      status: 'EDITING',
      header: '',
      message: '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.getUserTypes(this.props.firebase);
    // get store list
    this.props.getSuperiors(this.props.firebase);
    this.props.getStoreNames(this.props.firebase);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.resetAddUser();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.status !== this.state.status) {
      this.handleStatusChange(prevState.status, this.state.status);
    }
  }

  handleStatusChange = (previous, current) => {
    switch (current) {
      case 'SUCCESS':
        console.log(`${previous}, ${current}`);
        this.setSuccessAlert();
        break;
      case 'FAIL':
        console.log(`${previous}, ${current}`);
        this.setFailAlert();
        break;
      default:
    }
  };

  setSuccessAlert = () => {
    const state = cloneDeep(this.state);
    const committing = {
      ...state,
      header: 'Success',
      message: 'Created user successfully. Please prompt user to check inbox.',
    };
    this.setState(committing);
  };

  setFailAlert = () => {
    const state = cloneDeep(this.state);
    const committing = {
      ...state,
      header: 'Failure',
      message: 'Failed to create user.',
    };
    this.setState(committing);
  };

  updateStatus = (s) => {
    let committing = cloneDeep(this.state);
    committing.status = s;
    this.setState(committing);
  };

  render() {
    if (isAdmin() === true) {
      return (
        <React.Fragment>
          <Alert
            status={this.state.status}
            header={this.state.header}
            message={this.state.message}
          />
          <Segment>
            <Header size={'medium'}>Add User</Header>
            <Divider />
            <Container fluid>
              <AddUserForm
                firebase={this.props.firebase}
                updateStatus={this.updateStatus}
              />
            </Container>
          </Segment>
        </React.Fragment>
      );
    }

    return <Redirect to={'/403'} />;
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapActionsToProps = {
  getUserTypes: getUserTypes,
  getSuperiors: getSuperiors,
  getStoreNames: getStoreNames,
  resetAddUser: resetAddUser,
};

const condition = (authUser) => authUser != null;

const AddUserPage = compose(
  withFirebase,
  withRouter,
  withAuthorization(condition),
  connect(mapStateToProps, mapActionsToProps),
)(AddUserPageBase);

export default AddUserPage;
