import {
  ADD_USER,
  ADD_USER_FAIL,
  ADD_USER_RESET,
  ADD_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_RESET,
  DELETE_USER_SUCCESS,
  EDIT_GET_USER,
  EDIT_GET_USER_FAIL,
  EDIT_GET_USER_RESET,
  EDIT_GET_USER_SUCCESS,
  EDIT_USER,
  EDIT_USER_FAIL,
  EDIT_USER_REALM,
  EDIT_USER_REALM_FAIL,
  EDIT_USER_REALM_SUCCESS,
  EDIT_USER_RESET,
  EDIT_USER_SUCCESS,
  FILTER_USER_LIST,
  GET_SUBORDINATES_FAIL,
  GET_SUBORDINATES_SUCCESS,
  GET_SUPERIORS_FAIL,
  GET_SUPERIORS_SUCCESS,
  GET_USER_LIST,
  GET_USER_LIST_FAIL,
  GET_USER_LIST_SUCCESS,
  GET_USER_TYPES_FAIL,
  GET_USER_TYPES_SUCCESS,
  RESET_EDIT_USER_REALM,
  RESET_TOGGLE_USER_ACTIVATION,
  RESET_USER,
  SORT_USERS,
  TOGGLE_USER_ACTIVATION,
  TOGGLE_USER_ACTIVATION_FAIL,
  TOGGLE_USER_ACTIVATION_SUCCESS,
  USER_UPDATE_PER_PAGE,
} from '../actions/types';
import { UserActionTypes } from '../interfaces/actions/user-action-interface';
import {
  AddUserState,
  DeleteUserState,
  EditUserState,
  UserActivationState,
} from '../interfaces/states/redux/user-state';

const LIST_USER_INITIAL_STATE = {
  pagination: {
    currentPage: 1,
    totalPage: 1,
    perPage: 20,
    totalEntities: 0,
    sort: {
      key: 'Nickname',
      ascending: true,
    },
    filters: {
      realm: '',
    },
  },
  users: [],
  loading: true,
  error: '',
  updated: 0,
};

const ADD_USER_INITIAL_STATE: AddUserState = {
  form: {
    email: '',
    password: '',
    confirmPassword: '',
    userType: '',
  },
  status: 'INITIAL',
  error: '',
};

const DELETE_USER_INITIAL_STATE: DeleteUserState = {
  email: '',
  status: 'INITIAL',
};

const USER_ACTIVATION_INITIAL_STATE: UserActivationState = {
  email: '',
  type: undefined,
  status: 'INITIAL',
};

const EDIT_USER_INITIAL_STATE: EditUserState = {
  user: {
    _id: '',
    username: '',
    userType: '',
    superior: '',
    stores: [],
    name: '',
    nickName: '',
    realms: [],
  },
  status: {
    user: 'INITIAL',
    edit: 'INITIAL',
    realm: 'INITIAL',
  },
  error: undefined,
};

const SHARED_INITIAL_STATE = {
  userTypes: [],
  superiors: [], // oms emails
  subordinates: [], // dms emails
};

const INITIAL_STATE = {
  add: ADD_USER_INITIAL_STATE,
  edit: EDIT_USER_INITIAL_STATE,
  delete: DELETE_USER_INITIAL_STATE,
  activation: USER_ACTIVATION_INITIAL_STATE,
  list: LIST_USER_INITIAL_STATE,
  shared: SHARED_INITIAL_STATE,
};

const UserReducer = (state = INITIAL_STATE, action: UserActionTypes) => {
  switch (action.type) {
    case RESET_USER:
      return INITIAL_STATE;
    case SORT_USERS:
      return {
        ...state,
        list: {
          ...state.list,
          pagination: {
            ...state.list.pagination,
            sort: {
              ...state.list.pagination.sort,
              key: action.payload.data.sort.key,
              ascending: action.payload.data.sort.ascending,
            },
          },
        },
      };
    case FILTER_USER_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          pagination: {
            ...state.list.pagination,
            filters: {
              ...action.payload,
            },
          },
        },
      };
    case GET_USER_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          users: action.payload?.users ?? [],
          pagination: {
            ...state.list.pagination,
            totalEntities:
              action.payload?.pagination?.totalEntities ??
              state.list.pagination.totalEntities,
            currentPage:
              action.payload?.pagination?.currentPage ??
              state.list.pagination.currentPage,
            totalPage:
              action.payload?.pagination?.totalPage ??
              state.list.pagination.totalPage,
          },
          loading: false,
          updated: action.payload?.updated ?? 0,
        },
      };
    case GET_USER_LIST_FAIL:
      return {
        ...state,
        list: {
          ...state.list,
          error: action.payload,
        },
      };
    case USER_UPDATE_PER_PAGE:
      return {
        ...state,
        list: {
          ...state.list,
          pagination: {
            ...state.list.pagination,
            perPage: action.payload.data.perPage,
          },
        },
      };
    case GET_USER_TYPES_SUCCESS:
      return {
        ...state,
        shared: {
          ...state.shared,
          userTypes: action.payload?.data.types ?? [],
        },
      };
    case GET_USER_TYPES_FAIL:
      return state;
    case GET_SUPERIORS_SUCCESS:
      return {
        ...state,
        shared: {
          ...state.shared,
          superiors: action.payload?.userOptions ?? state.shared.superiors,
        },
      };
    case GET_SUPERIORS_FAIL:
      return state;
    case GET_SUBORDINATES_SUCCESS:
      return {
        ...state,
        shared: {
          ...state.shared,
          subordinates:
            action.payload?.userOptions ?? state.shared.subordinates,
        },
      };
    case GET_SUBORDINATES_FAIL:
      return state;
    case ADD_USER:
      return {
        ...state,
        add: {
          ...state.add,
          status: 'PROCESSING',
        },
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        add: {
          ...state.add,
          status: 'SUCCESS',
        },
      };
    case ADD_USER_FAIL:
      return {
        ...state,
        add: {
          ...state.add,
          status: 'FAILED',
        },
      };
    case ADD_USER_RESET:
      return {
        ...state,
        add: {
          ...state.add,
          status: 'INITIAL',
        },
      };
    case EDIT_GET_USER:
      return {
        ...state,
        edit: {
          ...state.edit,
          status: {
            ...state.edit.status,
            user: 'PROCESSING',
          },
        },
      };
    case EDIT_GET_USER_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          user: action.payload.user,
          status: {
            ...state.edit.status,
            user: 'SUCCESS',
          },
        },
      };
    case EDIT_GET_USER_FAIL:
      return {
        ...state,
        edit: {
          ...state.edit,
          status: {
            ...state.edit.status,
            user: 'FAILED',
          },
        },
      };
    case EDIT_GET_USER_RESET:
      return {
        ...state,
        edit: {
          ...state.edit,
          status: {
            ...state.edit.status,
            user: 'INITIAL',
          },
        },
      };
    case EDIT_USER:
      return {
        ...state,
        edit: {
          ...state.edit,
          status: {
            ...state.edit.status,
            edit: 'PROCESSING',
          },
        },
      };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          status: {
            ...state.edit.status,
            edit: 'SUCCESS',
          },
        },
      };
    case EDIT_USER_FAIL:
      return {
        ...state,
        edit: {
          ...state.edit,
          status: {
            ...state.edit.status,
            edit: 'FAILED',
          },
        },
      };
    case EDIT_USER_RESET:
      return {
        ...state,
        edit: {
          ...state.edit,
          status: {
            ...state.edit.status,
            edit: 'INITIAL',
          },
        },
      };
    case DELETE_USER:
      return {
        ...state,
        delete: {
          ...state.delete,
          email: action.payload.email,
          status: 'PROCESSING',
        },
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          status: 'SUCCESS',
        },
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        delete: {
          ...state.delete,
          status: 'FAILED',
        },
      };
    case DELETE_USER_RESET:
      return {
        ...state,
        delete: DELETE_USER_INITIAL_STATE,
      };
    case RESET_TOGGLE_USER_ACTIVATION:
      return {
        ...state,
        activation: USER_ACTIVATION_INITIAL_STATE,
      };
    case TOGGLE_USER_ACTIVATION:
      return {
        ...state,
        activation: {
          ...state.activation,
          email: action.payload.email,
          type: action.payload.type,
          status: 'PROCESSING',
        },
      };
    case TOGGLE_USER_ACTIVATION_SUCCESS:
      return {
        ...state,
        activation: {
          ...state.activation,
          status: 'SUCCESS',
        },
      };
    case TOGGLE_USER_ACTIVATION_FAIL:
      return {
        ...state,
        status: 'FAILED',
      };
    case EDIT_USER_REALM:
      return {
        ...state,
        edit: {
          ...state.edit,
          status: {
            ...state.edit.status,
            realm: 'PROCESSING',
          },
        },
      };
    case EDIT_USER_REALM_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          user: {
            ...state.edit.user,
            realms: action.payload.realms,
          },
          status: {
            ...state.edit.status,
            realm: 'SUCCESS',
          },
        },
      };
    case EDIT_USER_REALM_FAIL:
      return {
        ...state,
        edit: {
          ...state.edit,
          status: {
            ...state.edit.status,
            realm: 'FAILED',
          },
        },
      };
    case RESET_EDIT_USER_REALM:
      return {
        ...state,
        edit: {
          ...state.edit,
          status: {
            ...state.edit.status,
            realm: 'INITIAL',
          },
        },
      };
    default:
      return state;
  }
};

export default UserReducer;
