import {
  DeliveryActivityInfo,
  InStoreActivityInfo,
} from 'interfaces/entities/stores/modal/order';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Button,
  Dimmer,
  Divider,
  Header,
  Icon,
  Loader,
  Modal,
} from 'semantic-ui-react';
import { StoreDetailModalProps } from '../../../interfaces/props/stores/store-detail-modal-props';
import { formatTime } from '../../../utils/time-formatter';
import { DeliveryOrders } from './active-deliveries';
import { InStoreOrders } from './active-instores';
import { EmployeeDetails } from './employee-details';
import { OrderTimelineModal } from './order-timeline-modal';

export interface OrderDetails {
  activities: DeliveryActivityInfo | InStoreActivityInfo;
  orderNumber: string;
}

const handleOrderTimelineOnClose = (
  setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>,
) => (e: any, d: any) => {
  setOrder(undefined);
};

const handleOrderTimelineOnOpen = (
  setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>,
) => (activity: OrderDetails | undefined) => (e: any, d: any) => {
  setOrder(activity);
};

export const StoreDetailsModal: FunctionComponent<StoreDetailModalProps> = (
  props: StoreDetailModalProps,
) => {
  const {
    loading,
    closeModal,
    storeDetails: { activeDeliveries, activeInStore },
  } = props;
  const [updated, setUpdated] = useState(0);
  const [order, setOrder] = useState<OrderDetails | undefined>(undefined);

  useEffect(() => {
    if (!loading) {
      setUpdated(Date.now());
    }
  }, [loading, setUpdated]);

  useEffect(() => {
    if (order !== undefined) {
      const orders = [
        ...activeDeliveries.map((d) => d.orderId),
        ...activeInStore.map((d) => d.orderId),
      ];
      const currentOrder = orders.find((o) => o === order?.orderNumber);
      if (currentOrder === undefined) {
        setOrder(undefined);
      }
    }
  }, [order, activeDeliveries, activeInStore]);

  const onCloseModal = () => {
    closeModal();
  };

  return (
    <React.Fragment>
      <Modal
        className={'modal-dimensions overflow-scroll'}
        open={props.status}
        onClose={onCloseModal}
      >
        <Dimmer inverted active={loading}>
          <Loader />
        </Dimmer>
        <Modal.Header>
          <React.Fragment>
            {`${props.storeInfo?.storeId} ${props.storeInfo?.storeCode} ${props.storeInfo?.storeName} `}{' '}
            Details
            <Button icon floated="right" size={'mini'} onClick={onCloseModal}>
              <Icon name="delete" />
            </Button>
          </React.Fragment>
        </Modal.Header>
        <Modal.Content>
          <div className={'pull-right grey-text'}>
            {updated === 0
              ? 'loading...'
              : `last update ${formatTime(updated)}`}
          </div>
          <Header as="h2">Employees In Store</Header>
          <EmployeeDetails master={props.storeDetails.employees} />
          <Divider />
          <Header as="h2">Active Delivery Order</Header>
          <DeliveryOrders
            setOrder={handleOrderTimelineOnOpen(setOrder)}
            activeDeliveries={props.storeDetails.activeDeliveries}
          />
          <Divider />
          <Header as="h2">Active Carry Out & Eat In Orders</Header>
          <InStoreOrders
            setOrder={handleOrderTimelineOnOpen(setOrder)}
            activeInStores={props.storeDetails.activeInStore}
          />
        </Modal.Content>
      </Modal>
      <OrderTimelineModal
        order={order}
        onClose={handleOrderTimelineOnClose(setOrder)}
      />
    </React.Fragment>
  );
};
