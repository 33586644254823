import axios, { AxiosResponse } from 'axios';
import { GetSimpleStoreNameOptions } from 'interfaces/dtos/stores/store-listing';
import { Dispatch } from 'redux';
import { StorePaginationProps } from '../interfaces/props/stores/store-table-props';
import {
  transformStoreActiveDeliveries,
  transformStoreActiveInstores,
  transformStoreEmployees,
  transformStoreListing,
  transformStoreSortKey,
} from '../utils/transformers/store-transformers';
import {
  CLOSE_STORE_MODAL,
  FILTER_STORES,
  GET_SIMPLE_STORE_NAMES,
  GET_SIMPLE_STORE_NAMES_FAIL,
  GET_SIMPLE_STORE_NAMES_SUCCESS,
  GET_STORE_DETAILS,
  GET_STORE_DETAILS_ACTIVE_DELIVERIES,
  GET_STORE_DETAILS_ACTIVE_DELIVERIES_FAIL,
  GET_STORE_DETAILS_ACTIVE_DELIVERIES_SUCCESS,
  GET_STORE_DETAILS_ACTIVE_INSTORE,
  GET_STORE_DETAILS_ACTIVE_INSTORE_FAIL,
  GET_STORE_DETAILS_ACTIVE_INSTORE_SUCCESS,
  GET_STORE_DETAILS_EMPLOYEE,
  GET_STORE_DETAILS_EMPLOYEE_FAIL,
  GET_STORE_DETAILS_EMPLOYEE_SUCCESS,
  GET_STORE_INFO_LIST,
  GET_STORE_INFO_LIST_FAIL,
  GET_STORE_INFO_LIST_SUCCESS,
  OPEN_STORE_MODAL,
  SORT_STORES,
  STORE_UPDATE_PER_PAGE,
} from './types';

//--------------------------------------------------------
//---------------GET Order Info List----------------------
//--------------------------------------------------------

export const getStoreList = (
  firebase: any,
  pagination: StorePaginationProps,
) => {
  return async (dispatch: Dispatch) => {
    getStoreListFC(firebase, dispatch, pagination);
  };
};

export const getStoreListFC = (
  firebase: any,
  dispatch: Dispatch,
  pagination: StorePaginationProps,
) => {
  firebase
    .getIdToken()
    .then((token: string) => {
      dispatch({
        type: GET_STORE_INFO_LIST,
      });

      queryStores(
        dispatch,
        token,
        pagination,
        onGetListingInfoListSuccess,
        onGetListingInfoListFail,
      );
    })
    .catch((error: any) => {
      console.error(error);
      onGetListingInfoListFail(dispatch, error);
    });
};

const onGetListingInfoListSuccess = (
  dispatch: Dispatch,
  response?: AxiosResponse,
) => {
  const payload =
    response !== undefined ? transformStoreListing(response.data) : undefined;
  if (payload !== undefined) {
    payload.updated = Date.now();
  }

  dispatch({
    type: GET_STORE_INFO_LIST_SUCCESS,
    payload: payload,
  });
};

const onGetListingInfoListFail = (dispatch: Dispatch, error: any) => {
  dispatch({
    type: GET_STORE_INFO_LIST_FAIL,
    payload: error,
  });
};

const queryStores = (
  dispatch: Dispatch,
  token: string,
  pagination: StorePaginationProps,
  onSuccess: (dispatch: Dispatch, payload?: AxiosResponse) => void,
  onFailed: (dispatch: Dispatch, error?: any) => void,
) => {
  axios({
    method: 'get',
    url: `/store`,
    params: {
      page: pagination.currentPage,
      pageSize: pagination.perPage,
      om:
        pagination.filters.om.length === 0 ? undefined : pagination.filters.om,
      dm:
        pagination.filters.dm.length === 0 ? undefined : pagination.filters.dm,
      state:
        pagination.filters.state.length === 0
          ? undefined
          : pagination.filters.state,
      sortKey:
        pagination.sort.key.length === 0
          ? undefined
          : transformStoreSortKey(pagination.sort.key),
      sortOrder: pagination.sort.ascending ? 1 : -1,
    },
    headers: { Authorization: token },
  })
    .then((response) => onSuccess(dispatch, response))
    .catch((error) => onFailed(dispatch, error));
};

export const openOrderModal = (dispatch: Dispatch, idx: number) => {
  dispatch({
    type: OPEN_STORE_MODAL,
    payload: {
      idx: idx,
    },
  });
};

export const closeOrderModal = (dispatch?: Dispatch) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) => closeStoreModalFC(dispatch)
    : closeStoreModalFC(dispatch);

const closeStoreModalFC = (dispatch: Dispatch) => {
  dispatch({
    type: CLOSE_STORE_MODAL,
  });
};

export const getStoreDetails = (
  firebase: any,
  storeId: string,
  dispatch?: Dispatch,
) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) =>
        getStoreDetailsFC(firebase, storeId, dispatch)
    : getStoreDetailsFC(firebase, storeId, dispatch);

const getStoreDetailsFC = (
  firebase: any,
  storeId: string,
  dispatch: Dispatch,
) => {
  dispatch({
    type: GET_STORE_DETAILS,
  });

  firebase
    .getIdToken()
    .then((token: string) => {
      dispatch({
        type: GET_STORE_DETAILS_EMPLOYEE,
      });
      dispatch({
        type: GET_STORE_DETAILS_ACTIVE_DELIVERIES,
      });
      dispatch({
        type: GET_STORE_DETAILS_ACTIVE_INSTORE,
      });
      queryStoreEmployees(
        dispatch,
        token,
        storeId,
        onGetStoreEmployeeSuccess,
        onGetStoreEmployeeFail,
      );
      queryActiveDeliveries(
        dispatch,
        token,
        storeId,
        onGetActiveDeliveriesSuccess,
        onGetActiveDeliveriesFail,
      );
      queryActiveInstores(
        dispatch,
        token,
        storeId,
        onGetActiveInstoresSuccess,
        onGetActiveInstoresFail,
      );
    })
    .catch((error: any) => {
      console.error(error);
    });
};

export const queryActiveInstores = (
  dispatch: Dispatch,
  token: string,
  storeId: string,
  onSuccess: (dispatch: Dispatch, payload?: AxiosResponse) => void,
  onFailed: (dispatch: Dispatch, error?: any) => void,
) => {
  axios({
    method: 'get',
    url: `/store/${storeId}/active/instore`,
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      onSuccess(dispatch, response);
    })
    .catch((error) => {
      onFailed(dispatch, error);
    });
};

const onGetActiveInstoresSuccess = (
  dispatch: Dispatch,
  payload?: AxiosResponse,
) => {
  const response =
    payload?.data === undefined
      ? undefined
      : transformStoreActiveInstores(payload.data);
  dispatch({
    type: GET_STORE_DETAILS_ACTIVE_INSTORE_SUCCESS,
    payload: response,
  });
};

const onGetActiveInstoresFail = (dispatch: Dispatch, error?: any) => {
  dispatch({
    type: GET_STORE_DETAILS_ACTIVE_INSTORE_FAIL,
    payload: error,
  });
};

export const queryActiveDeliveries = (
  dispatch: Dispatch,
  token: string,
  storeId: string,
  onSuccess: (dispatch: Dispatch, payload?: AxiosResponse) => void,
  onFailed: (dispatch: Dispatch, error?: any) => void,
) => {
  axios({
    method: 'get',
    url: `/store/${storeId}/active/delivery`,
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      onSuccess(dispatch, response);
    })
    .catch((error) => {
      onFailed(dispatch, error);
    });
};

const onGetActiveDeliveriesSuccess = (
  dispatch: Dispatch,
  payload?: AxiosResponse,
) => {
  const response =
    payload?.data === undefined
      ? undefined
      : transformStoreActiveDeliveries(payload.data);

  dispatch({
    type: GET_STORE_DETAILS_ACTIVE_DELIVERIES_SUCCESS,
    payload: response,
  });
};

const onGetActiveDeliveriesFail = (dispatch: Dispatch, error?: any) => {
  dispatch({
    type: GET_STORE_DETAILS_ACTIVE_DELIVERIES_FAIL,
    payload: error,
  });
};

export const queryStoreEmployees = (
  dispatch: Dispatch,
  token: string,
  storeId: string,
  onSuccess: (dispatch: Dispatch, payload?: AxiosResponse) => void,
  onFailed: (dispatch: Dispatch, error?: any) => void,
) => {
  axios({
    method: 'get',
    url: `/store/${storeId}/employee`,
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      onSuccess(dispatch, response);
    })
    .catch((error) => {
      onFailed(dispatch, error);
    });
};

const onGetStoreEmployeeSuccess = (
  dispatch: Dispatch,
  payload?: AxiosResponse,
) => {
  const response =
    payload?.data === undefined
      ? undefined
      : transformStoreEmployees(payload.data);

  dispatch({
    type: GET_STORE_DETAILS_EMPLOYEE_SUCCESS,
    payload: response,
  });
};

const onGetStoreEmployeeFail = (dispatch: Dispatch, error?: any) => {
  dispatch({
    type: GET_STORE_DETAILS_EMPLOYEE_FAIL,
    payload: error,
  });
};

export const filterStores = (
  firebase: any,
  pagination: StorePaginationProps,
  dispatch?: Dispatch,
) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) =>
        filterStoresFC(firebase, pagination, dispatch)
    : filterStoresFC(firebase, pagination, dispatch);

const filterStoresFC = (
  firebase: any,
  pagination: StorePaginationProps,
  dispatch: Dispatch,
) => {
  dispatch({
    type: FILTER_STORES,
    payload: {
      data: pagination,
    },
  });
  getStoreListFC(firebase, dispatch, pagination);
};

export const storeUpdatePerPage = (
  firebase: any,
  pagination: StorePaginationProps,
  dispatch?: Dispatch,
) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) =>
        storeUpdatePerPageFC(firebase, pagination, dispatch)
    : storeUpdatePerPageFC(firebase, pagination, dispatch);

const storeUpdatePerPageFC = (
  firebase: any,
  pagination: StorePaginationProps,
  dispatch: Dispatch,
) => {
  dispatch({
    type: STORE_UPDATE_PER_PAGE,
    payload: {
      data: pagination,
    },
  });
  getStoreListFC(firebase, dispatch, pagination);
};

export const sortStores = (
  firebase: any,
  pagination: StorePaginationProps,
  dispatch?: Dispatch,
) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) => sortStoresFC(firebase, pagination, dispatch)
    : sortStoresFC(firebase, pagination, dispatch);

const sortStoresFC = (
  firebase: any,
  pagination: StorePaginationProps,
  dispatch: Dispatch,
) => {
  dispatch({
    type: SORT_STORES,
    payload: {
      data: pagination,
    },
  });

  getStoreListFC(firebase, dispatch, pagination);
};

export const getStoreNames = (
  firebase: any,
  options: GetSimpleStoreNameOptions = { dm: false, om: false },
  dispatch?: Dispatch,
) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) => getStoreNamesFC(firebase, dispatch, options)
    : getStoreNamesFC(firebase, dispatch, options);

const getStoreNamesFC = (
  firebase: any,
  dispatch: Dispatch,
  options: GetSimpleStoreNameOptions,
) => {
  dispatch({
    type: GET_SIMPLE_STORE_NAMES,
  });

  firebase
    .getIdToken()
    .then((token: string) =>
      querySimpleStores(
        dispatch,
        token,
        onGetStoreNamesSuccess,
        onGetStoreNamesFailed,
        options,
      ),
    )
    .catch((error: any) => console.error(error));
};

export const getUnassgined = (
  firebase: any,
  userType: 'om' | 'dm',
  email: string,
  dispatch?: Dispatch,
) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) =>
        getUnassginedFC(firebase, userType, email, dispatch)
    : getUnassginedFC(firebase, userType, email, dispatch);

const getUnassginedFC = (
  firebase: any,
  userType: 'om' | 'dm',
  email: string,
  dispatch: Dispatch,
) => {
  firebase
    .getIdToken()
    .then((token: string) => {
      queryUnassgined(
        dispatch,
        token,
        userType,
        email,
        onGetUnassignedSuccess,
        onGetUnassignedFail,
      );
    })
    .catch((error: any) => console.error(error));
};

const onGetUnassignedSuccess = (
  dispatch: Dispatch,
  response: AxiosResponse,
) => {
  dispatch({
    type: GET_SIMPLE_STORE_NAMES_SUCCESS,
    payload: {
      stores: response.data,
    },
  });
};

const onGetUnassignedFail = (dispatch: Dispatch, error: any) => {};

const queryUnassgined = (
  dispatch: Dispatch,
  token: string,
  userType: 'om' | 'dm',
  email: string,
  onSuccess: (dispatch: Dispatch, payload: AxiosResponse) => void,
  onFailed: (dispatch: Dispatch, error?: any) => void,
) => {
  const url = `store/${userType}/unassigned`;

  axios({
    method: 'get',
    url: url,
    params: {
      include: email,
    },
    headers: {
      Authorization: token,
    },
  })
    .then((response) => onSuccess(dispatch, response))
    .catch((error) => onFailed(dispatch, error));
};

const onGetStoreNamesSuccess = (
  dispatch: Dispatch,
  response?: AxiosResponse,
) => {
  dispatch({
    type: GET_SIMPLE_STORE_NAMES_SUCCESS,
    payload: {
      stores: response?.data,
    },
  });
};

const onGetStoreNamesFailed = (dispatch: Dispatch, error: any) => {
  dispatch({
    type: GET_SIMPLE_STORE_NAMES_FAIL,
    payload: error,
  });
};

const querySimpleStores = (
  dispatch: Dispatch,
  token: string,
  onSuccess: (dispatch: Dispatch, payload?: AxiosResponse) => void,
  onFailed: (dispatch: Dispatch, error?: any) => void,
  options: GetSimpleStoreNameOptions,
) => {
  axios({
    method: 'get',
    url: `/store/list`,
    params: {
      dm: options.dm,
      om: options.om,
    },
    // NOTE: since our default custom serializer removes everthing undefined,
    // to not mess up other api calls, we overwrite our param serializer
    // here
    paramsSerializer: (params) => {
      let result = '';
      Object.keys(params).forEach((key) => {
        // do normal serializing only for api_key
        if (key === 'api_key') {
          result += `${key}=${encodeURIComponent(params[key])}&`;
          return;
        }

        // else do custom serializing
        if (params[key] !== undefined && params[key]) {
          result += `${key}=&`;
        }
      });
      return result.substr(0, result.length - 1);
    },
    headers: {
      Authorization: token,
    },
  })
    .then((response: AxiosResponse) => onSuccess(dispatch, response))
    .catch((error: any) => onFailed(dispatch, error));
};
