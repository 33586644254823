import axios from 'axios';
import moment from 'moment';
import store from 'store';
import { INVALIDATE_SESSION } from '../actions/types';
import { API_KEY } from '../config';
// import _ from 'lodash';
import { EXPIRATION, EXPIRATION_DURATION } from '../constants/variable';
import * as LocalStorage from '../utils/localstorage';

// export const currentTime = moment('2020-05-13T14:00:00+08:00');
export const currentTime = moment();

axios.interceptors.request.use(async (request) => {
  const { dispatch } = store;

  const exp = LocalStorage.get(EXPIRATION);
  if (exp && Date.now() - exp < EXPIRATION_DURATION) {
    LocalStorage.put(EXPIRATION, Date.now());
  } else {
    // to prevent other requests calling this again if already called once
    if (exp !== undefined) {
      dispatch({
        type: INVALIDATE_SESSION,
      });
    }
    return Promise.reject();
  }

  if (request.params == null) {
    request.params = {};
  }

  request.params['api_key'] = API_KEY;
  return request;
});

axios.interceptors.response.use((response) => {
  return response;
});

export const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
};

// export const isOrderReady = (orderInOvenTime, inOvenToReadyBufferMin) => {
//     const orderInOvenTimePlusXmin = orderInOvenTime.clone().add(inOvenToReadyBufferMin, 'm');
//     return currentTime.isSameOrAfter(orderInOvenTimePlusXmin);
// }

// export const is15minBeforeCurrentTime = (orderTime) => {
//     const over15minOrderTime = orderTime.clone().add(15, 'm');
//     return currentTime.isSameOrAfter(over15minOrderTime);
// };

export const isIOCompareToTargetTime = (
  orderInOvenTime,
  targetOrderTime,
  inOvenToReadyBufferMin,
) => {
  const IOTimePlusXmin = orderInOvenTime
    .clone()
    .add(inOvenToReadyBufferMin, 'm');
  return IOTimePlusXmin.isAfter(targetOrderTime, 'minute');
};

export const isNowCompareToTargetTime = (
  targetOrderTime,
  orderDropToReadyBufferMin,
) => {
  const nowPlusXmin = currentTime.clone().add(orderDropToReadyBufferMin, 'm');
  return nowPlusXmin.isAfter(targetOrderTime, 'minute');
};

export const isNowCompareToOutTheDoorDeliverTime = (
  isArrived,
  orderComplete,
  completeToVehicleSec,
) => {
  const isArrivedPlusSec = isArrived.clone().add(completeToVehicleSec, 'ms');
  const orderCompletePlusSec = orderComplete
    .clone()
    .add(completeToVehicleSec, 'ms');

  if (orderComplete.isBefore(isArrived)) {
    return currentTime.isAfter(isArrivedPlusSec, 'minute');
  }

  if (isArrived.isBefore(orderComplete)) {
    return currentTime.isAfter(orderCompletePlusSec, 'minute');
  }

  return false;
};
