import React, { FunctionComponent } from 'react'
import { Table } from 'semantic-ui-react'
import { PendingOrderTableRowProps } from '../../../interfaces/props/dashboards/pending-order-table-props'

export const PendingOrderTableRow: FunctionComponent<PendingOrderTableRowProps> = (props) => (
  <Table.Row className={'clickable'} onClick={() => props.openStoreModal({storeId: props.store.storeId, storeName: props.store.storeName, storeCode: props.store.storeCode})}>
    <Table.Cell>{`${props.store.storeId} ${props.store.storeCode} ${props.store.storeName}`}</Table.Cell>
    <Table.Cell>{props.store.deliveryCount}</Table.Cell>
    <Table.Cell>{props.store.carryOutCount}</Table.Cell>
  </Table.Row>
)
