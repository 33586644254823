import React, { FunctionComponent, ReactElement } from 'react';
import { Icon, Message } from 'semantic-ui-react';

const StatusCode = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  INITIAL: 'INITIAL',
  PROCESSING: 'PROCESSING',
};

interface AlertProps {
  status: string;
  header: string;
  message: string;
}

const renderLoading = (message: string) => {
  return (
    <Message icon>
      <Icon name="circle notched" loading />
      <Message.Content>
        <Message.Header>Just one second</Message.Header>
        {message}
      </Message.Content>
    </Message>
  );
};

const renderAlert = (status: string, header: string, message: string) => {
  return (
    <Message
      positive={status === StatusCode.SUCCESS}
      negative={status === StatusCode.FAIL}
    >
      <Message.Header>{header}</Message.Header>
      <p>{message}</p>
    </Message>
  );
};

const render = ({ status, header, message }: AlertProps) => {
  switch (status) {
    case StatusCode.SUCCESS:
    case StatusCode.FAIL:
      return renderAlert(status, header, message);
    case StatusCode.PROCESSING:
      return renderLoading(message);
    case StatusCode.INITIAL:
    default:
      return null;
  }
};

export const Alert: FunctionComponent<AlertProps> = (
  prop
): ReactElement | null => {
  return render(prop);
};
