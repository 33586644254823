import { platformDictionary } from 'constants/dictionaries';
import { orderComparer } from 'utils/sorting';
import {
  GetStoreActiveDeliveriesActionResponse,
  GetStoreActiveInstoresActionResponse,
  GetStoreEmployeeActionResponse,
} from '../../interfaces/dtos/stores/store-details';
import { GetStoreListingActionResponse } from '../../interfaces/dtos/stores/store-listing';
import {
  DeliveryActivityInfo,
  InStoreActivityInfo,
} from '../../interfaces/entities/stores/modal/order';
import { Store } from '../../interfaces/entities/stores/store';
import {
  ActivityInfo as ApiActivityInfo,
  GetStoreActiveDeliveriesResponse,
  GetStoreActiveInstoresResponse,
  GetStoreEmployeeResponse,
  GetStoreListingResponse,
  Store as ExternalStore,
} from '../../interfaces/requests/stores';
import { ModalStatus } from '../../interfaces/states/redux/store-state';

export const transformStoreSortKey = (key: string) => {
  switch (key) {
    case 'om':
    case 'dm':
      return key;
    case 'store':
      return '_id';
    case 'insider':
      return 'insiderCount';
    case 'insiderAndManager':
      return 'insiderAndManagerCount';
    case 'rider':
      return 'riderCount';
    case 'orderDelivery':
      return 'deliveryCount';
    case 'orderCarryOut':
      return 'carryOutCount';
    case 'roadRider':
      return 'otrRiderCount';
    case 'roadOrder':
      return 'otrOrderCount';
    case 'actualRider':
      return 'actualRider';
    default:
      return undefined;
  }
};

/**
 * map store structure from api to internal store structure
 * @param s api store
 */
const mapStore = (s: ExternalStore): Store => {
  return {
    storeId: s.storeId ?? '',
    OM: s.OM ?? '',
    DM: s.DM ?? '',
    storeName: s.storeName ?? '',
    storeCode: s.storeCode ?? '',
    countryCode: s.countryCode ?? '',
    deliveryCount: s.deliveryCount ?? 0,
    deliveryCountExcludeOTR: s.deliveryCountExcludeOTR ?? 0,
    carryOutCount: s.carryOutCount ?? 0,
    otrRiderCount: s.otrRiderCount ?? 0,
    otrOrderCount: s.otrOrderCount ?? 0,
    insiderCount: s.insiderCount ?? 0,
    insiderAndManagerCount: s.insiderAndManagerCount ?? 0,
    riderCount: s.riderCount ?? 0,
    makelineItemCount: s.makelineItemCount ?? 0,
    actualLabor: s.actualLabor ?? 0,
    actualRider: s.actualRider ?? 0
  };
};

/**
 * transform web api response to action response for get store listing
 * @param data AxiosResponse.data
 */
export const transformStoreListing = (
  data: GetStoreListingResponse,
): GetStoreListingActionResponse => {
  if (data === undefined) {
    return data;
  }

  return {
    pagination: {
      currentPage: data.currentPage ?? 1,
      totalPage: data.totalPage ?? 0,
      perPage: 0,
      totalEntities: data.totalRecord ?? 0,
    },
    stores: data.listing?.map((s) => mapStore(s)) ?? [],
  };
};

export const transformStoreEmployees = (
  data: GetStoreEmployeeResponse,
): GetStoreEmployeeActionResponse => {
  return {
    manager: data.manager,
    insider: data.insider,
    rider: data.rider,
    lastUpdate: data.lastUpdate?.$date,
  };
};

const isEmptyOrUndefined = (s?: string) => {
  const replaced = s?.replaceAll(' ', '');
  return replaced === undefined || replaced.length === 0;
};

const cleanupString = (
  s: string | undefined,
  placeholder: string = 'N/A',
): string => {
  if (isEmptyOrUndefined(s)) {
    return placeholder;
  }
  return s as string;
};

export const transformActivityInfo = (
  from?: ApiActivityInfo,
): DeliveryActivityInfo => {
  return {
    type: 'delivery',
    makeline: {
      $date: from?.makeline?.$date ?? 0,
    },
    oven: {
      $date: from?.oven?.$date ?? 0,
    },
    routingStation: {
      $date: from?.routingStation?.$date ?? 0,
    },
    outTheDoor: {
      $date: from?.outTheDoor?.$date ?? 0,
    },
  };
};

export const transformStoreActiveDeliveries = (
  data: GetStoreActiveDeliveriesResponse[],
): GetStoreActiveDeliveriesActionResponse => {
  data.sort(orderComparer);

  return {
    orders: data.map((ad) => ({
      orderId: cleanupString(ad.orderId),
      platform: cleanupString(platformDictionary[ad.platform]),
      customerName: cleanupString(ad.customerName),
      contact: cleanupString(ad.contact),
      timedNow: cleanupString(ad.timedNow),
      pieCount: cleanupString(ad.pieCount),
      orderTime: ad.orderTime?.$date ?? 0,
      deliveryTime: ad.deliveryTime?.$date ?? 0,
      dispatchTime: ad.dispatchTime?.$date ?? 0,
      ETA: ad.ETA?.$date ?? 0,
      rider: cleanupString(ad.rider),
      status: cleanupString(ad.status),
      activityInfo: transformActivityInfo(ad.activityInfo),
    })),
  };
};

export const transformStoreActiveInstores = (
  data: GetStoreActiveInstoresResponse[],
): GetStoreActiveInstoresActionResponse => {
  data.sort(orderComparer);

  return {
    orders: data.map((ai) => ({
      orderId: cleanupString(ai.orderId),
      platform: cleanupString(platformDictionary[ai.platform]),
      customerName: cleanupString(ai.customerName),
      contact: cleanupString(ai.contact),
      timedNow: cleanupString(ai.timedNow),
      pieCount: cleanupString(ai.pieCount),
      orderTime: ai.orderTime?.$date ?? 0,
      collectTime: ai.collectTime?.$date ?? 0,
      status: cleanupString(ai.status),
      activityInfo: transformInStoreActivityInfo(ai.activityInfo),
    })),
  };
};

export const transformInStoreActivityInfo = (
  from?: ApiActivityInfo,
): InStoreActivityInfo => {
  return {
    type: 'in_store',
    makeline: {
      $date: from?.makeline?.$date ?? 0,
    },
    oven: {
      $date: from?.oven?.$date ?? 0,
    },
  };
};

export const transformModalStatus = (status: ModalStatus) =>
  status.employeeLoading &&
  status.activeDeliveriesLoading &&
  status.activeInStoreLoading;
