import React, { Component } from "react";

import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Line, Bar } from 'react-chartjs-2';

import {
    Container,
    Grid,
    Header
} from "semantic-ui-react";

const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'My First dataset',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [65, 59, 80, 81, 56, 55, 40],
        }
    ]
};

class ReportPageBase extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleClick(elems) {
        console.log(elems); // React Component instance
    }

    render() {
        return (
            <Container>
                <Header size='large'>Report</Header>

                <Grid columns='equal'>
                    <Grid.Row>

                        <Grid.Column>
                            <Line
                                data={data}
                                getDatasetAtEvent={this.handleClick}
                                getElementAtEvent={this.handleClick}
                                getElementsAtEvent={this.handleClick}
                                onElementsClick={this.handleClick}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Grid columns='equal'>
                    <Grid.Row>
                        <Grid.Column>
                            <Bar
                                data={data}
                                width={100}
                                height={50}
                                options={{ maintainAspectRatio: true }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </Container>
        );
    }
}

const mapStateToProps = (props) => {
    return props;
};

const ReportPage = compose(
    withRouter,
    connect(mapStateToProps, null)
)(ReportPageBase);

export default ReportPage;
