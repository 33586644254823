import { VERSION } from 'config';
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
  Button,
  Container,
  Form,
  Header,
  Image,
  Segment,
} from 'semantic-ui-react';
import { loginUser } from '../../actions';
import { withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import { COLOR } from '../../constants/variable';
import * as LocalStorage from '../../utils/localstorage';

const SignInPageBase = (props) => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const error = useMemo(() => {
    const errors = [props.error, props.userInfoError];
    const filtered = errors.filter(x => x !== undefined && x.length > 0);
    return filtered.join(' ');
  }, [props.error, props.userInfoError])

  useEffect(() => {
    return () => {
      console.log('component unmounted.');
    };
  }, []);

  const gotoLogin = (e) => {
    e.preventDefault();
    props.loginUser({ userName, password }, props);
  };

  const { t } = useTranslation();

  const checkSession = ({ firebase, history }) => {
    const currentUser = LocalStorage.get('currentUser');
    // const state = LocalStorage.get('currentUser').state === 'A'
    if (currentUser) {
      if (firebase.auth.currentUser) {
        history.push(ROUTES.DASHBOARD);
      }
    }
  };

  checkSession(props);

  return (
    <div style={{ minHeight: '100vh', display: 'flex' }}>
      <div style={{ maxWidth: '320px', minWidth: '320px', margin: 'auto' }}>
        <Segment>
          <Header textAlign={'center'}>
            <Image size="mini" src="/dpz-logo.svg" />
            Domino's Pizza Vital Sign App
          </Header>
          <Form onSubmit={gotoLogin}>
            <Form.Field error={error.length !== 0}>
              <label>{t('LOGIN_FORM.email')}</label>
              <Form.Input
                autoComplete="on"
                type={'email'}
                onChange={(e, d) => setUserName(d.value)}
              />
            </Form.Field>
            <Form.Field error={error.length !== 0}>
              <label>{t('LOGIN_FORM.password')}</label>
              <Form.Input
                autoComplete="on"
                type={'password'}
                onChange={(e, d) => setPassword(d.value)}
              />
            </Form.Field>
            {error.length === 0 ? undefined : (
              <p style={{ color: COLOR.RED }}>{error}</p>
            )}
            <Button fluid size={'large'}>
              {t('BUTTON.login')}
            </Button>
            <Container className={'clickable'} textAlign={'center'} style={{ paddingTop: '1vh' }}>
              <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
            </Container>
          </Form>
        </Segment>
        <div>
          <p className={'grey-text center-text'}>v {VERSION}</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    userInfoError: state.auth.userInfoError,
  };
};

const mapActionToProps = {
  loginUser: loginUser,
};

const SignInPage = compose(
  withFirebase,
  withRouter,
  connect(mapStateToProps, mapActionToProps),
)(SignInPageBase);

export default SignInPage;
