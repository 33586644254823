import React, { CSSProperties } from 'react'
import { StrictTableCellProps, Table } from 'semantic-ui-react';

export const TableHeader = ({
  headers,
  style,
  alignment = 'left',
}: {
  headers: string[];
  style?: CSSProperties;
  alignment: StrictTableCellProps['textAlign'];
}) => (
  <Table.Header style={style}>
    <Table.Row>
      {headers.map((hc, idx) => (
        <Table.HeaderCell textAlign={alignment} key={idx}>
          {hc}
        </Table.HeaderCell>
      ))}
    </Table.Row>
  </Table.Header>
);
