import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import {DEBUG} from '../config';

const loggerMiddleware = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error,
    predicate: (getState, action) => DEBUG
});

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware,loggerMiddleware));

export default store;