import React, { FunctionComponent } from 'react'
import { Table } from 'semantic-ui-react'
import { InactiveTableBodyProps } from '../../../interfaces/props/dashboards/inactive-table-props'
import { generateEmptyRow } from '../../table/utils'
import { InactiveTableRow } from './inactive-table-row'

export const InactiveTableBody: FunctionComponent<InactiveTableBodyProps> = (props) => (
  <Table.Body>
    {
      props.stores.length === 0
      ? generateEmptyRow()
        : props.stores.map((s, idx) => <InactiveTableRow openStoreModal={props.openStoreModal} store={s} key={idx}/>)
    }
  </Table.Body>
)
