import React, {FunctionComponent, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Dispatch } from 'redux';
import { Table } from 'semantic-ui-react';
import { getStoreDetails, openOrderModal } from '../../actions';
import { StoreTableBodyProps } from '../../interfaces/props/stores/store-table-props';
import { generateEmptyRow } from '../table/utils';
import { StoreTableRow } from './store-table-row';
import {RootState} from "../../interfaces/states/redux/root-state";
import {ApiCurrentUser} from "../../interfaces/apis/users/current-user";

export const StoreTableBody: FunctionComponent<StoreTableBodyProps> = (
  props,
) => {
  const dispatch = useDispatch();

  const userInfo = useSelector<RootState, ApiCurrentUser>(
      state => state.auth.currentUserInfo)
  const userEmail = useMemo(() => {
    return userInfo?.userInfo?.email ?? ''
  }, [userInfo])

  const openStoreModal = (
    firebase: any,
    dispatch: Dispatch,
    storeId: string,
    index: number,
  ) => {
    openOrderModal(dispatch, index);
    getStoreDetails(firebase, storeId, dispatch);
  };

  return (
    <Table.Body>
      {props.stores.length === 0
        ? generateEmptyRow()
        : props.stores.map((s, idx) => (
            <StoreTableRow
              userEmail={userEmail}
              key={idx}
              store={s}
              openOrderModal={() =>
                openStoreModal(props.firebase, dispatch, s.storeId, idx)
              }
            />
          ))}
    </Table.Body>
  );
};
