import React, { FunctionComponent } from 'react';
import { Table } from 'semantic-ui-react';
import { StorePercentageTableProps } from '../../../interfaces/props/dashboards/store-percentage-table-props';

export const StorePercentageTable: FunctionComponent<StorePercentageTableProps> = (
  props,
) => {
  return (
    <Table unstackable celled size={'small'} selectable>
      {props.children}
    </Table>
  );
};
