export const getYesterday = (): number => {
  const yesterdayDate = (new Date().getDate() - 1);
  return new Date().setDate(yesterdayDate);
}

export const getActiveRange = () => {
  const now = new Date();
  const hour = now.getHours();
  if (hour < 14) {
    return '2PM';
  } else if (hour >= 14 && hour < 17) {
    return '5PM';
  } else if (hour >= 17 && hour < 21) {
    return '9PM';
  } else if (hour >= 21 && hour < 23) {
    return '11PM'
  } else {
    return '2PM';
  }
}