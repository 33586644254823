import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { compose } from 'recompose'
import { withFirebase } from 'components/Firebase'
import { withAuthorization } from 'components/Session'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'interfaces/states/redux/root-state'
import { ApiCurrentUser } from 'interfaces/apis/users/current-user'
import { SalesContentSwitch } from 'components/sales/sales-content-switch'
import SalesDataFilterModal
  from 'components/sales/modal/sales-data-filter-modal'
import Visibility from 'components/utils/visibility'
import AddFilterSegment from 'components/sales/add-filter-segment'
import SalesAction from 'actions/SalesAction'

const SalesPageBase = (props: any) => {
  const dispatch = useDispatch();
  const filteredReports = useSelector(
    (state: RootState) => state.sales.filtered)
  const [open, setOpen] = useState(false)

  const userInfo = useSelector<RootState, ApiCurrentUser>(
    state => state.auth.currentUserInfo)
  const userType = useMemo(() => {
    return userInfo?.userType ?? ''
    // return 'DM';
  }, [userInfo])
  const userEmail = useMemo(() => {
    return userInfo?.userInfo?.email ?? ''
  }, [userInfo])



  useEffect(() => {
    return () => {
      dispatch(SalesAction.ResetFilteredReport());
    }
  }, [dispatch])

  const handleRemoveFilteredReport = useCallback((i: string) => () => {
    dispatch(SalesAction.RemoveFilteredReport(i));
  }, [dispatch]);

  const handleClear = useCallback(() => {
    dispatch(SalesAction.ResetFilteredReport());
  }, [dispatch]);

  return <div>

    <Visibility condition={['OM', 'DM', 'AD', 'OP'].includes(userType)}>
      {
        <React.Fragment>
          <AddFilterSegment setOpen={setOpen} handleClear={handleClear}/>
          <SalesContentSwitch type={userType} firebase={props.firebase}/>
        </React.Fragment>
      }
    </Visibility>
    <SalesDataFilterModal firebase={props.firebase}
                          open={open}
                          userType={userType}
                          setOpen={setOpen}/>
  </div>
}

const condition = (authUser: any) => authUser != null

const SalesPage = compose(
  withFirebase,
  withAuthorization(condition),
)(SalesPageBase)

export default SalesPage
