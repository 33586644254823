import React, { FunctionComponent } from 'react';
import { Table } from 'semantic-ui-react';
import { UserTableHeaderProps } from '../../../interfaces/props/users/user-table-props';

const determineSortDirection = (
  sorted: string,
  header: string,
  direction: boolean,
) => {
  if (sorted !== header) {
    return undefined;
  }

  return direction ? 'ascending' : 'descending';
};

//props.sortableHeaders.find((x) => x === hc) !== undefined,
export const UserTableHeader: FunctionComponent<UserTableHeaderProps> = (
  props,
) => {
  return (
    <Table.Header>
      <Table.Row>
        {props.headers.map((hc, idx) => (
          <Table.HeaderCell
            textAlign="center"
            sorted={determineSortDirection(props.sorted, hc, props.direction)}
            key={idx}
            onClick={
              props.sortableHeaders.find((x) => x === hc) !== undefined
                ? props.handleSort(hc)
                : undefined
            }
          >
            {hc}
          </Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
  );
};
