import React from 'react';
import { Table } from 'semantic-ui-react';
import { MasterEmployeeDetails } from '../../../interfaces/entities/stores/modal/employee-details';
import { formatTime } from '../../../utils/time-formatter';

export const EmployeeDetails = ({
  master,
}: {
  master: MasterEmployeeDetails;
}) => {
  const updatedDateTimeStr =
    master.updated === undefined ? 'N/A' : formatTime(master.updated);

  return (
    <Table celled selectable sortable structured>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Shift Type</Table.HeaderCell>
          <Table.HeaderCell>No. of People</Table.HeaderCell>
          <Table.HeaderCell>Last Update</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Managers</Table.Cell>
          <Table.Cell>{master.manager}</Table.Cell>
          <Table.Cell>{updatedDateTimeStr}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Insiders</Table.Cell>
          <Table.Cell>{master.insider}</Table.Cell>
          <Table.Cell>{updatedDateTimeStr}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Riders</Table.Cell>
          <Table.Cell>{master.rider}</Table.Cell>
          <Table.Cell>{updatedDateTimeStr}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
