import Firebase from 'components/Firebase'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'interfaces/states/redux/root-state'
import { useCallback } from 'react'
import { AuthApiService } from 'services/api/auth-api-service'
import { formatTime} from 'utils/time-formatter'
import SalesApiService from 'services/api/sales-api-service'

const useFilterSalesMutation = (firebase: Firebase) => {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.sales.filtered);

  const mutation = useCallback((filter: string, value: string) => {
    const now = new Date();
    const time = formatTime(now.getTime(), 'DATE_ONLY_VAR1');
    return AuthApiService.withFirebaseToken(firebase, SalesApiService.getFilteredReport(time, filter, value));
  }, [dispatch]);

  return { mutation, data }
};

export default useFilterSalesMutation;