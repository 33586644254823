import { useEffect, useRef } from 'react'

const useInterval = (duration: number, callback?: () => void) => {
  const ref = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    if (ref.current === undefined) {
      ref.current = setInterval(() => {
        callback && callback();
      }, duration)
    }
  }, [callback, duration])

  useEffect(() => {
    return () => {
      ref.current && clearInterval(ref.current);
      ref.current = undefined;
    }
  }, [])
}

export default useInterval;
