import { 
    HIDE_LOADING, 
    SHOW_LOADING 
} from '../actions/types';

const INITIAL_STATE = {
	show: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case SHOW_LOADING:
      return {...state, show: true};

    case HIDE_LOADING:
      return {...state, show: false};

    default:
      return state;
  }
};