import { getStoreNames, getUnassgined } from 'actions/StoreActions';
import { AddUserState } from 'interfaces/states/local/user-state';
import { cloneDeep } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createSelector } from 'reselect';
import { Button, Dropdown, Form, InputOnChangeData } from 'semantic-ui-react';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { addUser } from '../../../actions/UserActions';
import { AddUserActionRequest } from '../../../interfaces/dtos/users/add-user';
import { FormError } from '../../../interfaces/entities/forms/error';
import { AddUserFormProps } from '../../../interfaces/props/users/add-user-props';
import {
  transformMembers,
  transformStoreNames,
  transformUserTypes,
} from '../../../utils/transformers/user-transformer';

const NICKNAME_CHAR_LIMIT = 15;

/**
 * takes a string and generate an
 * error object
 * @param text error message
 * @returns {FormError} error object
 */
const generateErrorObject = (text: string): FormError => {
  return {
    content: text,
    pointing: 'above',
  };
};

const selectUserTypes = createSelector(
  (state: any) => state.user.shared.userTypes,
  (userTypes) => transformUserTypes(userTypes ?? []),
);

const selectSuperiors = createSelector(
  (state: any) => state.user.shared.superiors,
  (superiors) => transformMembers(superiors ?? []),
);

const selectStores = createSelector(
  (state: any) => state.store.shared.stores,
  (stores) => transformStoreNames(stores ?? []),
);

const selectStatus = createSelector(
  (state: any) => state.user.add.status,
  (status) => status,
);

const INITIAL_STATE: AddUserState = {
  form: {
    username: '',
    name: '',
    nickname: '',
    userType: '',
    stores: [],
    prevStores: [],
    superior: '',
  },
  error: {
    nameError: undefined,
    nickNameError: undefined,
    usernameError: undefined,
    userTypeError: undefined,
    superiorError: undefined,
  },
};

export const AddUserForm: FunctionComponent<AddUserFormProps> = (props) => {
  // dropdowns
  const userTypeOptions = useSelector(selectUserTypes);
  const superiorOptions = useSelector(selectSuperiors);
  const storeOptions = useSelector(selectStores);
  // misc
  const status = useSelector(selectStatus);

  // react related
  const history = useHistory();
  const dispatch = useDispatch();

  const { firebase, updateStatus } = props;

  useEffect(() => {
    console.log(status);
    switch (status) {
      case 'SUCCESS':
        setState(cloneDeep(INITIAL_STATE));
        updateStatus(status);
        return;
      case 'FAILED':
        updateStatus('FAIL');
        return;
      case 'INITIAL':
        updateStatus('EDITING');
        return;
      default:
        return;
    }
  }, [status, updateStatus]);

  const [state, setState] = useState(cloneDeep(INITIAL_STATE));

  const validateRequired = (value: string) =>
    value !== undefined && value.length > 0;

  const validateNickname = (value: string) =>
    validateRequired(value) && value.length <= NICKNAME_CHAR_LIMIT;

  const validateUsername = (un: string) =>
    /^[A-Za-z0-9._%+-]+@(dominos\.com.sg|dominos\.com.my|whenso\.com)$/.test(
      un,
    );

  // updaters

  /**
   * for updating parent state.status
   */
  const setEditing = () => {
    props.updateStatus('EDITING');
  };

  const updateUsername = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
  ) => {
    setEditing();
    const { value } = data;
    const isValid = validateUsername(value);
    let committing = {
      ...state,
      form: {
        ...state.form,
        username: value,
      },
      error: {
        ...state.error,
        usernameError: !isValid
          ? generateErrorObject('Please enter a valid email address.')
          : undefined,
      },
    };
    setState(committing);
  };

  const updateName = (field: 'name' | 'nickname') => (
    e: React.ChangeEvent<HTMLInputElement>,
    d: InputOnChangeData,
  ) => {
    setEditing();
    const { value } = d;
    let committing = {
      ...state,
    };
    let isValid = true;
    switch (field) {
      case 'name':
        committing.form.name = value;
        isValid = validateRequired(value);
        committing.error.nameError = isValid
          ? undefined
          : generateErrorObject('Full Name is required.');
        break;
      case 'nickname':
        committing.form.nickname = value;
        isValid = validateNickname(value);
        const nicknameerr =
          value.length > 0
            ? `Nickname should not be more than ${NICKNAME_CHAR_LIMIT} characters.`
            : 'Nickname is required.';
        committing.error.nickNameError = isValid
          ? undefined
          : generateErrorObject(nicknameerr);
        break;
      default:
        break;
    }
    setState(committing);
  };

  // dropdown updaters
  const handleSelectUserType = (_event: any, data: DropdownProps) => {
    setEditing();
    const isValid = validateRequired(data.value as string);
    const errMsg = 'User type is required.';

    const isAD = data.value === 'AD';
    const isCSCAD = data.value === 'CSC_AD';
    const isCSCUSR = data.value === 'CSC_USR';
    const isOP = data.value === 'OP';

    if (data.value === 'DM' || data.value === 'OM') {
      const userType = data.value.toLowerCase() as 'om' | 'dm';
      getUnassgined(firebase, userType, state.form.username, dispatch);
    } else {
      getStoreNames(firebase, { dm: false, om: false }, dispatch);
    }

    const isChangeFromAD =
      state.form.userType === 'AD' || state.form.userType === 'OP' || state.form.userType === 'CSC_USR' || state.form.userType === 'CSC_AD' &&
      (data.value === 'DM' || data.value === 'OM');
    if (isChangeFromAD) {
      // reload from previous selected stores
      // because usertype is not admin
      setState({
        ...state,
        form: {
          ...state.form,
          stores: state.form.prevStores,
          userType: data.value,
          superior: '',
        },
        error: {
          ...state.error,
          userTypeError: isValid ? undefined : generateErrorObject(errMsg),
          superiorError: undefined,
        },
      });

      return;
    }

    if (isAD || isCSCAD || isCSCUSR || isOP) {
      // load current selected into previous stores
      // and set selected to none due to admin usertype
      setState({
        ...state,
        form: {
          ...state.form,
          prevStores: state.form.stores,
          stores: [],
          userType: data.value,
          superior: '',
        },
        error: {
          ...state.error,
          userTypeError: isValid ? undefined : generateErrorObject(errMsg),
          superiorError: undefined,
        },
      });

      return;
    }

    setState({
      ...state,
      form: {
        ...state.form,
        superior: '',
        userType: data.value,
      },
      error: {
        ...state.error,
        userTypeError:
          data.value === undefined ? generateErrorObject(errMsg) : undefined,
        superiorError: undefined,
      },
    });
  };

  const handleSelectSuperior = (_event: any, data: DropdownProps) => {
    setEditing();
    const committing = {
      ...state,
      form: {
        ...state.form,
        superior: data.value,
      },
      error: {
        ...state.error,
        superiorError:
          data.value === '' && state.form.userType === 'DM'
            ? generateErrorObject('Superior is required.')
            : undefined,
      },
    };
    setState(committing);
  };

  const handleSelectStores = (_event: any, data: DropdownProps) => {
    setEditing();
    const committing = {
      ...state,
      form: {
        ...state.form,
        stores: data.value,
      },
    };
    setState(committing);
  };

  // property
  const isDM = state.form.userType === 'DM';
  const isAD = state.form.userType === 'AD' || state.form.userType === 'CSC_USR' || state.form.userType === 'CSC_AD' || state.form.userType === 'OP';

  const hasError =
    state.error.nameError !== undefined ||
    state.error.nickNameError !== undefined ||
    state.error.usernameError !== undefined ||
    state.error.userTypeError !== undefined ||
    state.error.superiorError !== undefined;

  const validateForm = (): boolean => {
    let error = cloneDeep(state.error);

    const isNameValid = validateRequired(state.form.name);
    const nameErrorMsg = 'Full Name is required.';
    error.nameError = isNameValid
      ? undefined
      : generateErrorObject(nameErrorMsg);

    const isNicknameValid = validateNickname(state.form.nickname);
    const nicknameErrorMsg =
      state.form.nickname.length > 0
        ? 'Nickname should not be more than 10 characters.'
        : 'Nickname is required.';
    error.nickNameError = isNicknameValid
      ? undefined
      : generateErrorObject(nicknameErrorMsg);

    const isUsernameValid = validateUsername(state.form.username);
    const usernameErrorMsg = 'Please enter a valid email address.';
    error.usernameError = isUsernameValid
      ? undefined
      : generateErrorObject(usernameErrorMsg);

    const isUserTypeValid =
      state.form.userType !== undefined &&
      (state.form.userType as string).length > 0;
    const userTypeErrorMsg = 'User type is required.';
    error.userTypeError = isUserTypeValid
      ? undefined
      : generateErrorObject(userTypeErrorMsg);

    const isSuperiorValid =
      state.form.userType === '' ||
      state.form.userType === 'AD' ||
      state.form.userType === 'OM' ||
      state.form.userType === 'CSC_USR' ||
      state.form.userType === 'CSC_AD' ||
      state.form.userType === 'OP' ||
      (state.form.userType === 'DM' && state.form.superior !== '');
    const superiorErrorMsg = 'Superior is required.';
    error.superiorError = isSuperiorValid
      ? undefined
      : generateErrorObject(superiorErrorMsg);

    const committing = {
      ...state,
      error: error,
    };
    setState(committing);

    const isFormValid =
      isNameValid &&
      isNicknameValid &&
      isUsernameValid &&
      isUserTypeValid &&
      isSuperiorValid;

    return isFormValid;
  };

  const submitUserForm = () => {
    const isFormValid = validateForm();
    if (isFormValid === false) {
      return;
    }

    const form: AddUserActionRequest = {
      username: state.form.username,
      fullName: state.form.name,
      nickName: state.form.nickname,
      superior: state.form.superior as string,
      stores: state.form.stores as string[],
      userType: state.form.userType as string,
    };
    addUser(props.firebase, form, dispatch);
  };

  return (
    <Form onSubmit={submitUserForm}>
      <Form.Group widths={'equal'}>
        <Form.Field>
          <Form.Input
            label={'Full Name'}
            type={'text'}
            value={state.form.name}
            error={state.error.nameError}
            onChange={updateName('name')}
            placeholder={'John Smith'}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label={'Nickname'}
            type={'text'}
            value={state.form.nickname}
            error={state.error.nickNameError}
            onChange={updateName('nickname')}
            placeholder={'Johnny'}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <Form.Input
          label={'Email'}
          type={'email'}
          value={state.form.username}
          onChange={updateUsername}
          placeholder={'john.smith@mail.net'}
          error={state.error.usernameError}
        />
      </Form.Field>
      <Form.Field>
        <Form.Select
          placeholder={'Select User Type'}
          selection
          value={state.form.userType}
          label={'User Type'}
          options={userTypeOptions}
          error={state.error.userTypeError}
          onChange={handleSelectUserType}
        />
      </Form.Field>
      {isDM ? (
        <Form.Field>
          <Form.Select
            label={'Superior Email'}
            placeholder={'Select Superior Email'}
            value={state.form.superior}
            search
            selection
            error={state.error.superiorError}
            onChange={handleSelectSuperior}
            options={superiorOptions}
          ></Form.Select>
        </Form.Field>
      ) : (
        <div />
      )}
      <Form.Field>
        <label>Assigned Stores</label>
        <Dropdown
          placeholder={'Select Assigned Stores'}
          search
          fluid
          selection
          multiple
          value={state.form.stores}
          disabled={isAD}
          onChange={handleSelectStores}
          options={storeOptions}
        />
      </Form.Field>
      <Button type={'button'} negative onClick={history.goBack}>
        Cancel
      </Button>
      <Button disabled={hasError || status === 'PROCESSING'} type={'submit'}>
        Submit
      </Button>
    </Form>
  );
};
