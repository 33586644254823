import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

interface Props {
  active: boolean;
  inverted: boolean;
}

export const SemanticLoader: React.FC<Props> = ({
  children,
  active,
  inverted = true,
}) => {
  return (
    <Dimmer.Dimmable dimmed={active}>
      {children}
      <Dimmer
        inverted={inverted}
        active={active}
        children={<Loader content={'Loading'} />}
      />
    </Dimmer.Dimmable>
  );
};
