import { Dispatch } from 'redux'
import {
  CLOSE_DASHBOARD_STORE_MODAL,
  GET_ABSENT_RIDER_STORES_SUCCESS,
  GET_DASHBOARD,
  GET_DASHBOARD_FAIL,
  GET_DASHBOARD_STORE_DETAILS,
  GET_DASHBOARD_STORE_DETAILS_ACTIVE_DELIVERIES,
  GET_DASHBOARD_STORE_DETAILS_ACTIVE_DELIVERIES_SUCCESS,
  GET_DASHBOARD_STORE_DETAILS_ACTIVE_INSTORES,
  GET_DASHBOARD_STORE_DETAILS_ACTIVE_INSTORES_SUCCESS,
  GET_DASHBOARD_STORE_DETAILS_EMPLOYEE,
  GET_DASHBOARD_STORE_DETAILS_EMPLOYEE_SUCCESS,
  GET_DASHBOARD_SUCCESS,
  GET_FIRST_PERCENTAGE_STORES_FAIL,
  GET_FIRST_PERCENTAGE_STORES_SUCCESS,
  GET_PENDING_ORDER_STORES_FAIL,
  GET_PENDING_ORDER_STORES_SUCCESS,
  GET_SECOND_PERCENTAGE_STORES_FAIL,
  GET_SECOND_PERCENTAGE_STORES_SUCCESS,
  GET_SERVICE_CALL_STORES,
  GET_SERVICE_CALL_STORES_FAIL,
  GET_SERVICE_CALL_STORES_SUCCESS,
  GET_STOP_ORDER_STORES,
  GET_STOP_ORDER_STORES_FAIL,
  GET_STOP_ORDER_STORES_SUCCESS,
  OPEN_DASHBOARD_STORE_MODAL,
  SET_DASHBOARD_FILTERS
} from './types'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { transformDashboard } from '../utils/transformers/dashboard-transformers'
import { DashboardFilters } from '../interfaces/entities/dashboards/filters'
import { FilterDashboardRequest } from '../interfaces/dtos/dashboards/filter-dashboard'
import { SimpleStore } from '../interfaces/requests/stores'
import { queryActiveDeliveries, queryActiveInstores, queryStoreEmployees } from './StoreActions'
import {
  transformStoreActiveDeliveries,
  transformStoreActiveInstores,
  transformStoreEmployees
} from '../utils/transformers/store-transformers'

export const filterDashboard = (request: FilterDashboardRequest) => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_DASHBOARD_FILTERS,
    payload: request.filters,
  });

  loadDashboard(request.firebase, request.filters, dispatch);
}

/**
 * get dashboard informations
 * @param firebase
 * @param filters
 * @param dispatch
 */
export const loadDashboard = (firebase: any, filters: DashboardFilters, dispatch?: Dispatch) => dispatch === undefined
  ? async (dispatch: Dispatch) => loadDashboardFC(firebase, dispatch, filters)
  : loadDashboardFC(firebase, dispatch, filters);

const loadDashboardFC = (firebase: any, dispatch: Dispatch, filters: DashboardFilters) => {
  firebase.getIdToken()
    .then((token: string) => {
      dispatch({
        type: GET_DASHBOARD,
      });
      queryDashbaordInfo(dispatch, token, filters, onGetDashboardSuccess, onGetDashboardFail);
    })
    .catch((error: any) => {
      console.error(error);
    })
}

const queryDashbaordInfo = ( dispatch: Dispatch,
  token: string,
  filters: DashboardFilters,
  onSuccess: (dispatch: Dispatch, response: AxiosResponse) => void,
  onFail: (dispatch: Dispatch, error: any) => void,
) => {
  axios({
    method: 'get',
    url: '/dashboard',
    headers: {'Authorization': token },
    params: {
      om: filters.om.length === 0 ? undefined : filters.om,
      dm: filters.dm.length === 0 ? undefined : filters.dm,
      state: filters.state.length === 0 ? undefined : filters.state,
    },
  })
    .then(response => onSuccess(dispatch, response))
    .catch(error => onFail(dispatch, error));
}

const onGetDashboardSuccess = (dispatch: Dispatch, response: AxiosResponse) => {
  let payload;
  if (response.data !== undefined) {
    payload = transformDashboard(response.data, Date.now());
  }

  dispatch({
    type: GET_DASHBOARD_SUCCESS,
    payload: payload,
  })
}

const onGetDashboardFail = (dispatch: Dispatch, error: any) => {
  dispatch({
    type: GET_DASHBOARD_FAIL,
    payload: error,
  })
}

const onGetFirstPercentageStoreSuccess = (dispatch: Dispatch, response?: AxiosResponse) => {
  dispatch({
    type: GET_FIRST_PERCENTAGE_STORES_SUCCESS,
    // TODO: insert real payload here
    payload: {
      data: {
        stores: [
          {
            name: 'BU9',
            ratio: '600%',
            orderCount: 12,
            riderCount: 2,
            insiderCount: 2,
          },
          {
            name: 'SBU',
            ratio: '700%',
            orderCount: 14,
            riderCount: 2,
            insiderCount: 2,
          },
          {
            name: 'KKL',
            ratio: '900%',
            orderCount: 18,
            riderCount: 2,
            insiderCount: 1,
          },
          {
            name: 'JDO',
            ratio: '933%',
            orderCount: 28,
            riderCount: 3,
            insiderCount: 2,
          },
        ]
      }
    }
  })
}

const onGetFirstPercentageStoreFail = (dispatch: Dispatch, error?: AxiosError) => {
  dispatch({
    type: GET_FIRST_PERCENTAGE_STORES_FAIL,
    // TODO: insert real payload here
    payload: {

    }
  })
}

const onGetSecondPercentageStoreSuccess = (dispatch: Dispatch, response?: AxiosResponse) => {
  dispatch({
    type: GET_SECOND_PERCENTAGE_STORES_SUCCESS,
    // TODO: insert real payload here
    payload: {
      data: {
        stores: [
          {
            name: 'NYT',
            ratio: '1500%',
            orderCount: 15,
            riderCount: 1,
            insiderCount: 1,
          },
          {
            name: 'PBL',
            ratio: '1800%',
            orderCount: 18,
            riderCount: 1,
            insiderCount: 2,
          },
          {
            name: 'BPH',
            ratio: '1200%',
            orderCount: 12,
            riderCount: 1,
            insiderCount: 1,
          },
        ]
      }
    },
  });
}

const onGetSecondPercentageStoreFail = (dispatch: Dispatch, error?: any) => {
  dispatch({
    type: GET_SECOND_PERCENTAGE_STORES_FAIL,
    // TODO: insert real payload here
    payload: {

    }
  })
}

const onGetAbsentRiderStoreSuccess = (dispatch: Dispatch, response?: AxiosResponse) => {
  dispatch({
    type: GET_ABSENT_RIDER_STORES_SUCCESS,
    payload: {
      data: {
        stores: [
          {
            name: 'BU9',
            duration: '',
          },
          {
            name: 'SBU',
            duration: '',
          },
          {
            name: 'KKL',
            duration: '',
          },
          {
            name: 'JDO',
            duration: '',
          },
        ],
      }
    }
  })
}

const onGetAbsentRiderStoreFail = (dispatch: Dispatch, response?: any) => {
  dispatch({
    type: GET_SECOND_PERCENTAGE_STORES_FAIL,
    payload: {

    }
  })
}

const onGetStopOrderStoreSuccess = (dispatch: Dispatch, response?: AxiosResponse) => {
  dispatch({
    type: GET_STOP_ORDER_STORES_SUCCESS,
    payload: {
      data: {
        stores: [
          {
            name: 'BU9',
            category: 'Busy',
            duration: '3:05:00',
          },
          {
            name: 'SBU',
            category: 'Technical',
            duration: '2:34:56',
          },
          {
            name: '',
            category: '',
            duration: '',
          },
          {
            name: '',
            category: '',
            duration: '',
          },
        ]
      }
    }
  })
}

const onGetStopOrderStoreFail = (dispatch: Dispatch, response: any) => {
  dispatch({
    type: GET_STOP_ORDER_STORES_FAIL,
  })
}

const onGetServiceCallStoreSuccess = (dispatch: Dispatch, response?: AxiosResponse) => {
  dispatch({
    type: GET_SERVICE_CALL_STORES_SUCCESS,
    payload: {
      data: {
        stores: [
          {
            name: 'BU9',
            category: '60mins',
            duration: '-00:23:12'
          },
          {
            name: 'BSJ',
            category: '120mins',
            duration: '1:48:33'
          },
          {
            name: 'WP2',
            category: '90mins',
            duration: '00:59:00'
          },
          {
            name: 'CBE',
            category: '60mins',
            duration: '00:15:41'
          },
        ]
      }
    }
  })
}

const onGetServiceCallStoreFail = (dispatch: Dispatch, error?: any) => {
  dispatch({
    type: GET_SERVICE_CALL_STORES_FAIL,
    payload: error,
  })
}

const onGetPendingOrderStoreSuccess = (dispatch: Dispatch, response?: AxiosResponse) => {
  dispatch({
    type: GET_PENDING_ORDER_STORES_SUCCESS,
    payload: {
      data: {
        stores: [
          {
            name: 'NYT',
            delivery: '5',
            carryOut: '',
          },
          {
            name: 'NYT',
            delivery: '',
            carryOut: '2',
          },
          {
            name: 'NYT',
            delivery: '3',
            carryOut: '1',
          },
          {
            name: '',
            delivery: '',
            carryOut: '',
          },
        ]
      }
    }
  })
}

const onGetPendingOrderStoreFail = (dispatch: Dispatch, error?: any) => {
  dispatch({
    type: GET_PENDING_ORDER_STORES_FAIL,
    payload: error,
  })
}

export const openStoreModal = (store: SimpleStore, dispatch?: Dispatch) => dispatch === undefined
  ? async (dispatch: Dispatch) => openStoreModalFC(store, dispatch)
  : openStoreModalFC(store, dispatch);

const openStoreModalFC = (store: SimpleStore, dispatch: Dispatch) => {
  dispatch({
    type: OPEN_DASHBOARD_STORE_MODAL,
    payload: {
      store: store
    }
  });
}

export const closeStoreModal = (dispatch?: Dispatch) => dispatch === undefined
? async (dispatch: Dispatch) => closeStoreModalFC(dispatch)
  : closeStoreModalFC(dispatch);

const closeStoreModalFC = (dispatch: Dispatch) => {
  dispatch({
    type: CLOSE_DASHBOARD_STORE_MODAL,
  })
}

const onGetStoreEmployeeSuccess = (dispatch: Dispatch, payload?: AxiosResponse) => {
  const response = payload?.data === undefined
    ? undefined
    : transformStoreEmployees(payload.data);
  dispatch({
    type: GET_DASHBOARD_STORE_DETAILS_EMPLOYEE_SUCCESS,
    payload: response,
  });
}

const onGetStoreEmployeeFail = (_dispatch: Dispatch, error: any) => {
  // error handling
  console.error(error);
}

const onGetActiveDeliveriesSuccess = (dispatch: Dispatch, payload?: AxiosResponse) => {
  const response = payload?.data === undefined ? undefined : transformStoreActiveDeliveries(payload.data);

  dispatch({
    type: GET_DASHBOARD_STORE_DETAILS_ACTIVE_DELIVERIES_SUCCESS,
    payload: response,
  })
}

const onGetActiveDeliveriesFail = (_dispatch: Dispatch, error: any) => {
  console.error(error);
}

const onGetActiveInstoresSuccess = (dispatch: Dispatch, payload?: AxiosResponse) => {
  const response = payload?.data === undefined ? undefined : transformStoreActiveInstores(payload.data);
  dispatch({
    type: GET_DASHBOARD_STORE_DETAILS_ACTIVE_INSTORES_SUCCESS,
    payload: response,
  })
}

const onGetActiveInstoresFail = (_dispatch: Dispatch, error: any) => {
  console.error(error);
}

export const getStoreDetails = (firebase: any, storeId: string, dispatch?: Dispatch) => dispatch === undefined
  ? async (dispatch: Dispatch) => getStoreDetailsFC(firebase, storeId, dispatch)
  : getStoreDetailsFC(firebase, storeId, dispatch)

const getStoreDetailsFC = (firebase: any, storeId: string, dispatch: Dispatch) => {
  dispatch({
    type: GET_DASHBOARD_STORE_DETAILS
  })

  firebase.getIdToken()
    .then((token: string) => {
      dispatch({
        type: GET_DASHBOARD_STORE_DETAILS_EMPLOYEE,
      })
      dispatch({
        type: GET_DASHBOARD_STORE_DETAILS_ACTIVE_DELIVERIES,
      })
      dispatch({
        type: GET_DASHBOARD_STORE_DETAILS_ACTIVE_INSTORES,
      })
      queryStoreEmployees(dispatch, token, storeId, onGetStoreEmployeeSuccess, onGetStoreEmployeeFail)
      queryActiveDeliveries(dispatch, token, storeId, onGetActiveDeliveriesSuccess, onGetActiveDeliveriesFail)
      queryActiveInstores(dispatch, token, storeId, onGetActiveInstoresSuccess, onGetActiveInstoresFail)
    })
    .catch((error: any) => {
      console.error(error)
    })
}

