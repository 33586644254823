import { SemanticLoader } from 'components/semantic-loader/semantic-loader';
import { cloneDeep } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Divider, Dropdown, Grid, Header, Segment } from 'semantic-ui-react';
import {
  closeStoreModal,
  filterDashboard,
  getStoreDetails,
  loadDashboard,
  openStoreModal,
} from '../../actions/DashboardActions';
import { getSubordinates, getSuperiors } from '../../actions/UserActions';
import { AbsentRiderTable } from '../../components/dashboard/absent-riders/absent-rider-table';
import { AbsentRiderTableBody } from '../../components/dashboard/absent-riders/absent-rider-table-body';
import { InactiveStoreTable } from '../../components/dashboard/inactive/inactive-table';
import { InactiveTableBody } from '../../components/dashboard/inactive/inactive-table-body';
import { PendingOrderTable } from '../../components/dashboard/pending-order/pending-order-table';
import { PendingOrderTableBody } from '../../components/dashboard/pending-order/pending-order-table-body';
import { StorePercentageTable } from '../../components/dashboard/percentage/store-percentage-table';
import { StorePercentageTableBody } from '../../components/dashboard/percentage/store-percentage-table-body';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import { StoreDetailsModal } from '../../components/stores/modal/store-details-modal';
import { TableHeader } from '../../components/table/table-header';
import { POLLING_RATE, STATES } from '../../constants/variable';
import { formatTime } from '../../utils/time-formatter';
import { transformModalStatus } from '../../utils/transformers/store-transformers';
import {
  transformStates,
  transformUserOptions,
} from '../../utils/transformers/user-transformer';
import { StopOrderTableBody } from '../../components/dashboard/stop-order/stop-order-table-body'
import { StopOrderTable } from '../../components/dashboard/stop-order/stop-order-table'
import { ServiceCallTable } from '../../components/dashboard/service-call/service-call-table'
import { ServiceCallTableBody } from '../../components/dashboard/service-call/service-call-table-body'

const percentageHeaders = ['Store', 'Ratio', 'Order', 'Rider', 'Insider'];
const absentRiderHeaders = ['Store', 'Duration'];
const inactiveHeaders = ['Store', 'Last Update'];
const stopOrderHeaders = ['Store', 'Category', 'Duration']
const serviceCallHeaders = ['Store', 'Type', 'Category', 'Duration']
const pendingOrderHeaders = ['Store', 'Delivery', 'Carry Out'];

const isMY = () => window.location.origin.includes('.com.my');

const statesDropdown = () => transformStates(STATES);

class DashboardPageBase extends Component {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.props.loadDashboard(this.props.firebase, this.props.filters);
    this.props.getSuperiors(this.props.firebase);
    this.props.getSubordinates(this.props.firebase);
    this.interval = setInterval(() => {
      this.props.loadDashboard(this.props.firebase, this.props.filters);
    }, POLLING_RATE.TWENTY_SECONDS);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.interval);
    // todo: remove this if auto polling not required
    clearInterval(this.modalInterval);
  }

  // todo: remove this if auto polling not required
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.store.storeId === this.props.store.storeId) {
      return;
    }

    if (prevProps.store.storeId.length === 0) {
      this.modalInterval = setInterval(() => {
        this.props.getStoreDetails(
          this.props.firebase,
          this.props.store.storeId,
        );
      }, POLLING_RATE.TWENTY_SECONDS);
    }

    if (this.props.store.storeId.length === 0) {
      clearInterval(this.modalInterval);
    }
  }

  handleSelectEvent = (filter, value) => {
    let committing = cloneDeep(this.props.filters);
    switch (filter) {
      case 'state':
        committing.state = value;
        break;
      case 'om':
        committing.om = value;
        break;
      case 'dm':
        committing.dm = value;
        break;
      default:
        console.error('unrecognized filter option.');
        return;
    }
    this.props.filterDashboard({
      firebase: this.props.firebase,
      filters: committing,
    });
  };

  openModal = (store) => {
    this.props.openStoreModal(store);
    this.props.getStoreDetails(this.props.firebase, store.storeId);
  };

  render() {
    return (
      <React.Fragment>
        <Header size="medium">Dashboard</Header>
        <Segment>
          <div className={'index-header'}>
            <div className={'index-header-bottom-align pull-left grey-text'}>
              {this.props.updated === 0
                ? 'loading...'
                : `last update ${formatTime(this.props.updated)}`}
            </div>
            <div className={'pull-right'}>
              {
                isMY() ? <Dropdown
                  clearable
                  placeholder={'Filter by State'}
                  search
                  selection
                  value={this.props.filters.state}
                  onChange={(e, d) => this.handleSelectEvent('state', d.value)}
                  options={statesDropdown()}
                /> : null
              }
              <Dropdown
                className={'index-header-dropdown'}
                clearable
                placeholder={'Filter by OM'}
                search
                selection
                value={this.props.filters.om}
                onChange={(e, d) => this.handleSelectEvent('om', d.value)}
                options={transformUserOptions(this.props.oms) ?? []}
              />
              <Dropdown
                className={'index-header-dropdown'}
                clearable
                placeholder={'Filter by DM'}
                search
                selection
                value={this.props.filters.dm}
                onChange={(e, d) => this.handleSelectEvent('dm', d.value)}
                options={transformUserOptions(this.props.dms) ?? []}
              />
            </div>
          </div>
          <Divider />
          <SemanticLoader
            inverted={true}
            active={this.props.dashboardStatus !== 'SUCCESS'}
          >
            <Grid>
              {/** a hack to make it look better,
                * due to the argument for computer={'16/3'}
                * in float not supported
                * render only for computer */}
              <Grid.Row>
                <Grid.Column computer={8} mobile={16}>
                  <Header size={'small'}>Stop Order Store</Header>
                  <StopOrderTable>
                    <TableHeader alignment={'center'} headers={stopOrderHeaders}/>
                    <StopOrderTableBody stores={this.props.stopOrderStores}/>
                  </StopOrderTable>
                </Grid.Column>

                <Grid.Column computer={8} mobile={16}>
                  <Header size={'small'}>Service Call Store</Header>
                  <ServiceCallTable>
                    <TableHeader alignment={'center'} headers={serviceCallHeaders}/>
                    <ServiceCallTableBody stores={this.props.serviceCallStores}/>
                  </ServiceCallTable>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row only={'computer'} columns={'equal'}>
                <Grid.Column>
                  <Header size={'small'}>{'Stores > 500% < 1000%'}</Header>
                  <StorePercentageTable>
                    <TableHeader
                      alignment={'center'}
                      headers={percentageHeaders}
                    />
                    <StorePercentageTableBody
                      openStoreModal={this.openModal}
                      stores={this.props.firstPercentageStores}
                    />
                  </StorePercentageTable>
                </Grid.Column>

                <Grid.Column>
                  <Header size={'small'}>{'Stores > 1000%'}</Header>
                  <StorePercentageTable>
                    <TableHeader
                      alignment={'center'}
                      headers={percentageHeaders}
                    />
                    <StorePercentageTableBody
                      openStoreModal={this.openModal}
                      stores={this.props.secondPercentageStores}
                    />
                  </StorePercentageTable>
                </Grid.Column>

                <Grid.Column>
                  <Header size={'small'}>0 Riders</Header>
                  <AbsentRiderTable>
                    <TableHeader
                      alignment={'center'}
                      headers={absentRiderHeaders}
                    />
                    <AbsentRiderTableBody
                      stores={this.props.absentRiderStores}
                      openStoreModal={this.openModal}
                    />
                  </AbsentRiderTable>
                </Grid.Column>
              </Grid.Row>

              {/* render only for tablet */}
              <Grid.Row only={'tablet mobile'}>
                <Grid.Column mobile={'16'} tablet={'16'}>
                  <Header size={'small'}>{'Stores > 500% < 1000%'}</Header>
                  <StorePercentageTable>
                    <TableHeader
                      alignment={'center'}
                      headers={percentageHeaders}
                    />
                    <StorePercentageTableBody
                      openStoreModal={this.openModal}
                      stores={this.props.firstPercentageStores}
                    />
                  </StorePercentageTable>
                </Grid.Column>

                <Grid.Column mobile={'16'} tablet={'16'}>
                  <Header size={'small'}>{'Stores > 1000%'}</Header>
                  <StorePercentageTable>
                    <TableHeader
                      alignment={'center'}
                      headers={percentageHeaders}
                    />
                    <StorePercentageTableBody
                      openStoreModal={this.openModal}
                      stores={this.props.secondPercentageStores}
                    />
                  </StorePercentageTable>
                </Grid.Column>

                <Grid.Column mobile={'16'} tablet={'16'}>
                  <Header size={'small'}>0 Riders</Header>
                  <AbsentRiderTable>
                    <TableHeader
                      alignment={'center'}
                      headers={absentRiderHeaders}
                    />
                    <AbsentRiderTableBody
                      stores={this.props.absentRiderStores}
                      openStoreModal={this.openModal}
                    />
                  </AbsentRiderTable>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column computer={'8'} mobile={'16'}>
                  <Header size={'small'}>Inactive Store</Header>
                  <div style={{ maxHeight: 350, overflow: 'auto', overflowY: 'overlay' }}>
                    <InactiveStoreTable>
                      <TableHeader
                        style={{ position: 'sticky', insetBlockStart: 0 }}
                        alignment={'center'}
                        headers={inactiveHeaders}
                      />
                      <InactiveTableBody
                        openStoreModal={this.openModal}
                        stores={this.props.inactiveStores}
                      />
                    </InactiveStoreTable>
                  </div>
                </Grid.Column>

                <Grid.Column computer={'8'} mobile={'16'}>
                  <Header size={'small'}>
                    {'Pending Orders > 15 minutes'}
                  </Header>
                  <PendingOrderTable>
                    <TableHeader
                      alignment={'center'}
                      headers={pendingOrderHeaders}
                    />
                    <PendingOrderTableBody
                      openStoreModal={this.openModal}
                      stores={this.props.pendingOrderStores}
                    />
                  </PendingOrderTable>
                </Grid.Column>
              </Grid.Row>

            </Grid>
          </SemanticLoader>
        </Segment>
        <StoreDetailsModal
          status={this.props.store.storeId !== ''}
          loading={transformModalStatus(this.props.storeDetails.status)}
          storeInfo={{
            storeId: this.props.store.storeId,
            storeCode: this.props.store.storeCode,
            storeName: this.props.store.storeName,
          }} // current available info from list
          storeDetails={this.props.storeDetails} // further queried details
          closeModal={this.props.closeStoreModal}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // dashboard main tables
    firstPercentageStores: state.dashboard.list.firstPercentageStores,
    secondPercentageStores: state.dashboard.list.secondPercentageStores,
    absentRiderStores: state.dashboard.list.absentRiderStores,
    inactiveStores: state.dashboard.list.inactiveStores,
    stopOrderStores: state.dashboard.list.stopOrderStores,
    serviceCallStores: state.dashboard.list.serviceCallStores,
    pendingOrderStores: state.dashboard.list.pendingOrderStores,
    updated: state.dashboard.list.updated,

    // status
    dashboardStatus: state.dashboard.status.list,

    // users
    oms: state.user.shared.superiors,
    dms: state.user.shared.subordinates,

    // filters
    filters: state.dashboard.filters,

    // modal
    store: state.dashboard.modal.store,
    storeDetails: state.dashboard.modal.storeDetails,
  };
};

const mapActionToProps = {
  loadDashboard: loadDashboard,
  getSuperiors: getSuperiors,
  getSubordinates: getSubordinates,
  filterDashboard: filterDashboard,
  closeStoreModal: closeStoreModal,
  openStoreModal: openStoreModal,
  getStoreDetails: getStoreDetails,
};

const condition = (authUser) => authUser != null;

const DashboardPage = compose(
  withFirebase,
  withRouter,
  withAuthorization(condition),
  connect(mapStateToProps, mapActionToProps),
)(DashboardPageBase);

export default DashboardPage;
