import { SET_SIDEBAR_VISIBILITY } from 'actions/types';
import { VERSION } from 'config';
import * as ROUTES from 'constants/routes';
import React, {useMemo, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Divider, Menu, Sidebar } from 'semantic-ui-react';
import {RootState} from "../interfaces/states/redux/root-state";
import {ApiCurrentUser} from "../interfaces/apis/users/current-user";
import Visibility from "./utils/visibility";

const inlineStyles = {
  sidebarOverrides: {
    height: 'calc(100vh - 4em)',
    position: 'fixed',
    top: '4em',
  },
};

export const MenuComponent = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const visible = useSelector((state: any) => state.main.sidebarVisible);
  const [active, setActive] = useState('');

  const enableSalesSection = true;

  const userInfo = useSelector<RootState, ApiCurrentUser>(
      state => state.auth.currentUserInfo)
  const userType = useMemo(() => {
    return userInfo?.userType ?? ''
  }, [userInfo])

  const userEmail = useMemo(() => {
    return userInfo?.userInfo?.email ?? ''
  }, [userInfo])

  const onItemClick = (name: string) => (e: any, d: any) => {
    switch (name) {
      case 'users':
        setActive(name);
        history.push(ROUTES.USERS);
        break;
      case 'stores':
        setActive(name);
        history.push(ROUTES.STORES);
        break;
      case 'dashboard':
        setActive(name);
        history.push(ROUTES.DASHBOARD);
        break;
      case 'sales':
        setActive(name);
        history.push(ROUTES.SALES);
        break;
      default:
        break;
    }

    dispatch({
      type: SET_SIDEBAR_VISIBILITY,
      payload: false,
    });
  };

  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      inverted
      vertical
      borderless
      onHide={onItemClick('')}
      visible={visible}
      style={inlineStyles.sidebarOverrides}
      width="thin">
      <Menu.Item
        active={'dashboard' === active}
        onClick={onItemClick('dashboard')}>
        Dashboard
      </Menu.Item>
      <Menu.Item active={'stores' === active} onClick={onItemClick('stores')}>
        Stores
      </Menu.Item>

      <Visibility condition={['AD', 'CSC_AD'].includes(userType)}>
        <Divider />
        <Menu.Item active={'users' === active} onClick={onItemClick('users')}>
          Users
        </Menu.Item>
      </Visibility>

      <Visibility condition={['OM', 'DM', 'AD', 'OP'].includes(userType)}>
        <Divider />
        <Menu.Item active={'sales' === active} onClick={onItemClick('sales')}>
          Sales
        </Menu.Item>
      </Visibility>

      <p className={'version-footer'}>Version {VERSION}</p>
    </Sidebar>
  );
};
