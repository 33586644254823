import { PublicActionTypes } from '../interfaces/actions/public-action-interface'
import {
  DO_PASSWORD_RESET,
  DO_PASSWORD_RESET_FAIL,
  DO_PASSWORD_RESET_SUCCESS,
  RESET_DO_PASSWORD_RESET
} from '../actions/types'

interface PublicActionState {
  status: {
    forgetPassword: 'INITIAL' | 'PROCESSING' | 'SUCCESS' | 'FAILED'
  }
}

const INITIAL_STATE: PublicActionState = {
  status: {
    forgetPassword: 'INITIAL',
  }
}

export default (state = INITIAL_STATE, action: PublicActionTypes): PublicActionState=> {
  switch(action.type) {
    case DO_PASSWORD_RESET:
      return {
        ...state,
        status: {
          ...state.status,
          forgetPassword: 'PROCESSING',
        },
      }
    case DO_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          forgetPassword: 'SUCCESS',
        },
      }
    case DO_PASSWORD_RESET_FAIL:
      return {
        ...state,
        status: {
          ...state.status,
          forgetPassword: 'FAILED',
        },
      }
    case RESET_DO_PASSWORD_RESET:
      return {
        ...state,
        status: {
          ...state.status,
          forgetPassword: 'INITIAL',
        },
      }
    default:
      return state;
  }
}
