import {
    STOP_WEBSOCKET,
    START_WEBSOCKET
} from '../actions/types';

const INITIAL_STATE = {
    timeout: false,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {

		case START_WEBSOCKET:
			return {...state, timeout: false};

		case STOP_WEBSOCKET:
			return { ...state, timeout: true};

		default:
			return state;
	}
};
