import { cloneDeep } from 'lodash';

export const FOOTER_RECORDS_INITIAL_STATE = {
  start: 0,
  end: 0,
  total: 0,
};

/**
 * generate records object for footer
 * i.e. Showing (start) to (end) of (total) records variable.
 * @param isEmpty flag for when table is empty
 * @param isEnd flag for when current page is last page
 * @param totalEntities total records
 * @param perPage number of entities per page
 * @param currentPage current page index
 */
export const generateRecords = (isEmpty: boolean, isEnd: boolean, totalEntities: number, perPage: number, currentPage: number) => {
  let footerRecords = cloneDeep(FOOTER_RECORDS_INITIAL_STATE);
  // if empty return pre determined all 0 object
  if (isEmpty) {
    return footerRecords;
  }

  // avoid calculating end value that exceeds total entities,
  // we use total entities count instead when reaching last page
  footerRecords.end = isEnd ? totalEntities : perPage * currentPage;
  footerRecords.start = (currentPage - 1) * perPage + 1;
  footerRecords.total = totalEntities;
  return footerRecords;
}
