
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Button, Form, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { doForgetPassword, resetForgetPassword } from '../../actions/PublicActions';
import { withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';

class PasswordForgetBase extends Component {
  constructor (props) {
    super(props);

    this.state = {
      form: {
        email: '',
      }
    }
  }

  componentWillUnmount () {
    this.props.resetForgetPassword();
  }

  updateEmail = (email) => {
    const committing = {
      ...this.state,
      form: {
        ...this.state.form,
        email: email,
      }
    };
    this.setState(committing);
  }

  handleSubmit = () => {
    console.log(this.state.form.email);
    this.props.doForgetPassword(this.props.firebase, this.state.form.email);
  }

  renderMessage = () => {
    switch(this.props.status) {
      case 'SUCCESS':
        return <p style={{color: 'green'}}>An email was sent to reset your password. You can sign in with your new password by clicking {<Link to={ROUTES.SIGN_IN}>here</Link>}.</p>;
      case 'FAILED':
        return <p style={{color: 'red'}}>Something went wrong. Please contact support.</p>;
      default:
        return null;
    }
  }

  render () {
    return (
      <Grid verticalAlign='middle' style={{ height: '100vh' }}>
        <Grid.Column width={'5'}/>
        <Grid.Column width={'6'}>
          <Segment>
            <Header textAlign='left'>
              Reset Password
            </Header>
            <Form size='large' onSubmit={() => this.handleSubmit()}>
              <Form.Input fluid value={this.state.form.email} onChange={(e, d) => this.updateEmail(d.value)} placeholder='Your email address.' />
              {
                this.renderMessage()
              }
              <Button
                fluid
                size={'large'}
                disabled={this.props.status === 'SUCCESS'}
                icon={this.props.status === 'SUCCESS'}
                content={
                  this.props.status === 'SUCCESS'
                    ? <Icon name={'checkmark'}/>
                    : 'Submit'
                }
              />
            </Form>
          </Segment>
        </Grid.Column>
        <Grid.Column width={'5'}/>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.public.status.forgetPassword,
  };
};

const mapActionToProps = {
  doForgetPassword: doForgetPassword,
  resetForgetPassword: resetForgetPassword,
}


const PasswordForgetPage = compose(
  withFirebase,
  withRouter,
  connect(mapStateToProps, mapActionToProps),
)(PasswordForgetBase)

export default PasswordForgetPage;

export { PasswordForgetPage };
