import { DEBUG } from 'config';
import {
  CARRYOUT_ORDER_UPDATED,
  CLAIM_MANAGER_CODE,
  CLAIM_MANAGER_CODE_FAIL,
  CLAIM_MANAGER_CODE_SUCCESS,
  CUSTOMER_CLICKED_DRIVEUP,
  CUSTOMER_CLICKED_DRIVEUP_FAIL,
  CUSTOMER_CLICKED_DRIVEUP_SUCCESS,
  CUSTOMER_CLICKED_WALKUP,
  CUSTOMER_CLICKED_WALKUP_FAIL,
  CUSTOMER_CLICKED_WALKUP_SUCCESS,
  CUSTOMER_ORDER_FINISH,
  CUSTOMER_ORDER_SENT,
  GET_CHECK_STORE_CODE,
  GET_CHECK_STORE_CODE_FAIL,
  GET_CHECK_STORE_CODE_SUCCESS,
  GET_CUSTOMER_ARRIVED_STATUS,
  GET_CUSTOMER_ARRIVED_STATUS_FAIL,
  GET_CUSTOMER_ARRIVED_STATUS_SUCCESS,
  GET_ORDER_STATUS,
  GET_ORDER_STATUS_FAIL,
  GET_ORDER_STATUS_SUCCESS,
  GO_TO_STEP,
  NEXT_STEP,
  ORDER_NOT_RECEIVED_COMPLETED_SUCCESS,
  PREV_STEP,
  REFRESH,
  SET_SIDEBAR_VISIBILITY,
  UPDATE_CUSTOMER_VEHICLE_DETAILS,
  UPDATE_CUSTOMER_VEHICLE_DETAILS_FAIL,
  UPDATE_CUSTOMER_VEHICLE_DETAILS_SUCCESS,
  UPDATE_MOCK_CARRY_OUT_INFO,
  UPDATE_TASK_LOG,
} from '../actions/types';

const INITIAL_STATE = {
  page: 1,
  error: '',
  loading: false,
  currentCarryoutInfo: null,
  sidebarVisible: true,
};

export default (state = INITIAL_STATE, action) => {
  let { currentCarryoutInfo } = state;
  switch (action.type) {
    case SET_SIDEBAR_VISIBILITY:
      return {
        ...state,
        sidebarVisible: action.payload,
      };
    case GO_TO_STEP:
      return {
        ...state,
        page: action.payload,
      };

    case NEXT_STEP:
      return {
        ...state,
        page: state.page + 1,
      };

    case PREV_STEP:
      return {
        ...state,
        page: state.page - 1,
      };

    case GET_ORDER_STATUS:
      return {
        ...state,
      };

    case GET_ORDER_STATUS_SUCCESS:
      if (DEBUG) console.log(action.payload.carryOutInfo);

      return {
        ...state,
        loading: false,
        currentCarryoutInfo: action.payload.carryOutInfo,
      };

    case GET_ORDER_STATUS_FAIL:
      return {
        ...state,
      };

    case GET_CUSTOMER_ARRIVED_STATUS:
      return {
        ...state,
      };

    case GET_CUSTOMER_ARRIVED_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentCarryoutInfo: action.payload.carryOutInfo
          ? action.payload.carryOutInfo
          : state.currentCarryoutInfo,
      };

    case GET_CUSTOMER_ARRIVED_STATUS_FAIL:
      return {
        ...state,
      };

    case CUSTOMER_CLICKED_WALKUP:
      return {
        ...state,
      };

    case CUSTOMER_CLICKED_WALKUP_SUCCESS:
      return {
        ...state,
        loading: false,
        currentCarryoutInfo: action.payload.carryOutInfo
          ? action.payload.carryOutInfo
          : state.currentCarryoutInfo,
      };

    case CUSTOMER_CLICKED_WALKUP_FAIL:
      return {
        ...state,
      };

    case CUSTOMER_CLICKED_DRIVEUP:
      return {
        ...state,
      };

    case CUSTOMER_CLICKED_DRIVEUP_SUCCESS:
      return {
        ...state,
        loading: false,
        currentCarryoutInfo: action.payload.carryOutInfo
          ? action.payload.carryOutInfo
          : state.currentCarryoutInfo,
      };

    case CUSTOMER_CLICKED_DRIVEUP_FAIL:
      return {
        ...state,
      };

    case UPDATE_CUSTOMER_VEHICLE_DETAILS:
      return {
        ...state,
      };

    case UPDATE_CUSTOMER_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentCarryoutInfo: action.payload.carryOutInfo
          ? action.payload.carryOutInfo
          : state.currentCarryoutInfo,
      };

    case UPDATE_CUSTOMER_VEHICLE_DETAILS_FAIL:
      return {
        ...state,
      };

    case GET_CHECK_STORE_CODE:
      return {
        ...state,
      };

    case GET_CHECK_STORE_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentCarryoutInfo: action.payload.carryOutInfo
          ? action.payload.carryOutInfo
          : state.currentCarryoutInfo,
      };

    case GET_CHECK_STORE_CODE_FAIL:
      return {
        ...state,
      };

    case CLAIM_MANAGER_CODE:
      return {
        ...state,
      };

    case CLAIM_MANAGER_CODE_SUCCESS:
      return {
        ...state,
        currentCarryoutInfo: action.payload.carryOutInfo
          ? action.payload.carryOutInfo
          : state.currentCarryoutInfo,
      };

    case CLAIM_MANAGER_CODE_FAIL:
      return {
        ...state,
      };

    case ORDER_NOT_RECEIVED_COMPLETED_SUCCESS:
      return {
        ...state,
        currentCarryoutInfo: action.payload.carryOutInfo
          ? action.payload.carryOutInfo
          : state.currentCarryoutInfo,
      };

    case CUSTOMER_ORDER_SENT:
      return {
        ...state,
        currentCarryoutInfo: action.payload,
      };

    case CUSTOMER_ORDER_FINISH:
      return {
        ...state,
        currentCarryoutInfo: action.payload,
      };

    case CARRYOUT_ORDER_UPDATED:
      return {
        ...state,
        currentCarryoutInfo: action.payload,
      };

    case REFRESH:
      return {
        ...state,
        currentCarryoutInfo: action.payload,
      };

    case UPDATE_TASK_LOG:
      return {
        ...state,
        currentCarryoutInfo: {
          ...currentCarryoutInfo,
          taskLogInfoList: [
            ...currentCarryoutInfo.taskLogInfoList,
            action.payload,
          ],
        },
      };

    case UPDATE_MOCK_CARRY_OUT_INFO:
      return {
        ...state,
        currentCarryoutInfo: {
          ...currentCarryoutInfo,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};
