import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import * as LocalStorage from '../../utils/localstorage';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthentication = Component => {
  
  class WithAuthentication extends React.Component {
    _isMounted = false;
    constructor(props) {
      super(props);

      this.state = {
        authUser: null
      }
    }

    componentDidMount() {
      this._isMounted = true;
      
        this.listener = this.props.firebase.auth.onAuthStateChanged(
          authUser => {
            if (this._isMounted) {
              if(authUser) {
                LocalStorage.put('authUser', authUser);
                this.setState({ authUser });
              } else {
                LocalStorage.remove('authUser');
                this.setState({ authUser: null });
              }
     
            }
          }
        );
     
    }

    componentWillUnmount() {
      this._isMounted = false;

      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }

  }

  const mapDispatchToProps = dispatch => ({
    onSetAuthUser: authUser =>
      dispatch({ type: 'AUTH_USER_SET', authUser }),
  });

  return compose(
    withFirebase,
    connect(
      null,
      mapDispatchToProps,
    ),
  )(WithAuthentication);
};

export default withAuthentication;
