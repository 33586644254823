import React, { useCallback, useEffect, useMemo } from 'react'
import { Button, Form, Modal } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'interfaces/states/redux/root-state'
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown'
import SalesAction from 'actions/SalesAction'
import Firebase from 'components/Firebase'
import { getSubordinates, getSuperiorsFC } from 'actions/UserActions'
import { POLLING_RATE, STATES } from 'constants/variable'
import { useFormik } from 'formik'
import { v4 as uuidV4 } from 'uuid'
import useFilterSalesMutation from 'hooks/sales/use-filter-sales-mutation'
import { useAllStores } from 'hooks/stores/use-all-stores'

interface Props {
  open: boolean;
  userType: String;
  setOpen: (value: boolean) => void;
  firebase: Firebase;
}

const SalesDataFilterModal = (props: Props) => {
  const { setOpen, open } = props
  const dispatch = useDispatch()
  const oms = useSelector((state: RootState) => state.user.shared.superiors)
  const dms = useSelector((state: RootState) => state.user.shared.subordinates)
  const { data: stores, mutate: queryStores } = useAllStores(props.firebase);
  const { mutation } = useFilterSalesMutation(props.firebase)

  const isSG = () => window.location.origin.includes('.com.sg');

  const queryReport = useCallback((filter: string, value: string, text: string, id: string) => {
    return mutation(filter, value).then((data) => {
      dispatch(
        SalesAction.GetFilteredReportSuccess(filter, value, text, id, data))
    }).catch(err => {
      dispatch(SalesAction.GetFilteredReportFail(err))
    })
  }, [])

  const formik = useFormik({
    initialValues: {
      filter: '',
      filterText: '',
      filterValue: '',
    },
    onSubmit: (v, h) => {
      const id = uuidV4();
      queryReport(v.filter, v.filterValue, v.filterText,  id)
      .then(() => {
      })
      .then(() => {
        h.setSubmitting(false)
        setOpen(false)
        resetForm()
      });
    },
  })
  const { setFieldValue, setValues, resetForm } = formik
  const { filter, filterValue } = formik.values

  const options = useMemo(() => {
    switch (filter) {
      case 'om':
        return oms.map((o) => ({ text: o.nickName, value: o.nickName }))
      case 'dm':
        return dms.map((o) => ({ text: o.nickName, value: o.nickName }))
      case 'state':
        return STATES.map((s) => ({ text: s, value: s }));
      case 'storeId':
        return stores.map((s) => ({ text: `${s.storeId} ${s.storeCode} ${s.storeName}`, value: s.storeId }));
      default:
        return []
    }
  }, [filter, oms, dms])

  const updateFilter = useCallback(
    (_: React.SyntheticEvent, d: DropdownProps) => {
      setFieldValue('filter', d.value)
      setFieldValue('filterValue', '')
      setFieldValue('filterText', '')
    }, [setFieldValue])

  const updateValue = useCallback(
    (_: React.SyntheticEvent, d: DropdownProps) => {
      const t = d.options!.find(o => o.value === d.value)?.text as string ?? '';
      setFieldValue('filterValue', d.value)
      setFieldValue('filterText', t)
    }, [setFieldValue])

  const handleClose = useCallback(() => {
    if (formik.isSubmitting) {
      console.log('exit')
      return
    }

    setOpen(false)
    resetForm()
  }, [resetForm, formik.isSubmitting])

  const getFilterOption = (userType: String) => {

    let filterOptionList = [
      {
        text: 'DM',
        value: 'dm',
      },
      {
        text: 'OM',
        value: 'om',
      },
      {
        text: 'State',
        value: 'state',
      },
      {
        text: 'Store',
        value: 'storeId',
      }
    ]

    if(userType == 'OM') {

      filterOptionList = filterOptionList.filter(function(obj) {
        return obj.text !=='OM';
      });

    }

    if(userType == 'DM') {

      filterOptionList = filterOptionList.filter(function(obj) {
        return obj.text !=='OM' && obj.text !== 'DM';
      });

    }

    if(isSG()) {
      filterOptionList = filterOptionList.filter(function(obj) {
        return obj.text !=='State';
      });
    }

    return filterOptionList
  }

  useEffect(() => {
    switch (filter) {
      case 'om':
        getSuperiorsFC(props.firebase, dispatch)
        break
      case 'dm':
        getSubordinates(props.firebase, dispatch)
        break
      case 'storeId':
        queryStores();
        break;
      default:
    }
  }, [filter])



  return <Modal open={open} onClose={handleClose}>
    <Modal.Content>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Dropdown label={'Filter Type'} selection search
                       placeholder={'Select Filter Type'}
                       options={getFilterOption(props.userType)}
                       value={filter} onChange={updateFilter}/>
        <Form.Dropdown label={'Value'} selection search options={options}
                       value={filterValue} onChange={updateValue}/>
        <Button type={'submit'} loading={formik.isSubmitting}
                disabled={formik.isSubmitting || !formik.isValid} primary fluid
                content={'Filter'}/>
      </Form>
    </Modal.Content>
  </Modal>
}

export default SalesDataFilterModal
