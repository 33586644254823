import React, { useCallback } from 'react'
import { Button } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'interfaces/states/redux/root-state'
import Visibility from 'components/utils/visibility'
import SalesAction from 'actions/SalesAction'

interface Props {
  setOpen: (value: boolean) => void;

  handleClear: () => void;
}

const AddFilterSegment = (props: Props) => {
  const filteredInfo = useSelector((state: RootState) => state.sales.filtered);

  return <div style={{ textAlign: 'right', marginTop: '1.0rem', marginBottom: '1.0rem'}}>
    <Button type={'button'} content={'Filter'}
            onClick={() => props.setOpen(true)}/>
    <Visibility condition={filteredInfo != undefined}>
      <Button type={'button'} primary content={'Reset'} onClick={props.handleClear} />
    </Visibility>
  </div>
}

export default AddFilterSegment;
