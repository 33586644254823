import {
  BACKEND_LOGOUT_USER,
  GET_FCM_TOKEN,
  GET_FCM_TOKEN_FAIL,
  GET_FCM_TOKEN_SUCCESS, GET_USER_INFO_FAIL,
  GET_USER_INFO_SUCCESS,
  INVALIDATE_SESSION,
  LOGIN_USER,
  LOGIN_USER_FAIL,
  LOGIN_USER_SUCCESS,
  REGISTER_USER_BACKEND,
  REGISTER_USER_BACKEND_FAIL,
  REGISTER_USER_BACKEND_SUCCESS,
  REGISTER_USER_FIREBASE,
  REGISTER_USER_FIREBASE_FAIL,
  REGISTER_USER_FIREBASE_SUCCESS,
  RESET_LOGIN_FORM,
  RESET_SESSION,
  RESET_SIGNUP_FORM,
} from '../actions/types'
import * as LocalStorage from '../utils/localstorage';

const INITIAL_STATE = {
  firebaseUser: null,
  currentFirebaseUser: null,
  currentUserInfo: null,
  error: '',
  userInfoError: '',
  loading: false,
  notificationToken: '',
  sessionInvalid: false,
};

const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INVALIDATE_SESSION:
      return {
        ...state,
        sessionInvalid: true,
      };
    // probably can remove since when log in again, it will be reset to initial value
    case RESET_SESSION:
      return {
        ...state,
        sessionInvalid: false,
      };

    case RESET_LOGIN_FORM:
      return INITIAL_STATE;

    case RESET_SIGNUP_FORM:
      return INITIAL_STATE;

    case LOGIN_USER:
      return { ...state, loading: true, error: '', userInfoError: '', };

    case GET_USER_INFO_SUCCESS:
      LocalStorage.put('currentUser', action.payload.data);
      return {
        ...state,
        ...INITIAL_STATE,
        loading: false,
        currentUserInfo: action.payload.data,
      };

    case GET_USER_INFO_FAIL:
      return {
        ...state,
        userInfoError: action.payload,
      }

    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
        loading: false,
        currentFirebaseUser: action.payload,
      };

    case LOGIN_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case REGISTER_USER_FIREBASE:
      return {
        ...state,
        ...INITIAL_STATE,
        loading: true,
        error: '',
      };

    case REGISTER_USER_FIREBASE_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
        loading: false,
        firebaseUser: action.payload,
      };
    case REGISTER_USER_FIREBASE_FAIL:
      return {
        ...state,
        loading: false,
        error: 'Error on Firebase',
      };

    case REGISTER_USER_BACKEND:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case REGISTER_USER_BACKEND_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };

    case REGISTER_USER_BACKEND_FAIL:
      return {
        ...state,
        loading: false,
        error: 'Failed on Backend',
      };

    case BACKEND_LOGOUT_USER:
      return INITIAL_STATE;

    case GET_FCM_TOKEN:
      return { ...state };

    case GET_FCM_TOKEN_SUCCESS:
      return { ...state, notificationToken: action.payload };

    case GET_FCM_TOKEN_FAIL:
      return { ...state };

    default:
      return state;
  }
};

export default AuthReducer;
