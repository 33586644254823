import { combineReducers } from 'redux';

import MainReducer from './MainReducer';
import LoadingReducer from './LoadingReducer';
import WebsocketReducer from './WebsocketReducer';
import AuthReducer from './AuthReducer';
import StoreReducer from './StoreReducer';
import UserReducer from './UserReducer'
import DashboardReducer from './DashboardReducer'
import PublicReducer from './PublicReducer'
import SalesReducer from './SalesReducer'

const appReducer = combineReducers({
    auth: AuthReducer,
    dashboard: DashboardReducer,
    main: MainReducer,
    loading: LoadingReducer,
    store: StoreReducer,
    user: UserReducer,
    websocket: WebsocketReducer,
    public: PublicReducer,
    sales: SalesReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
