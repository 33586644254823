import React from 'react';
import { AdminSalesPanel } from 'components/sales/admin-sales-panel'
import { UnknownTypePanel } from 'components/sales/unknown-type-panel'
import {HidePanel} from "components/sales/hide-panel";
import Firebase from 'components/Firebase'

interface Props {
  type: string;
  firebase: Firebase;
}

export const SalesContentSwitch = (props: Props) => {
  switch (props.type) {
    case 'AD':
    case 'OM':
    case 'DM':
    case 'OP':
      return <AdminSalesPanel firebase={props.firebase} />

    case 'CSC_AD':
      return <HidePanel/>;

    case 'CSC_USR':
      return <HidePanel/>;

    default:
      return <UnknownTypePanel />;
  }
}
