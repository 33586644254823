import { logoutUser, resetSession } from 'actions';
import { toggleSidebar } from 'actions/MainAction';
import { MenuComponent } from 'components/NewMenuComponent';
import { withAuthorization } from 'components/Session';
import TopNavComponent from 'components/TopNav/TopNavComponent';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Container, Sidebar } from 'semantic-ui-react';
import * as ROUTES from '../constants/routes';
import CategoriesPage from './Categories';
import DashboardPage from './Dashboard';
import PosSettingPage from './PosSetting';
import ProductsPage from './Products';
import ReportPage from './report';
import SalesChannelPage from './SalesChannel';
import StorePage from './stores';
import UserPage from './Users';
import AddUserPage from './Users/add-user';
import EditUserPage from './Users/edit-user';
import SalesPage from './sales'
import { withFirebase } from '../components/Firebase'
import { getCurrentUser } from '../actions'

class PagesBase extends Component {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.props.getCurrentUser(this.props.firebase);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.sessionInvalid !== this.props.sessionInvalid) {
      this.handleSessionUpdate(
        prevProps.sessionInvalid,
        this.props.sessionInvalid,
      );
    }
  }

  handleSessionUpdate(prev, current) {
    if (prev === false && current === true) {
      this.props.logoutUser(this.props);
    }
  }

  onToggleSidebar = (e, d) => {
    this.props.toggleSidebar();
  };

  render() {
    return (
      <div className="custom-grid">
        <div className="menu">
          <TopNavComponent className="top-menu" />
        </div>
        <div className="main-content">
          <Sidebar.Pushable className={'enable-fixed'}>
            <MenuComponent />

            <Sidebar.Pusher>
              <Container fluid style={{ padding: '2em' }}>
                <Switch>
                  <Route path={ROUTES.DASHBOARD} component={DashboardPage} />
                  <Route
                    exact
                    path={`${ROUTES.STORES}`}
                    component={StorePage}
                  />
                  <Route exact path={ROUTES.USERS} component={UserPage} />
                  <Route exact path={ROUTES.ADDUSER} component={AddUserPage} />
                  <Route
                    path={`${ROUTES.EDITUSER}/:id`}
                    component={EditUserPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.PRODUCTS}`}
                    component={ProductsPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.CATEGORIES}`}
                    component={CategoriesPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.REPORT}`}
                    component={ReportPage}
                  />
                  <Route
                    exact
                    path={ROUTES.SALES}
                    component={SalesPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.SALESCHANNEL}`}
                    component={SalesChannelPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.POSSETTING}`}
                    component={PosSettingPage}
                  />

                  <Redirect to={ROUTES.NOTFOUND} />
                </Switch>
              </Container>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </div>
      </div>
    );
  }
}

const mapActionToProps = {
  logoutUser: logoutUser,
  resetSession: resetSession,
  toggleSidebar: toggleSidebar,
  getCurrentUser: getCurrentUser,
};

const mapStateToProps = (props) => {
  return {
    sessionInvalid: props.auth.sessionInvalid,
    sidebarVisible: props.main.sidebarVisible,
  };
};

const condition = (authUser) => authUser != null;

const Pages = compose(
  withRouter,
  withFirebase,
  withAuthorization(condition),
  connect(mapStateToProps, mapActionToProps),
)(PagesBase);

export default Pages;
