export const BASE_URL = 'https://vsignapi.dominos.com.my/api/';
// export const BASE_URL = 'http://172.23.0.8:9000/api/';
//export const BASE_URL = 'https://dfstestbed.whenso.com/vsapi/';
//export const BASE_URL = 'http://127.0.0.1:52106/api/';


export const API_KEY = '23rusdaljk203ad2bl';
export const DEBUG = false;
export const VERSION = '1.0.26';
export const REFRESH_MILISECOND = 60000;

export const BUGSNAG_API_KEY = '76e073f03583832db90e1e458b18819e';
export const GOOGLE_API_KEY = 'AIzaSyA6V0797_QAUJhYSYzZg6keM3HfXkjzVFM';
