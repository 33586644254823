import { TableHeader } from 'components/table/table-header';
import {
  DeliveryActivityInfo,
  InStoreActivityInfo,
} from 'interfaces/entities/stores/modal/order';
import React from 'react';
import { Button, Icon, Modal, Table } from 'semantic-ui-react';
import { formatTime } from 'utils/time-formatter';
import { OrderDetails } from './store-details-modal';

interface Props {
  order?: OrderDetails;
  onClose: (e: any, d: any) => void;
}

const deliveryHeaders = ['Makeline', 'Oven', 'Routing Station', 'Out The Door'];
const inStoreHeaders = ['Makeline', 'Oven'];

const processTime = (millis: number) =>
  millis === 0 ? '-' : formatTime(millis, 'TIME_ONLY');

/**
 * render activity info for InStoreActivityInfo type
 * @param activities eat in or carry out activityinfo
 */
const renderInStoreActivity = (activities: InStoreActivityInfo) => (
  <React.Fragment>
    <TableHeader alignment={'left'} headers={inStoreHeaders} />
    <Table.Body>
      <Table.Row>
        <Table.Cell>{processTime(activities.makeline.$date)}</Table.Cell>
        <Table.Cell>{processTime(activities.oven.$date)}</Table.Cell>
      </Table.Row>
    </Table.Body>
  </React.Fragment>
);

/**
 * render activity info for DeliveryActivityInfo type
 * @param activities delivery activityinfo
 */
const renderDeliveryActivity = (activities: DeliveryActivityInfo) => (
  <React.Fragment>
    <TableHeader alignment={'left'} headers={deliveryHeaders} />
    <Table.Body>
      <Table.Row>
        <Table.Cell>{processTime(activities.makeline.$date)}</Table.Cell>
        <Table.Cell>{processTime(activities.oven.$date)}</Table.Cell>
        <Table.Cell>{processTime(activities.routingStation.$date)}</Table.Cell>
        <Table.Cell>{processTime(activities.outTheDoor.$date)}</Table.Cell>
      </Table.Row>
    </Table.Body>
  </React.Fragment>
);

const render = (
  activities: InStoreActivityInfo | DeliveryActivityInfo | undefined,
) => {
  // don't render anything if undefined
  if (activities === undefined) {
    return null;
  }

  // do conditional rendering based on
  // what type of delivery activity it is
  switch (activities.type) {
    case 'delivery':
      return renderDeliveryActivity(activities);
    case 'in_store':
      return renderInStoreActivity(activities);
    default:
      return null;
  }
};

export const OrderTimelineModal: React.FC<Props> = (props) => (
  <Modal
    centered={true}
    dimmer={undefined}
    open={props.order !== undefined}
    onClose={props.onClose}
    size={'large'}
  >
    <Modal.Header>
      <React.Fragment>
        Order {props.order?.orderNumber ?? 'N/A'}
        <Button icon floated="right" size={'mini'} onClick={props.onClose}>
          <Icon name="delete" />
        </Button>
      </React.Fragment>
    </Modal.Header>
    <Modal.Content>
      <Table celled>{render(props.order?.activities)}</Table>
    </Modal.Content>
  </Modal>
);
