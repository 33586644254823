import { cloneDeep } from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { Container, Divider, Header, Segment } from 'semantic-ui-react'
import { getStoreNames } from '../../actions'
import { getSuperiors, getUser, getUserTypes, resetEditUser, resetGetEditUser, } from '../../actions/UserActions'
import { withFirebase } from '../../components/Firebase'
import { withAuthorization } from '../../components/Session'
import { EditUserForm } from '../../components/users/edit/edit-user-form'
import { Alert } from '../../components/utils/alerts'
import { isAdmin } from '../../utils/access-control'

class EditUserPageBase extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      status: 'EDITING',
      header: '',
      message: '',
    };
  }

  updateStatus = (s) => {
    const state = cloneDeep(this.state);
    this.setState({
      ...state,
      status: s,
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.status !== this.state.status) {
      this.handleStatusChange(prevState.status, this.state.status);
    }
  }

  componentDidMount() {
    this._isMounted = true;
    // get store lists
    // this.props.getStoreNames(this.props.firebase);
    // get user
    this.props.getUser(this.props.firebase, this.props.match.params.id);
    // get superior
    this.props.getSuperiors(this.props.firebase);
    // get userTypes
    this.props.getUserTypes(this.props.firebase);
  }

  componentWillUnmount() {
    this._isMounted = false;
    // cleanup redux states
    this.props.resetGetEditUser();
    this.props.resetEditUser();
  }

  handleStatusChange = (previous, current) => {
    switch (current) {
      case 'SUCCESS':
        this.setSuccessAlert();
        break;
      case 'FAIL':
        this.setFailedAlert();
        break;
      default:
    }
  };

  setSuccessAlert = () => {
    const state = cloneDeep(this.state);
    const committing = {
      ...state,
      header: 'Success',
      message: 'Successfully updated user.',
    };
    this.setState(committing);
  };

  setFailedAlert = () => {
    const state = cloneDeep(this.state);
    const committing = {
      ...state,
      header: 'Failure',
      message: 'Failed to update user.',
    };
    this.setState(committing);
  };

  render() {
    if (isAdmin() === true) {
      return (
        <React.Fragment>
          <Alert
            status={this.state.status}
            header={this.state.header}
            message={this.state.message}
          />
          <Segment>
            <Header size={'medium'}>Edit User</Header>
            <Divider />
            <Container fluid>
              <EditUserForm
                user={this.props.user}
                firebase={this.props.firebase}
                updateStatus={this.updateStatus}
              />
            </Container>
          </Segment>
        </React.Fragment>
      );
    }

    return <Redirect to={'/403'} />;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.edit.user,
  };
};

const mapActionsToProps = {
  getStoreNames,
  getUser,
  getUserTypes,
  getSuperiors,
  resetGetEditUser,
  resetEditUser,
};

const condition = (authUser) => authUser != null;

const EditUserPage = compose(
  withFirebase,
  withRouter,
  withAuthorization(condition),
  connect(mapStateToProps, mapActionsToProps),
)(EditUserPageBase);

export default EditUserPage;
