import React, { FunctionComponent, useState } from 'react'
import { Table } from 'semantic-ui-react'
import { StopOrderTableRowProps } from '../../../interfaces/props/dashboards/stop-order-table-props'
import useInterval from 'hooks/utils/use-interval'

const durationToString = (since: number) => {
  const difference = Date.now() - since
  return new Date(difference).toISOString().substr(11, 8)
}

export const StopOrderTableRow: FunctionComponent<StopOrderTableRowProps> = (props) => {
  const [duration, setDuration] = useState(durationToString(props.store.since))
  useInterval(1000, () => {
    setDuration(durationToString(props.store.since))
  })

  return <Table.Row>
    <Table.Cell>{`${props.store.storeId} ${props.store.storeCode} ${props.store.storeName}`}</Table.Cell>
    <Table.Cell>{props.store.category}</Table.Cell>
    <Table.Cell>{duration}</Table.Cell>
  </Table.Row>
}
