import React, { FunctionComponent } from 'react'
import { PendingOrderTableBodyProps } from '../../../interfaces/props/dashboards/pending-order-table-props'
import { PendingOrderTableRow } from './pending-order-table-row'
import { Table } from 'semantic-ui-react'
import { generateEmptyRow } from '../../table/utils'

export const PendingOrderTableBody: FunctionComponent<PendingOrderTableBodyProps> = (props) => (
  <Table.Body>
    {
      props.stores.length === 0
        ? generateEmptyRow()
        : props.stores.map((s, idx) => <PendingOrderTableRow openStoreModal={props.openStoreModal} store={s} key={idx} />)
    }
  </Table.Body>
)
