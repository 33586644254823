import React, { Component } from "react";

import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
    Header
} from "semantic-ui-react";

class SalesChannelPageBase extends Component {
    _isMounted = false;
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <Header size='large'>Sales Channel</Header>
        );
    }
}

const mapStateToProps = (props) => {
    return props;
};

const SalesChannelPage = compose(
    withRouter,
    connect(mapStateToProps, null)
)(SalesChannelPageBase);

export default SalesChannelPage;
