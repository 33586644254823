import React, { FunctionComponent, useState } from 'react'
import { Table } from 'semantic-ui-react'
import { AbsentRiderTableRowProps } from 'interfaces/props/dashboards/absent-rider-table-props'
import useInterval from 'hooks/utils/use-interval'

const durationToString = (since: number) => {
  const diff = Date.now() - since;
  return new Date(diff).toISOString().substr(11,8);
}

export const AbsentRiderTableRow: FunctionComponent<AbsentRiderTableRowProps> = (props) => {
  const { store: {since} } = props;
  const [duration, setDuration] = useState(durationToString(since));
  useInterval(1000, () => {
    setDuration(durationToString(since));
  });

  return (
    <Table.Row className={'clickable'} onClick={() => props.openStoreModal({storeId: props.store.storeId, storeName: props.store.storeName, storeCode: props.store.storeCode})}>
      <Table.Cell>{`${props.store.storeId} ${props.store.storeCode} ${props.store.storeName}`}</Table.Cell>
      <Table.Cell>{duration}</Table.Cell>
    </Table.Row>
  )
}
