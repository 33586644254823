const ResetReport = () => {
  return {
    type: 'RESET_REPORT',
  };
}

const GetReport = () => ({
  type: 'GET_REPORT',
})

const GetReportSuccess = (payload) => {
  return {
    type: 'GET_REPORT_SUCCESS',
    payload: payload,
  };
}

const GetReportFail = (error) => ({
  type: 'GET_REPORT_FAIL',
  payload: error,
})

const GetFilteredReport = () => ({
  type: 'GET_FILTERED_REPORT',
})

const GetFilteredReportSuccess = (filter, value, text, id, payload) => ({
  type: 'GET_FILTERED_REPORT_SUCCESS',
  payload: {
    filter: filter,
    value: value,
    text: text,
    id: id,
    report: payload,
  },
})

const GetFilteredReportFail = (error) => ({
  type: 'GET_FILTERED_REPORT_FAIL',
  payload: error,
})

/**
 * remove filtered report by index
 * @param payload report's index
 * @returns {{payload, type: string}}
 */
const RemoveFilteredReport = (payload) => ({
  type: 'REMOVE_FILTERED_REPORT',
  payload: payload,
});

const ResetFilteredReport = () => ({
  type: 'RESET_FILTERED_REPORT',
})

const SalesAction = {
  ResetReport: ResetReport,
  GetReport: GetReport,
  GetReportSuccess: GetReportSuccess,
  GetReportFail: GetReportFail,
  GetFilteredReport: GetFilteredReport,
  GetFilteredReportSuccess: GetFilteredReportSuccess,
  GetFilteredReportFail: GetFilteredReportFail,
  RemoveFilteredReport: RemoveFilteredReport,
  ResetFilteredReport: ResetFilteredReport,
}

export default SalesAction;