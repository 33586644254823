import axios, { AxiosResponse } from 'axios';
import {
  userSortKeyDictionary,
  userTypeDictionary,
} from 'constants/dictionaries';
import { GetUserOptionsActionResponse } from 'interfaces/dtos/users/list-user';
import { UserPaginationProps } from 'interfaces/props/users/user-table-props';
import { Dispatch } from 'redux';
import { AddUserActionRequest } from '../interfaces/dtos/users/add-user';
import { RemoveUserActionRequest } from '../interfaces/dtos/users/delete-user';
import { EditUserActionRequest } from '../interfaces/dtos/users/edit-user';
import { User as InternalUser } from '../interfaces/entities/users/edit-user';
import {
  AddUserRequest,
  DeleteUserRequest,
  EditUserRequest,
  User,
} from '../interfaces/requests/users';
import * as LocalStorage from '../utils/localstorage';
import {
  transformAddUserPayload,
  transformDeleteUserPayload,
  transformEditUserPayload,
  transformUserListingResponse,
} from '../utils/transformers/user-transformer';
import {
  ADD_USER,
  ADD_USER_FAIL,
  ADD_USER_RESET,
  ADD_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_RESET,
  DELETE_USER_SUCCESS,
  EDIT_GET_USER,
  EDIT_GET_USER_FAIL,
  EDIT_GET_USER_RESET,
  EDIT_GET_USER_SUCCESS,
  EDIT_USER,
  EDIT_USER_FAIL, EDIT_USER_REALM, EDIT_USER_REALM_FAIL, EDIT_USER_REALM_SUCCESS,
  EDIT_USER_RESET,
  EDIT_USER_SUCCESS, FILTER_USER_LIST,
  GET_SUBORDINATES,
  GET_SUBORDINATES_FAIL,
  GET_SUBORDINATES_SUCCESS,
  GET_SUPERIORS,
  GET_SUPERIORS_FAIL,
  GET_SUPERIORS_SUCCESS,
  GET_USER_INFO_FAIL,
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  GET_USER_TYPES,
  GET_USER_TYPES_SUCCESS,
  RESET_TOGGLE_USER_ACTIVATION,
  SORT_USERS,
  TOGGLE_USER_ACTIVATION,
  TOGGLE_USER_ACTIVATION_FAIL,
  TOGGLE_USER_ACTIVATION_SUCCESS,
  USER_UPDATE_PER_PAGE,
} from './types';

const filterUserTypes = (userTypes: any[], currentUserType: string) => {
  if (currentUserType === 'AD' || currentUserType === 'CSC_AD') {
    return userTypes;
  }

  return userTypes.filter((x) => x.value !== 'AD');
};

export const getUserList = (firebase: any, pagination: UserPaginationProps) => {
  return async (dispatch: Dispatch) => {
    getUserListFC(firebase, dispatch, pagination);
  };
};

export const getUserListFC = (
  firebase: any,
  dispatch: Dispatch,
  pagination: UserPaginationProps,
) => {
  firebase
    .getIdToken()
    .then((token: string) => {
      dispatch({
        type: GET_USER_LIST,
      });

      queryUserList(
        dispatch,
        token,
        pagination,
        onGetUserListSuccess,
        onGetUserListFail,
      );
    })
    .catch((error: any) => {
      onGetUserListFail(dispatch, error);
    });
};

const onGetUserListSuccess = (dispatch: Dispatch, response?: AxiosResponse) => {
  const payload =
    response === undefined
      ? undefined
      : transformUserListingResponse(response.data);
  dispatch({
    type: GET_USER_LIST_SUCCESS,
    payload: payload,
  });
};

const onGetUserListFail = (dispatch: Dispatch, error: any) => {
  dispatch({
    type: GET_USER_INFO_FAIL,
    payload: error,
  });
  console.error(error);
};

export const updateUserPerPage = (
  firebase: any,
  pagination: UserPaginationProps,
  dispatch?: Dispatch,
) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) =>
        updateUserPerPageFC(firebase, pagination, dispatch)
    : updateUserPerPageFC(firebase, pagination, dispatch);

const updateUserPerPageFC = (
  firebase: any,
  pagination: UserPaginationProps,
  dispatch: Dispatch,
) => {
  dispatch({
    type: USER_UPDATE_PER_PAGE,
    payload: {
      data: pagination,
    },
  });

  getUserListFC(firebase, dispatch, pagination);
};

export const getUserTypes = (firebase: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_USER_TYPES,
    });
    onGetUserTypesSuccess(dispatch, undefined);
  };
};

const onGetUserTypesSuccess = (dispatch: Dispatch, payload?: AxiosResponse) => {
  const userTypes = ['OM', 'DM', 'AD', 'CSC_USR', 'CSC_AD', 'OP'];
  const options = userTypes.map((val) => ({
    text: userTypeDictionary[val],
    value: val,
  }));

  const currentUserType = LocalStorage.get('currentUser').userType;
  const filtered = filterUserTypes(options, currentUserType);

  dispatch({
    type: GET_USER_TYPES_SUCCESS,
    payload: {
      data: {
        types: filtered,
      },
    },
  });
};

export const getSuperiors = (firebase: any, dispatch?: Dispatch) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) => getSuperiorsFC(firebase, dispatch)
    : getSuperiorsFC(firebase, dispatch);

export const getSuperiorsFC = (firebase: any, dispatch: Dispatch) => {
  firebase
    .getIdToken()
    .then((token: string) => {
      dispatch({
        type: GET_SUPERIORS,
      });
      queryUserOptions(
        dispatch,
        token,
        'OM',
        onGetSuperiorSuccess,
        onGetSuperiorFail,
      );
    })
    .catch((error: any) => console.error(error));
};

const onGetSuperiorSuccess = (
  dispatch: Dispatch,
  response: GetUserOptionsActionResponse,
) => {
  dispatch({
    type: GET_SUPERIORS_SUCCESS,
    payload: response,
  });
};

const onGetSuperiorFail = (dispatch: Dispatch, error?: any) => {
  dispatch({
    type: GET_SUPERIORS_FAIL,
    payload: error,
  });
};

export const addUser = (
  firebase: any,
  form: AddUserActionRequest,
  dispatch?: Dispatch,
) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) => addUserFC(firebase, form, dispatch)
    : addUserFC(firebase, form, dispatch);

const addUserFC = (
  firebase: any,
  form: AddUserActionRequest,
  dispatch: Dispatch,
) => {
  firebase
    .getIdToken()
    .then((token: string) => {
      dispatch({
        type: ADD_USER,
      });
      const body = transformAddUserPayload(form);
      putUser(dispatch, body, token, onAddUserSuccess, onAddUserFail);
    })
    .catch((error: any) => {
      console.error(error);
    });
};

const onAddUserSuccess = (dispatch: Dispatch, response?: AxiosResponse) => {
  dispatch({
    type: ADD_USER_SUCCESS,
    payload: response,
  });
};

const onAddUserFail = (dispatch: Dispatch, error: any) => {
  dispatch({
    type: ADD_USER_FAIL,
    payload: error,
  });
};

export const resetAddUser = (dispatch?: Dispatch) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) => resetAddUserFC(dispatch)
    : resetAddUserFC(dispatch);

const resetAddUserFC = (dispatch: Dispatch) => {
  dispatch({
    type: ADD_USER_RESET,
  });
};

// get dms
export const getSubordinates = (firebase: any, dispatch?: Dispatch) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) => getSubordinatesFC(firebase, dispatch)
    : getSubordinatesFC(firebase, dispatch);

const getSubordinatesFC = (firebase: any, dispatch: Dispatch) => {
  firebase
    .getIdToken()
    .then((token: string) => {
      dispatch({
        type: GET_SUBORDINATES,
      });

      queryUserOptions(
        dispatch,
        token,
        'DM',
        onGetSubordinatesSuccess,
        onGetSubordinatesFail,
      );
    })
    .catch((error: any) => {
      console.error(error);
    });
};

// api/users/all
const queryUserOptions = (
  dispatch: Dispatch,
  token: string,
  userType: 'OM' | 'DM',
  onSuccess: (
    dispatch: Dispatch,
    payload: GetUserOptionsActionResponse,
  ) => void,
  onFailed: (dispatch: Dispatch, error?: any) => void,
) => {
  axios({
    url: 'users/all',
    method: 'get',
    params: {
      userType: userType,
      sortKey: 'nickName',
      sortOrder: 1,
    },
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      const payload: GetUserOptionsActionResponse = {
        userOptions: response.data,
      };
      onSuccess(dispatch, payload);
    })
    .catch((error: any) => {
      console.error(error);
      onFailed(dispatch, error);
    });
};

const onGetSubordinatesSuccess = (
  dispatch: Dispatch,
  payload: GetUserOptionsActionResponse,
) => {
  dispatch({
    type: GET_SUBORDINATES_SUCCESS,
    payload: payload,
  });
};

const onGetSubordinatesFail = (dispatch: Dispatch, error: any) => {
  dispatch({
    type: GET_SUBORDINATES_FAIL,
    payload: error,
  });
};

export const getUser = (firebase: any, id: string, dispatch?: Dispatch) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) => getUserFC(firebase, id, dispatch)
    : getUserFC(firebase, id, dispatch);

export const getUserFC = (firebase: any, id: string, dispatch: Dispatch) => {
  firebase
    .getIdToken()
    .then((token: string) => {
      // do initial dispatch
      dispatch({
        type: EDIT_GET_USER,
      });

      getUserById(dispatch, token, id, onGetUserSuccess, onGetUserFail);
    })
    .catch((error: any) => console.error(error));
};

const transformUser = (user?: User): InternalUser | undefined => {
  if (user === undefined) {
    return undefined;
  }

  return {
    _id: user._id,
    username: user.userInfo.email,
    userType: user.userType,
    superior: user.superiorEmail ?? '',
    stores: user.storeIdList ?? [],
    name: user.userInfo.fullName,
    nickName: user.userInfo.nickName,
    realms: user.realms ?? [],
  };
};

const onGetUserSuccess = (dispatch: Dispatch, payload?: AxiosResponse) => {
  const user = transformUser(payload?.data);

  dispatch({
    type: EDIT_GET_USER_SUCCESS,
    payload: {
      user: user,
    },
  });
};

const onGetUserFail = (dispatch: Dispatch, error?: any) => {
  dispatch({
    type: EDIT_GET_USER_FAIL,
    payload: error,
  });
};

export const resetGetEditUser = (dispatch?: Dispatch) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) => resetGetEditUserFC(dispatch)
    : resetGetEditUserFC(dispatch);

const resetGetEditUserFC = (dispatch: Dispatch) => {
  dispatch({
    type: EDIT_GET_USER_RESET,
  });
};

export const resetEditUser = (dispatch?: Dispatch) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) => resetEditUserFC(dispatch)
    : resetEditUserFC(dispatch);

const resetEditUserFC = (dispatch: Dispatch) => {
  dispatch({
    type: EDIT_USER_RESET,
  });
};

export const sortUsers = (
  firebase: any,
  pagination: UserPaginationProps,
  dispatch?: Dispatch,
) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) => sortUsersFC(firebase, pagination, dispatch)
    : sortUsersFC(firebase, pagination, dispatch);

export const sortUsersFC = (
  firebase: any,
  pagination: UserPaginationProps,
  dispatch: Dispatch,
) => {
  dispatch({
    type: SORT_USERS,
    payload: {
      data: pagination,
    },
  });

  getUserListFC(firebase, dispatch, pagination);
};

/**
 * edit user action creator
 * @param firebase firebase instance
 * @param payload request payload
 * @param dispatch dispatcher
 */
export const editUser = (
  firebase: any,
  payload: EditUserActionRequest,
  dispatch?: Dispatch,
) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) => editUserFC(firebase, payload, dispatch)
    : editUserFC(firebase, payload, dispatch);

export const editUserFC = (
  firebase: any,
  payload: EditUserActionRequest,
  dispatch: Dispatch,
) => {
  firebase
    .getIdToken()
    .then((token: string) => {
      dispatch({
        type: EDIT_USER,
      });

      const request = transformEditUserPayload(payload);
      postUser(dispatch, token, request, onEditUserSuccess, onEditUserFail);
    })
    .catch((error: any) => console.error(error));
};

const onEditUserSuccess = (dispatch: Dispatch, payload?: AxiosResponse) => {
  dispatch({
    type: EDIT_USER_SUCCESS,
    payload: payload,
  });
};

const onEditUserFail = (dispatch: Dispatch, error?: any) => {
  dispatch({
    type: EDIT_USER_FAIL,
    payload: error,
  });
};

/**
 * remove user action creator
 * @param firebase firebase instance
 * @param payload request payload
 * @param dispatch dispatcher if any
 */
export const removeUser = (
  firebase: any,
  payload: RemoveUserActionRequest,
  dispatch?: Dispatch,
) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) => deleteUserFC(firebase, payload, dispatch)
    : deleteUserFC(firebase, payload, dispatch);

const deleteUserFC = (
  firebase: any,
  request: RemoveUserActionRequest,
  dispatch: Dispatch,
) => {
  dispatch({
    type: DELETE_USER,
    payload: {
      email: request.email,
    },
  });
  firebase
    .getIdToken()
    .then((token: string) => {
      const payload = transformDeleteUserPayload(request);
      deleteUser(
        dispatch,
        token,
        payload,
        onRemoveUserSuccess,
        onRemoveUserFailed,
      );
    })
    .catch((error: any) => {
      console.error(error);
    });
};

const onRemoveUserSuccess = (dispatch: Dispatch, payload: AxiosResponse) => {
  dispatch({
    type: DELETE_USER_SUCCESS,
  });
};

const onRemoveUserFailed = (dispatch: Dispatch, error: any) => {
  dispatch({
    type: DELETE_USER_FAIL,
    error: error,
  });
};

export const toggleUserActivation = (
  firebase: any,
  mode: 'activate' | 'deactivate',
  email: string,
  dispatch?: Dispatch,
) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) =>
        toggleUserActivationFC(firebase, mode, email, dispatch)
    : toggleUserActivationFC(firebase, mode, email, dispatch);

const toggleUserActivationFC = (
  firebase: any,
  mode: 'activate' | 'deactivate',
  email: string,
  dispatch: Dispatch,
) => {
  dispatch({
    type: TOGGLE_USER_ACTIVATION,
    payload: {
      email: email,
      type: mode === 'activate' ? 'activation' : 'deactivation',
    },
  });
  firebase
    .getIdToken()
    .then((token: string) => {
      const payload = {
        email: email,
      };
      switch (mode) {
        case 'activate':
          postActivateUser(
            dispatch,
            token,
            payload,
            onToggleUserSuccess,
            onToggleUserFail,
          );
          break;
        case 'deactivate':
          postDeactivateUser(
            dispatch,
            token,
            payload,
            onToggleUserSuccess,
            onToggleUserFail,
          );
          break;
        default:
          break;
      }
    })
    .catch((error: any) => onToggleUserFail(dispatch, error));
};

const onToggleUserSuccess = (dispatch: Dispatch, response: AxiosResponse) => {
  dispatch({
    type: TOGGLE_USER_ACTIVATION_SUCCESS,
  });
};

const onToggleUserFail = (dispatch: Dispatch, error: any) => {
  console.error(error);
  dispatch({
    type: TOGGLE_USER_ACTIVATION_FAIL,
    error: error,
  });
};

/**
 * reset delete user redux state
 * @param dispatch dispatcher
 */
export const resetRemoveUser = (dispatch?: Dispatch) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) => resetRemoveUserFC(dispatch)
    : resetRemoveUserFC(dispatch);

const resetRemoveUserFC = (dispatch: Dispatch) => {
  dispatch({
    type: DELETE_USER_RESET,
  });
};

/**
 * reset toggle user activation redux state
 * @param dispatch dispatcher
 */
export const resetToggleUserActivation = (dispatch?: Dispatch) =>
  dispatch === undefined
    ? async (dispatch: Dispatch) => resetToggleUserActivationFC(dispatch)
    : resetToggleUserActivationFC(dispatch);

const resetToggleUserActivationFC = (dispatch: Dispatch) => {
  dispatch({
    type: RESET_TOGGLE_USER_ACTIVATION,
  });
};

/** API Calls */

/**
 * GET user listing
 * @param dispatch
 * @param token
 * @param pagination
 * @param onSuccess
 * @param onFailed
 */
const queryUserList = (
  dispatch: Dispatch,
  token: string,
  pagination: UserPaginationProps,
  onSuccess: (dispatch: Dispatch, payload: AxiosResponse) => void,
  onFailed: (dispatch: Dispatch, error: any) => void,
) => {
  axios({
    method: 'GET',
    url: '/users',
    params: {
      page: pagination.currentPage,
      pageSize: pagination.perPage,
      sortKey: userSortKeyDictionary[pagination.sort.key],
      sortOrder: pagination.sort.ascending ? 1 : -1,
      realm: pagination.filters.realm?.length === 0 ? undefined : pagination.filters.realm,
    },
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      onSuccess(dispatch, response);
    })
    .catch((error) => onFailed(dispatch, error));
};

/**
 * GET user by _id
 * @param dispatch
 * @param token
 * @param id
 * @param onSuccess
 * @param onFailed
 */
const getUserById = (
  dispatch: Dispatch,
  token: string,
  id: string,
  onSuccess: (dispatch: Dispatch, payload?: AxiosResponse) => void,
  onFailed: (dispatch: Dispatch, error?: any) => void,
) => {
  axios({
    url: `user/${id}`,
    method: 'get',
    headers: {
      Authorization: token,
    },
  })
    .then((response) => onSuccess(dispatch, response))
    .catch((error) => onFailed(dispatch, error));
};

/**
 * PUT user : add user
 * @param dispatch
 * @param body
 * @param token
 * @param onSuccess
 * @param onFailed
 */
const putUser = (
  dispatch: Dispatch,
  body: AddUserRequest,
  token: string,
  onSuccess: (dispatch: Dispatch, payload?: AxiosResponse) => void,
  onFailed: (dispatch: Dispatch, error?: any) => void,
) => {
  axios({
    url: 'users',
    method: 'put',
    headers: {
      Authorization: token,
    },
    data: body,
  })
    .then((response) => onSuccess(dispatch, response))
    .catch((error) => onFailed(dispatch, error));
};

/**
 * POST user : update user
 * @param dispatch dispatcher
 * @param token access token
 * @param payload request payload
 * @param onSuccess onSuccess handler
 * @param onFailed onFailed handler
 */
const postUser = (
  dispatch: Dispatch,
  token: string,
  payload: EditUserRequest,
  onSuccess: (dispatch: Dispatch, payload?: AxiosResponse) => void,
  onFailed: (dispatch: Dispatch, error?: any) => void,
) => {
  axios({
    url: `user`,
    method: 'post',
    headers: {
      Authorization: token,
    },
    data: payload,
  })
    .then((response) => onSuccess(dispatch, response))
    .catch((error) => onFailed(dispatch, error));
};

/**
 * DELETE user
 * @param dispatch dispatcher
 * @param token firebase token
 * @param payload request payload
 * @param onSuccess onSuccess handler
 * @param onFailed onFailed handler
 */
const deleteUser = (
  dispatch: Dispatch,
  token: string,
  payload: DeleteUserRequest,
  onSuccess: (dispatch: Dispatch, payload: AxiosResponse) => void,
  onFailed: (dispatch: Dispatch, error: any) => void,
) => {
  axios({
    url: `user`,
    method: 'delete',
    headers: {
      Authorization: token,
    },
    data: payload,
  })
    .then((response) => onSuccess(dispatch, response))
    .catch((error) => onFailed(dispatch, error));
};

export const filterUsers = (
  firebase: any,
  pagination: UserPaginationProps,
  dispatch?: Dispatch,
) => dispatch === undefined
  ? async (dispatch: Dispatch) => filterUsersFC(firebase, pagination, dispatch)
  : filterUsersFC(firebase, pagination, dispatch);

export const filterUsersFC = (
  firebase: any,
  pagination: UserPaginationProps,
  dispatch: Dispatch,
) => {
  dispatch({
    type: FILTER_USER_LIST,
    payload: pagination,
  });

  getUserListFC(firebase, dispatch, pagination);
}

export const postActivateUser = (
  dispatch: Dispatch,
  token: string,
  payload: { email: string },
  onSuccess: (dispatch: Dispatch, payload: AxiosResponse) => void,
  onFailed: (dispatch: Dispatch, error: any) => void,
) => {
  axios({
    url: `user/activate`,
    method: 'post',
    headers: {
      Authorization: token,
    },
    data: payload,
  })
    .then((response) => onSuccess(dispatch, response))
    .catch((error) => onFailed(dispatch, error));
};

export const postDeactivateUser = (
  dispatch: Dispatch,
  token: string,
  payload: { email: string },
  onSuccess: (dispatch: Dispatch, payload: AxiosResponse) => void,
  onFailed: (dispatch: Dispatch, error: any) => void,
) => {
  axios({
    url: `user/deactivate`,
    method: 'post',
    headers: {
      Authorization: token,
    },
    data: payload,
  })
    .then((response) => onSuccess(dispatch, response))
    .catch((error) => onFailed(dispatch, error));
};

export const activateUserRealmFC = (firebase: any, dispatch: Dispatch, user: string) => {
  dispatch({ type: EDIT_USER_REALM });
  firebase.getIdToken()
    .then((token: string) => {
      postActivateUserRealm(token,
        dispatch,
        onPostActivateUserRealmSuccess,
        onPostActivateUserRealmFail,
        user);
    })
    .catch((err: any) => {
      dispatch({ type: EDIT_USER_REALM_FAIL });
      console.error(err);
    })
}

const onPostActivateUserRealmSuccess = (dispatch: Dispatch, payload: AxiosResponse<User>) => {
  dispatch({
    type: EDIT_USER_REALM_SUCCESS,
    payload: {
      realms: payload.data.realms,
    },
  });
}

const onPostActivateUserRealmFail = (dispatch: Dispatch, error: any) => {
  console.error(error);
  dispatch({ type: EDIT_USER_REALM_FAIL });
}

const postActivateUserRealm = (token: string, dispatch: Dispatch,
  onSuccess: (dispatch: Dispatch, payload: AxiosResponse) => void,
  onFailed: (dispatch: Dispatch, error: any) => void,
  nickname: string) => {
  axios.post('/user/realm/activate', undefined, {
    headers: {
      Authorization: token,
    },
    params: {
      nickname: nickname,
    }
  })
    .then((res) => {
      onSuccess(dispatch, res);
    })
    .catch((err) => {
      onFailed(dispatch, err);
    });
}

const postDeactivateUserRealm = (token: string, dispatch: Dispatch,
  onSuccess: (dispatch: Dispatch, payload: AxiosResponse) => void,
  onFailed: (dispatch: Dispatch, error: any) => void,
  nickname: string) => {
  axios.post('/user/realm/deactivate', undefined, {
    headers: {
      Authorization: token,
    },
    params: {
      nickname: nickname,
    }
  })
    .then((res) => {
      onSuccess(dispatch, res);
    })
    .catch((err) => {
      onFailed(dispatch, err);
    })
}

export const deactivateUserRealm = (firebase: any, dispatch: Dispatch, user: string) => {
  dispatch({ type: EDIT_USER_REALM });
  firebase.getIdToken()
    .then((token: string) => {
      postDeactivateUserRealm(token, dispatch,
        onPostActivateUserRealmSuccess, onPostActivateUserRealmFail,
        user);
    })
    .catch((err: any) => {
      dispatch({ type: EDIT_USER_REALM_FAIL });
      console.error(err);
    })
}
