import { userTypeDictionary } from 'constants/dictionaries';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Table } from 'semantic-ui-react';
import * as ROUTES from '../../../constants/routes';
import {
  UserTableBodyProps,
  UserTableRowProps,
} from '../../../interfaces/props/users/user-table-props';
import { isAdmin } from '../../../utils/access-control';
import { formatTime } from '../../../utils/time-formatter';
import { generateEmptyRow } from '../../table/utils';

const processTime = (millis: number) =>
  millis === 0 ? 'N/A' : formatTime(millis);

export const UserTableRow: FunctionComponent<UserTableRowProps> = (props) => {
  const history = useHistory();
  const lastLogin = processTime(props.user.lastLoginDateTime?.$date ?? 0);

  return (
    <Table.Row>
      <Table.Cell width={1}>{props.user.userInfo.nickName}</Table.Cell>
      <Table.Cell width={1}>{props.user.userInfo.email}</Table.Cell>
      <Table.Cell width={1}>
        {userTypeDictionary[props.user.userType] ?? 'N/A'}
      </Table.Cell>
      <Table.Cell width={1}>
        {props.user.lastLoginDateTime === undefined ? 'N/A' : lastLogin}
      </Table.Cell>
      <Table.Cell width={1}>
        {props.user.createdByUserId}
      </Table.Cell>
      <Table.Cell width={1}>
        {isAdmin() ? (
          <React.Fragment>
            <Button
              icon
              title={'edit'}
              onClick={() =>
                history.push(`${ROUTES.EDITUSER}/${props.user._id}`)
              }
            >
              <Icon name="write" />
            </Button>
            <Button
              negative
              icon
              title={'delete'}
              onClick={() =>
                props.triggerDeleteConfirmation(props.user.userInfo.email)
              }
            >
              <Icon name={'trash'} />
            </Button>
            <Button
              negative={props.user.active}
              positive={!props.user.active}
              icon
              title={props.user.active ? 'deactivate' : 'activate'}
              onClick={() =>
                props.triggerActivationConfirmation(
                  props.user.userInfo.email,
                  props.user.active ? 'deactivate' : 'activate',
                )
              }
            >
              <Icon name={'power'} />
            </Button>
          </React.Fragment>
        ) : null}
        {/*<Button icon title={'details'}>
          <Icon name='book'/>
        </Button>*/}
      </Table.Cell>
    </Table.Row>
  );
};

export const UserTableBody: FunctionComponent<UserTableBodyProps> = (props) => (
  <Table.Body>
    {props.users.length === 0
      ? generateEmptyRow()
      : props.users.map((u, idx) => (
          <UserTableRow
            user={u}
            key={idx}
            triggerActivationConfirmation={props.triggerActivationConfirmation}
            triggerDeleteConfirmation={props.triggerDeleteConfirmation}
          />
        ))}
  </Table.Body>
);
