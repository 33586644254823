import Firebase from 'components/Firebase'

const withFirebaseToken = <T>(firebase: Firebase, callback: (token: string) => Promise<T>): Promise<T> => {
  return firebase.getIdToken()
  .then((token) => {
    return callback(token);
  })
  .catch((err) => {
    console.error(err);
    throw err;
  })
}

export const AuthApiService = {
  withFirebaseToken: withFirebaseToken,
};