import { generateEmptyRow } from 'components/table/utils'
import React, { FunctionComponent } from 'react'
import { Table } from 'semantic-ui-react'
import { ServiceCallTableBodyProps } from '../../../interfaces/props/dashboards/service-call-table-props'
import { ServiceCallTableRow } from './service-call-table-row'

export const ServiceCallTableBody: FunctionComponent<ServiceCallTableBodyProps> = (props) => (
  <Table.Body>
    {
      props.stores.length === 0
          ? generateEmptyRow() :
      props.stores.map((s, idx) => <ServiceCallTableRow store={s} key={idx}/>)
    }
  </Table.Body>
)
