import { SemanticLoader } from 'components/semantic-loader/semantic-loader';
import { cloneDeep } from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
  Button,
  Confirm,
  Divider,
  Dropdown,
  Header,
  Segment,
} from 'semantic-ui-react';
import {
  filterUsers,
  getUserList,
  removeUser,
  resetRemoveUser,
  resetToggleUserActivation,
  sortUsers,
  toggleUserActivation,
  updateUserPerPage,
} from '../../actions/UserActions'
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import { UserTable } from '../../components/users/list/user-table';
import { UserTableBody } from '../../components/users/list/user-table-body';
import { UserTableFooter } from '../../components/users/list/user-table-footer';
import { UserTableHeader } from '../../components/users/list/user-table-header';
import { Alert } from '../../components/utils/alerts';
import * as ROUTES from '../../constants/routes';
import { PER_PAGE } from '../../constants/variable';
import { isAdmin } from '../../utils/access-control';
import { formatTime } from '../../utils/time-formatter';
import { transformPerPage } from '../../utils/transformers/transformers';

const headers = ['Nickname', 'Email', 'User Type', 'Last Login', 'Created By', ''];
const sortableHeaders = ['Nickname', 'Email', 'Full Name'];

const realmsOptions = [
  {
    key: 'DMY',
    value: 'DMY',
    text: 'Malaysia',
  },
  {
    key: 'DSG',
    value: 'DSG',
    text: 'Singapore',
  }
]

const INITIAL_STATE = {
  delete: {
    email: '',
    status: 'INITIAL',
    header: '',
    message: '',
  },
  activation: {
    email: '',
    status: 'INITIAL',
    type: undefined,
    header: '',
    message: '',
  },
};

const perPageDropdown = () => transformPerPage(PER_PAGE);

class UserPageBase extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadUserList();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.resetRemoveUser();
    this.props.resetToggleUserActivation();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.deleteStatus !== this.props.deleteStatus) {
      this.handleDeleteStatus(prevProps.deleteStatus, this.props.deleteStatus);
    }
    if (prevProps.activation.status !== this.props.activation.status) {
      this.handleToggleUserActivationStatus(
        prevProps.activation.status,
        this.props.activation.status,
      );
    }
  }

  /**
   * dropdown onchange handler
   * @param dropdown dropdown identifier
   * @param data DropdownProps
   */
  handleDropdownOnChange = (dropdown) => (e, data) => {
    switch (dropdown) {
      case 'perPage':
        const committing = {
          ...this.props.pagination,
          // NOTE: reset to 1 to avoid loading pages that does not exist
          currentPage: 1,
          perPage: data.value,
        };
        this.props.updateUserPerPage(this.props.firebase, committing);
        break;
      default:
        console.error('unrecognized dropdown identifier.');
    }
  };

  loadUserList = () => {
    this.props.getUserList(this.props.firebase, this.props.pagination);
  };

  handleDeleteStatus = (prev, current) => {
    switch (current) {
      case 'PROCESSING':
        console.log(`${prev}, ${current}`);
        this.setDeleteUserLoadingAlert();
        break;
      case 'SUCCESS':
        console.log(`${prev}, ${current}`);
        this.setDeleteUserSuccessAlert();
        this.loadUserList();
        break;
      case 'FAILED':
        console.log(`${prev}, ${current}`);
        this.setDeleteUserFailedAlert();
        break;
      default:
    }
  };

  handleToggleUserActivationStatus = (prev, current) => {
    switch (current) {
      case 'PROCESSING':
        console.log(`${prev}, ${current}`);
        this.setToggleUserActivationLoadingAlert();
        break;
      case 'SUCCESS':
        console.log(`${prev}, ${current}`);
        this.setToggleUserActivationSuccessAlert();
        this.loadUserList();
        break;
      case 'FAILED':
        console.log(`${prev}, ${current}`);
        this.setToggleUserActivationFailAlert();
        break;
      default:
    }
  };

  setDeleteUserLoadingAlert = () => {
    this.setState({
      ...this.state,
      delete: {
        ...this.state.delete,
        status: 'PROCESSING',
        header: '',
        message: `Please wait while we delete user ${this.props.deleteUser}`,
      },
    });
  };

  setDeleteUserSuccessAlert = () => {
    const committing = {
      ...this.state,
      delete: {
        ...this.state.delete,
        status: 'SUCCESS',
        header: 'Success',
        message: `Successfully deleted user ${this.props.deletedUser}.`,
      },
    };
    this.setState(committing);
  };

  setDeleteUserFailedAlert = () => {
    const state = cloneDeep(this.state);
    const committing = {
      ...state,
      delete: {
        ...state.delete,
        status: 'FAIL',
        header: 'Failure',
        message: `Failed to delete user ${this.props.deletedUser}.`,
      },
    };
    this.setState(committing);
  };

  setDeleteEmail = (target) => {
    const committing = {
      ...this.state,
      delete: {
        ...this.state.delete,
        email: target,
      },
    };

    this.setState(committing);
  };

  setToggleUserActivationLoadingAlert = () => {
    this.setState({
      ...this.state,
      activation: {
        ...this.state.activation,
        status: 'PROCESSING',
        header: '',
        message: `Please wait while we ${
          this.props.activation.type === 'activation'
            ? 'activate'
            : 'deactivate'
        } user ${this.props.activation.email}`,
      },
    });
  };

  setToggleUserActivationSuccessAlert = () => {
    this.setState({
      ...this.state,
      activation: {
        ...this.state.activation,
        status: 'SUCCESS',
        header: 'Success',
        message: `Successfully ${
          this.props.activation.type === 'activation'
            ? 'activated'
            : 'deactivated'
        } user ${this.props.activation.email}.`,
      },
    });
  };

  setToggleUserActivationFailAlert = () => {
    this.setState({
      ...this.state,
      activation: {
        ...this.state.activation,
        status: 'FAIL',
        header: 'Failure',
        message: `Failed to ${
          this.props.activation.type === 'activation'
            ? 'activate'
            : 'deactivate'
        } user ${this.props.activation.email}.`,
      },
    });
  };

  resetUserConfirmation = () => {
    this.setDeleteEmail('');
  };

  setToggleUser = (target, type) => {
    this.setState({
      activation: {
        ...this.state.activation,
        email: target,
        type: type,
      },
    });
  };

  resetActivationConfirmation = () => {
    this.setToggleUser('', undefined);
  };

  isConfirmationOpen = () => this.state.delete.email !== '';

  isActivationConfirmationOpen = () => this.state.activation.email !== '';

  deleteUser = () => {
    console.log(`delete user ${this.state.delete.email}`);
    this.resetUserConfirmation();
    const payload = {
      email: this.state.delete.email,
    };
    this.props.removeUser(this.props.firebase, payload);
  };

  toggleUser = () => {
    console.log(
      `${this.state.activation.type} user ${this.state.activation.email}`,
    );
    this.resetActivationConfirmation();
    this.props.toggleUserActivation(
      this.props.firebase,
      this.state.activation.type,
      this.state.activation.email,
    );
  };

  handleSort = (target) => (e, d) => {
    this.props.sortUsers(this.props.firebase, {
      ...this.props.pagination,
      sort: {
        ...this.props.pagination.sort,
        key: target,
        ascending:
          this.props.pagination.sort.key === target
            ? !this.props.pagination.sort.ascending
            : true,
      },
    });
  };

  handleFilter = (filter) => (e, d) => {
    switch (filter) {
      case 'realm':
        this.props.filterUsers(this.props.firebase, {
          ...this.props.pagination,
          filters: {
            ...this.props.pagination.filters,
            realm: d.value,
          },
        });
        break;
      default:
        console.error('filter unrecognized.');
        break;
    }
  }

  render() {
    return (
      <Fragment>
        <Alert
          status={this.state.activation.status}
          header={this.state.activation.header}
          message={this.state.activation.message}
        />
        <Alert
          status={this.state.delete.status}
          header={this.state.delete.header}
          message={this.state.delete.message}
        />
        <div className={'page-header'}>
          <div className={'pull-left'}>
            <Header size={'medium'}>Users</Header>
          </div>
          <div className={'pull-right grey-text'}>
            {this.props.updated === 0
              ? 'loading...'
              : `last update ${formatTime(this.props.updated)}`}
          </div>
        </div>
        <Segment>
          <div className={'index-header'}>
            <div className={'pull-left'}>
              <Dropdown
                className={'fit-content'}
                value={this.props.pagination.perPage}
                options={perPageDropdown()}
                onChange={this.handleDropdownOnChange('perPage')}
                selection
              />
            </div>
            <div className={'pull-right'}>

              {isAdmin() ? (
                <React.Fragment>
                  <Dropdown clearable options={realmsOptions} placeholder={'Filter by Country'} value={this.props.pagination.filters.realm} onChange={this.handleFilter('realm')} selection />
                  <Button
                    floated={'right'}
                    onClick={() => this.props.history.push(ROUTES.ADDUSER)}
                  >
                    Add User
                  </Button>
                </React.Fragment>
              ) : null}
            </div>
          </div>
          <Divider />
          <SemanticLoader inverted={true} active={this.props.loading}>
            <UserTable>
              <UserTableHeader
                headers={headers}
                sortableHeaders={sortableHeaders}
                handleSort={this.handleSort}
                sorted={this.props.pagination.sort.key}
                direction={this.props.pagination.sort.ascending}
              />
              <UserTableBody
                users={this.props.users}
                triggerDeleteConfirmation={this.setDeleteEmail}
                triggerActivationConfirmation={this.setToggleUser}
              />
              <UserTableFooter
                pagination={this.props.pagination}
                firebase={this.props.firebase}
              />
            </UserTable>
          </SemanticLoader>
        </Segment>
        <Confirm
          open={this.isConfirmationOpen()}
          content={`Do you want to delete ${this.state.delete.email}?`}
          onCancel={this.resetUserConfirmation}
          onConfirm={this.deleteUser}
        />
        <Confirm
          open={this.isActivationConfirmationOpen()}
          content={`Do you want to ${this.state.activation.type} ${this.state.activation.email}?`}
          onCancel={this.resetActivationConfirmation}
          onConfirm={this.toggleUser}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.user.list.users,
    pagination: state.user.list.pagination,
    loading: state.user.list.loading,
    updated: state.user.list.updated,
    deletedUser: state.user.delete.email,
    deleteStatus: state.user.delete.status,
    activation: state.user.activation,
  };
};

const mapDispatchToProps = {
  getUserList: getUserList,
  updateUserPerPage: updateUserPerPage,
  removeUser: removeUser,
  resetRemoveUser: resetRemoveUser,
  resetToggleUserActivation: resetToggleUserActivation,
  toggleUserActivation: toggleUserActivation,
  sortUsers: sortUsers,
  filterUsers: filterUsers,
};

const condition = (authUser) => authUser != null;

const UserPage = compose(
  withFirebase,
  withRouter,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
)(UserPageBase);

export default UserPage;
