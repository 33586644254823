import React, { FunctionComponent } from 'react';
import { Table } from 'semantic-ui-react';

export const InactiveStoreTable: FunctionComponent = (props) => {
  return (
    <Table unstackable celled size={'small'} selectable>
      {props.children}
    </Table>
  );
};
