import React, { FunctionComponent } from 'react'
import { Table } from 'semantic-ui-react'
import { AbsentRiderTableBodyProps } from '../../../interfaces/props/dashboards/absent-rider-table-props'
import { AbsentRiderTableRow } from './absent-rider-table-row'
import { generateEmptyRow } from '../../table/utils'

export const AbsentRiderTableBody: FunctionComponent<AbsentRiderTableBodyProps> = (props) => (
  <Table.Body>
    {
      props.stores.length === 0
        ? generateEmptyRow()
        : props.stores.map((s, idx) => <AbsentRiderTableRow openStoreModal={props.openStoreModal} key={idx} store={s}/>)
    }
  </Table.Body>
);
