import { getStoreNames, getUnassgined } from 'actions/StoreActions';
import { EditUserState } from 'interfaces/states/local/user-state';
import { cloneDeep } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createSelector } from 'reselect';
import {
  Button,
  Dimmer,
  Form,
  InputOnChangeData,
  Loader,
} from 'semantic-ui-react';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { editUser } from '../../../actions/UserActions';
import { EditUserActionRequest } from '../../../interfaces/dtos/users/edit-user';
import { DropdownOption } from '../../../interfaces/entities/forms/dropdown-options';
import { FormError } from '../../../interfaces/entities/forms/error';
import { EditUserProps } from '../../../interfaces/props/users/edit-user-props';
import {
  transformMembers,
  transformStoreNames,
  transformUserTypes,
} from '../../../utils/transformers/user-transformer';
import { isAdmin } from '../../../utils/access-control';

const realmsOptions = [
  {
    key: 'DMY',
    value: 'DMY',
    text: 'Malaysia',
  },
  {
    key: 'DSG',
    value: 'DSG',
    text: 'Singapore',
  }
]

/**
 * takes a string and generate an
 * error object
 * @param text error message
 * @returns {FormError} error object
 */
const generateErrorObject = (text: string): FormError => {
  return {
    content: text,
    pointing: 'above',
  };
};

const selectUserTypes = createSelector(
  (state: any) => state.user.shared.userTypes,
  (userTypes) => transformUserTypes(userTypes ?? []),
);

const superiorSelector = createSelector(
  (state: any) => state.user.shared.superiors,
  (superiors) => transformMembers(superiors ?? []),
);

const storesSelector = createSelector(
  (state: any) => state.store.shared.stores,
  (stores) => transformStoreNames(stores ?? []),
);

const userStatusSelector = createSelector(
  (state: any) => state.user.edit.status.user,
  (userStatus) => userStatus,
);

const editStatusSelector = createSelector(
  (state: any) => state.user.edit.status.edit,
  (editStatus) => editStatus,
);

const INITIAL_STATE: EditUserState = {
  form: {
    username: '',
    userType: '',
    superior: '',
    prevStores: [],
    stores: [],
    realms: [],
    name: '',
    nickName: '',
  },
  error: {
    nameError: undefined,
    nicknameError: undefined,
    superiorError: undefined,
    userTypeError: undefined,
  },
};

const EDIT_USER_ERROR_MESSAGE = {
  NICKNAME: 'Nickname is required.',
  NICKNAME_LENGTH: 'Nickname should not be more than 15 characters.',
  SUPERIOR: 'Superior is required.',
  USER_TYPE: 'User type is required.',
  NAME: 'Full name is required.',
};

export const EditUserForm: FunctionComponent<EditUserProps> = (props) => {
  const userTypeOptions: DropdownOption[] = useSelector(selectUserTypes);
  const storeOptions: DropdownOption[] = useSelector(storesSelector);
  const superiorOptions: DropdownOption[] = useSelector(superiorSelector);
  const userStatus: string = useSelector(userStatusSelector);
  const editStatus: string = useSelector(editStatusSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = useState(cloneDeep(INITIAL_STATE));
  const { firebase, user, updateStatus } = props;

  const hasError =
    state.error.nameError !== undefined ||
    state.error.nicknameError !== undefined ||
    state.error.superiorError !== undefined ||
    state.error.userTypeError !== undefined;

  const isDM = state.form.userType === 'DM';
  const isAD = state.form.userType === 'AD';
  const isCSCAD = state.form.userType === 'CSC_AD';
  const isCSCUSR = state.form.userType === 'CSC_USR';
  const isOP = state.form.userType === 'OP';


  /**
   * listens to userStatus and only
   * set local user form with values when it's loaded
   */
  useEffect(() => {
    if (userStatus !== 'SUCCESS') {
      return;
    }

    const current = cloneDeep(user);

    let committing = cloneDeep(INITIAL_STATE);
    committing.form.username = current.username ?? '';
    committing.form.userType =
      current.userType !== 'DM' &&
      current.userType !== 'OM' &&
      current.userType !== 'CSC_USR' &&
      current.userType !== 'CSC_AD' &&
      current.userType !== 'OP' &&
      current.userType !== 'AD'
        ? ''
        : current.userType;
    committing.form.superior = current.superior ?? '';
    committing.form.stores = current.stores;
    committing.form.prevStores = current.stores;
    committing.form.name = current.name ?? '';
    committing.form.nickName = current.nickName ?? '';
    committing.form.realms = current.realms ?? [];
    setState(committing);
    console.log(committing);

    if (current.userType === 'DM' || current.userType === 'OM') {
      getUnassgined(
        firebase,
        current.userType.toLowerCase() as 'om' | 'dm',
        current.nickName,
        dispatch,
      );
    } else {
      getStoreNames(firebase, { dm: false, om: false }, dispatch);
    }
  }, [userStatus, user, dispatch, firebase]);

  /**
   * listens to editStatus and update status of
   * parent prop when there is a change to set
   * alert accordingly
   */
  useEffect(() => {
    switch (editStatus) {
      case 'SUCCESS':
        updateStatus('SUCCESS');
        break;
      case 'FAILED':
        updateStatus('FAIL');
        break;
      case 'INITIAL':
        updateStatus('EDITING');
        break;
      default:
    }
  }, [editStatus, updateStatus]);

  const setEditing = () => {
    props.updateStatus('EDITING');
  };

  const handleSelectUserType = (_event: any, data: DropdownProps) => {
    setEditing();

    const isAD = data.value === 'AD';
    const isCSCAD = data.value === 'CSC_AD';
    const isCSCUSR = data.value === 'CSC_USR';
    const isOP = data.value === 'OP';
    if (data.value === 'DM' || data.value === 'OM') {
      getUnassgined(
        firebase,
        `${data.value}`.toLowerCase() as 'om' | 'dm',
        state.form.username,
        dispatch,
      );
    } else {
      getStoreNames(firebase, { dm: false, om: false }, dispatch);
    }

    const isChangedFromAD =
      state.form.userType === 'AD' || state.form.userType === 'OP' || state.form.userType === 'CSC_USR' || state.form.userType === 'CSC_AD' &&
      (data.value === 'DM' || data.value === 'OM');
    if (isChangedFromAD) {
      setState({
        ...state,
        form: {
          ...state.form,
          stores: state.form.prevStores,
          userType: data.value,
          superior: '',
        },
      });
      return;
    }

    if (isAD || isCSCAD || isCSCUSR || isOP) {
      setState({
        ...state,
        form: {
          ...state.form,
          prevStores: state.form.stores,
          stores: [],
          userType: data.value,
          superior: '',
        },
      });

      return;
    }

    setState({
      ...state,
      form: {
        ...state.form,
        userType: data.value,
        superior: '',
      },
    });
  };

  const handleSelectSuperior = (_event: any, data: DropdownProps) => {
    setEditing();
    setState({
      ...state,
      form: {
        ...state.form,
        superior: data.value,
      },
      error: {
        ...state.error,
        superiorError:
          data.value === '' && state.form.userType === 'DM'
            ? generateErrorObject(EDIT_USER_ERROR_MESSAGE.SUPERIOR)
            : undefined,
      },
    });
  };

  const handleSelectStores = (_event: any, data: DropdownProps) => {
    setEditing();
    setState({
      ...state,
      form: {
        ...state.form,
        stores: data.value,
      },
    });
  };

  const validateForm = () => {
    const isUserTypeValid = (state.form.userType as string).length !== 0;
    const isSuperiorEmailValid = !(
      state.form.userType === 'DM' && state.form.superior === ''
    );
    const isNameValid = state.form.name.length > 0;
    const nickNameError = validateNickname(state.form.nickName);
    const isNicknameValid = nickNameError === undefined;

    setState({
      ...state,
      error: {
        ...state.error,
        userTypeError: isUserTypeValid
          ? undefined
          : generateErrorObject(EDIT_USER_ERROR_MESSAGE.USER_TYPE),
        superiorError: isSuperiorEmailValid
          ? undefined
          : generateErrorObject(EDIT_USER_ERROR_MESSAGE.SUPERIOR),
        nameError: isNameValid
          ? undefined
          : generateErrorObject(EDIT_USER_ERROR_MESSAGE.NAME),
        nicknameError: nickNameError,
      },
    });

    return (
      isUserTypeValid && isSuperiorEmailValid && isNameValid && isNicknameValid
    );
  };

  const handleSubmit = () => {
    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    const payload: EditUserActionRequest = {
      username: state.form.username,
      name: state.form.name,
      nickname: state.form.nickName,
      userType: state.form.userType as string,
      stores: state.form.stores as string[],
      superior: state.form.superior as string,
      realms: state.form.realms as string[],
    };

    editUser(props.firebase, payload, dispatch);
  };

  const handleNameUpdate = (_e: any, d: InputOnChangeData) => {
    setEditing();
    const error = validateNameUpdate(d.value);
    setState({
      ...state,
      form: {
        ...state.form,
        name: d.value,
      },
      error: {
        ...state.error,
        nameError: error,
      },
    });
  };

  const validateNameUpdate = (n: string) => {
    let error: FormError | undefined = undefined;
    if (n.length === 0) {
      error = generateErrorObject(EDIT_USER_ERROR_MESSAGE.NAME);
    }
    return error;
  };

  const handleNicknameUpdate = (_e: any, d: InputOnChangeData) => {
    setEditing();
    const error = validateNickname(d.value);
    setState({
      ...state,
      form: {
        ...state.form,
        nickName: d.value,
      },
      error: {
        ...state.error,
        nicknameError: error,
      },
    });
  };

  const validateNickname = (s: string) => {
    let error: FormError | undefined = undefined;
    if (s.length === 0) {
      error = generateErrorObject(EDIT_USER_ERROR_MESSAGE.NICKNAME);
      return error;
    }

    if (s.length >= 15) {
      error = generateErrorObject(EDIT_USER_ERROR_MESSAGE.NICKNAME_LENGTH);
      return error;
    }

    return error;
  };

  const handleDropdownUpdate = (field: string) => (e: any, d: DropdownProps) => {
    switch (field) {
      case 'realms':
        setState({
          ...state,
          form: {
            ...state.form,
            realms: d.value,
          },
        });
        break;
      default:
        console.error('unrecognized field');
        break;
    }
  }


  return (
    <React.Fragment>
      <Dimmer
        inverted
        active={
          userStatus === 'INITIAL' ||
          userStatus === 'PROCESSING' ||
          userStatus === 'FAILED'
        }
      >
        <Loader />
      </Dimmer>
      <Form onSubmit={handleSubmit}>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <label>Full Name</label>
            <Form.Input
              type={'text'}
              value={state.form.name}
              error={state.error.nameError}
              onChange={handleNameUpdate}
              placeholder={'John Smith'}
            />
          </Form.Field>
          <Form.Field>
            <label>Nickname</label>
            <Form.Input
              type={'text'}
              value={state.form.nickName}
              error={state.error.nicknameError}
              onChange={handleNicknameUpdate}
              placeholder={'Johnny'}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <label>Email</label>
          <Form.Input type={'email'} disabled value={state.form.username} />
        </Form.Field>
        {
          isAdmin() ? (
            <Form.Field>
              <Form.Select
                placeholder={'Select Countries'}
                selection
                multiple
                value={state.form.realms}
                label={'Countries'}
                options={realmsOptions}
                onChange={handleDropdownUpdate('realms')}
              />
            </Form.Field>
          ) : null
        }
        <Form.Field>
          <Form.Select
            placeholder={'Select User Type'}
            selection
            value={state.form.userType}
            error={state.error.userTypeError}
            label={'User Type'}
            options={userTypeOptions}
            onChange={handleSelectUserType}
          />
        </Form.Field>
        {isDM ? (
          <Form.Field>
            <Form.Select
              label={'Superior Email'}
              search
              fluid
              selection
              placeholder={'Select Superior Email'}
              value={state.form.superior}
              error={state.error.superiorError}
              onChange={handleSelectSuperior}
              options={superiorOptions.filter(
                (x) => x.value !== state.form.username,
              )}
            />
          </Form.Field>
        ) : undefined}
        <Form.Field>
          <Form.Select
            placeholder={'Select Assigned Stores'}
            disabled={isAD || isCSCAD || isCSCUSR || isOP}
            search
            fluid
            selection
            multiple
            value={state.form.stores}
            onChange={handleSelectStores}
            label={'Assigned Stores'}
            options={storeOptions}
          />
        </Form.Field>
        <Button type={'button'} negative onClick={history.goBack}>
          Cancel
        </Button>
        <Button
          disabled={hasError || editStatus === 'PROCESSING'}
          type={'submit'}
        >
          Save Changes
        </Button>
      </Form>
    </React.Fragment>
  );
};
