import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
}

class Firebase {
    constructor() {
      app.initializeApp(config);

      this.messaging = null;

      this.auth = app.auth();
      if(app.messaging.isSupported()) {
        this.messaging = app.messaging();
        // does not exist in new config dominos vital sign
        // this.messaging.usePublicVapidKey(process.env.REACT_APP_WEB_PUSH_CERT);
      }
    }

    // *** Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
      this.auth.currentUser.updatePassword(password);

    getIdToken = () => this.auth.currentUser.getIdToken();

    getUserId = () => this.auth.currentUser.uid;

    isMessagingSupport = () => app.messaging.isSupported();

    doSetPersistence = (session) => this.auth.setPersistence(session);

    FCMrequestPermission = () => this.messaging.requestPermission();

    FCMGetIdToken = () => this.messaging.getToken();
  }
  
  export default Firebase;
