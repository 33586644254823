import { GetUserListActionResponse } from 'interfaces/dtos/users/list-user';
import { UserOption } from 'interfaces/entities/users/list-user';
import { simpleStoreComparer } from 'utils/sorting';
import { AddUserActionRequest } from '../../interfaces/dtos/users/add-user';
import { RemoveUserActionRequest } from '../../interfaces/dtos/users/delete-user';
import { EditUserActionRequest } from '../../interfaces/dtos/users/edit-user';
import { DropdownOption } from '../../interfaces/entities/forms/dropdown-options';
import { SimpleStore } from '../../interfaces/requests/stores';
import {
  AddUserRequest,
  DeleteUserRequest,
  EditUserRequest,
  GetUserListingResponse,
} from '../../interfaces/requests/users';

/* dropdowns */

/**
 * transform constant user type list
 * to dropdown options for add & edit user
 * @param userTypes user type constants list
 */
export const transformUserTypes = (userTypes: any[]): DropdownOption[] => {
  return userTypes.map((ut) => {
    return {
      key: ut.value,
      value: ut.value,
      text: ut.text,
    };
  });
};

/**
 * transforms constant list to dropdown for
 * store summary filter
 * @param states malaysia states constants list
 */
export const transformStates = (states: string[]): DropdownOption[] => {
  return states.map((s) => {
    return {
      key: s,
      value: s,
      text: s,
    };
  });
};

/**
 * used for transforming user options to
 * email dropdown in edit & add user
 * @param users user options from redux state
 */
export const transformMembers = (users: UserOption[]): DropdownOption[] => {
  return users.map((u) => ({
    key: u.userId,
    value: u.email,
    text: u.email,
  }));
};

/**
 * used for transforming user options to
 * nickname dropdown in store summary
 * @param users user options from redux state
 */
export const transformUserOptions = (users: UserOption[]): DropdownOption[] => {
  return users.map((u) => ({
    key: u.userId,
    value: u.nickName,
    text: u.nickName,
  }));
};

/**
 * transform simplified store objects
 * to dropdown options for edit & add user
 * @param stores simplified store objects
 */
export const transformStoreNames = (
  stores: SimpleStore[],
): DropdownOption[] => {
  stores.sort(simpleStoreComparer);

  return stores.map((s) => {
    return {
      key: s.storeId,
      value: s.storeId,
      text: `${s.storeId} ${s.storeCode} ${s.storeName}`,
    };
  });
};

/* api requests inbound */
export const transformUserListingResponse = (
  from: GetUserListingResponse,
): GetUserListActionResponse => {
  return {
    pagination: {
      currentPage: from.currentPage ?? 1,
      totalPage: from.totalPage ?? 0,
      perPage: 0,
      totalEntities: from.totalRecord ?? 0,
    },
    users: from.listing.map((u) => ({
      _id: u._id,
      userId: u.userId,
      userType: u.userType,
      superiorEmail: u.superiorEmail,
      active: u.active ?? true,
      createdByUserId: u.createdByUserId ?? '',
      lastLoginDateTime: u.lastLoginDateTime ?? 0,
      userInfo: {
        email: u.userInfo.email,
        fullName: u.userInfo.fullName,
        nickName: u.userInfo.nickName,
      },
    })),
    updated: Date.now(),
  };
};

/* api requests outbound */

/**
 * map add user payload from ui to the
 * correct structure for api
 * @param form add user request from ui
 */
export const transformAddUserPayload = (
  form: AddUserActionRequest,
): AddUserRequest => {
  return {
    user: {
      userId: '',
      userType: form.userType,
      storeIdList: form.stores,
      groupIdList: [],
      userInfo: {
        fullName: form.fullName,
        nickName: form.nickName,
        email: form.username,
      },
      superiorEmail: form.superior,
    },
    password: 'string', // hardcode
  };
};

/**
 * map edit user payload from ui to the
 * correct structure for api
 * @param from edit user request from ui
 */
export const transformEditUserPayload = (
  from: EditUserActionRequest,
): EditUserRequest => {
  return {
    userEmail: from.username,
    userType: from.userType,
    storeIdList: from.stores,
    superiorEmail: from.superior,
    realms: from.realms,
    fullName: from.name,
    nickname: from.nickname,
  };
};

/**
 * map delete user payload from ui to the
 * correct structure for api
 * @param from delete user request from ui
 */
export const transformDeleteUserPayload = (
  from: RemoveUserActionRequest,
): DeleteUserRequest => {
  return {
    email: from.email,
  };
};
