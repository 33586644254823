import React, { Component } from 'react'
import axios from 'axios'
import { compose } from 'recompose'
import { Redirect, Route, withRouter } from 'react-router-dom'
import * as ROUTES from './constants/routes'
import { API_KEY, BASE_URL } from './config'
import { withAuthentication } from './components/Session'
import { withFirebase } from './components/Firebase'
import SignInPage from './pages/SignIn'
import PasswordForgetPage from './pages/PasswordForget'
import * as LocalStorage from './utils/localstorage'

import Loading from './components/Loading'

import Pages from './pages'
import NotFoundPage from './pages/NotFound'
import RestrictedPage from './pages/restricted'

axios.defaults.baseURL = BASE_URL;
axios.defaults.params = {};
axios.defaults.params['api_key'] = API_KEY;
axios.defaults.paramsSerializer = (params) => {
  let result = '';
  Object.keys(params).forEach(key => {
    if (params[key] !== undefined) {
      result += `${key}=${encodeURIComponent(params[key])}&`;
    }
  });
  return result.substr(0, result.length - 1);
}

class AppBase extends Component {
  authenticate() {
    // return new Promise(resolve => setTimeout(resolve, 500))
    return new Promise(resolve => LocalStorage.get('authUser') ? this.init(resolve) : resolve(this.props.history.push(ROUTES.SIGN_IN)) )
  }

  init(resolve) {
    setTimeout(resolve, 500);
    // this.props.history.push('/dashboard/users');
  }

  componentDidMount() {
    this.authenticate()
    .then(() => {
      const ele = document.getElementById('ipl-progress-indicator')
      if(ele){
         // fade out
        ele.classList.add('available')
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = ''
        }, 500)
      }
    })
  }

  render() {
    return (
      <React.Fragment>
        <Loading />
        <Route exact path={'/'}>
          <Redirect to={ROUTES.DASHBOARD}/>
        </Route>
        <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage}/>
        <Route exact path={ROUTES.RESTRICTED} component={RestrictedPage}/>
        <Route path={ROUTES.NOTFOUND} component={NotFoundPage}/>
        <Route path={ROUTES.PAGES} component={Pages} />
      </React.Fragment>
    );
  }
}

const App = compose(
  withFirebase,
  withRouter,
  withAuthentication)(AppBase);

export default App;
