import moment from 'moment'

type DATETIME_FORMAT = 'FULL' | 'TIME_ONLY' | 'DATE_ONLY' | 'DATE_ONLY_VAR1';

/**
 * formats time to string in YYYY-MM-DD HH:mm:ss format
 * @param millis milliseconds since epoch
 * @param format time format
 */
export const formatTime = (millis: number, format: DATETIME_FORMAT = 'FULL') => {
  let timeFormat: string;
  switch (format) {
    case 'TIME_ONLY':
      timeFormat = 'HH:mm:ss'
      break;
    case 'DATE_ONLY':
      timeFormat = 'YYYY-MM-DD';
      break;
    case 'DATE_ONLY_VAR1':
      timeFormat = 'YYYYMMDD';
      break;
    case 'FULL':
    default:
      timeFormat = 'YYYY-MM-DD HH:mm:ss'
  }
  return moment(new Date(millis)).format(timeFormat);
}

export const formatDuration = (millis: number) => {
  return new Date(millis).toISOString().substr(11,8)
}

export const formatNumber = (num: number) => {
  console.log(num)
  if(isNaN(num))
    return 0.0
  else
    return num
}
