import React from 'react'
import { FunctionComponent } from 'react'
import { Table } from 'semantic-ui-react'
import { StopOrderTableBodyProps } from '../../../interfaces/props/dashboards/stop-order-table-props'
import { StopOrderTableRow } from './stop-order-table-row'
import {generateEmptyRow} from "../../table/utils";

export const StopOrderTableBody: FunctionComponent<StopOrderTableBodyProps> = (props) => (
  <Table.Body>
    { props.stores.length === 0
        ? generateEmptyRow() : props.stores.map((s, idx) => <StopOrderTableRow store={s} key={idx}/>) }
  </Table.Body>
)
