import React from 'react'
import { Icon } from 'semantic-ui-react'

export const UnknownTypePanel = () => {
  return <React.Fragment>
    <div style={{ textAlign: 'center' }}>
      <Icon name={'frown outline'} size={'massive'} />
      <h2>Unable to Determine User Type</h2>
      <p>Please contact support for more information.</p>
    </div>
  </React.Fragment>
}