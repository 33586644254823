import React, { Component } from "react";

import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
    Header
} from "semantic-ui-react";

class PosSettingPageBase extends Component {
    _isMounted = false;
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <Header size='large'>Pos Settings</Header>
        );
    }
}

const mapStateToProps = (props) => {
    return props;
};

const PosSettingPage = compose(
    withRouter,
    connect(mapStateToProps, null)
)(PosSettingPageBase);

export default PosSettingPage;
