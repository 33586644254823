export const SUCC_CODE = '000';

export const ERROR_CODE = {
  ERROR_CARRY_OUT_ORDER_NOT_FOUND: '460',
  ERROR_CARRY_OUT_ORDER_EXIST: '461',
  ERROR_CLIENT_ALREADY_CLICKED: '462',
  ERROR_STORE_DO_NOT_HAVE_CODE: '463',
  ERROR_INVALID_STORE_CODE: '464',
  ERROR_STORE_CODE_MISMATCHED: '465',
  ERROR_COUPON_CLAIMED: '468',
  ERROR_REQUEST_COUPON_FAILED: '469',
  ERROR_ARRIVE_NOT_ALLOWED: '471',
};

export const METHOD_TYPE = {
  CARRY: 'CARRY_OUT',
  DRIVE: 'DRIVE_UP',
};

export const STATE = {
  ARRIVED: 'CLIENT_ARRIVED',
  ORDER_PLACE: 'OP',
  PLACE_ORDER: 'PC',
  IN_OVEN: 'IO',
  COMPLETE: 'CP',
};

export const POLLING_RATE = {
  THIRTY_SECONDS: 30000,
  TWENTY_SECONDS: 20000,
};

export const STATES = [
  'Johor',
  'Kedah',
  'Kelantan',
  'Melaka',
  'Negeri Sembilan',
  'Pahang',
  'Penang',
  'Perak',
  'Perlis',
  'Sabah',
  'Sarawak',
  'Selangor',
  'Terengganu',
  'Kuala Lumpur',
];

export const PER_PAGE = [20, 40, 60, 80, 100];

export const COLOR = {
  RED: '#9F3A38',
};

// localstorage name for session expiration
export const EXPIRATION = 'expiration';
// duration for session expiration
export const EXPIRATION_DURATION = 3600000;

// ideal item count
export const IDEAL_ITEM_COUNT = 12;
