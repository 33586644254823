import { toggleSidebar } from 'actions/MainAction';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Icon, Image, Menu } from 'semantic-ui-react';
import { logoutUser, resetStates } from '../../actions';
import { withAuthentication } from '../Session';
import Visibility from "../utils/visibility";

class TopNavBase extends Component {

  onToggleSidebar = (e, d) => {
    const payload = this.props.sidebarVisible ? false : true;
    this.props.toggleSidebar(payload);
  };

  render() {
    const logout = (e) => {
      this.props.logoutUser(this.props);
    };

    return (
      <Menu fixed="top" inverted className="top-menu">
        <Menu.Item
          onClick={this.onToggleSidebar}
          icon
          content={<Icon name={'align justify'} />}
        />
        <Menu.Item as="a" header>
          <Image
            size="mini"
            src="/dpz-logo.svg"
            style={{ marginRight: '1.5em' }}
          />
          Vital Sign App
        </Menu.Item>
        {/* <Menu.Item as='a'>Home</Menu.Item>
        <Dropdown item simple text='Dropdown' position='right'>
          <Dropdown.Menu>
            <Dropdown.Item>List Item</Dropdown.Item>
            <Dropdown.Item>List Item</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>Header Item</Dropdown.Header>
            <Dropdown.Item>
              <i className='dropdown icon' />
              <span className='text'>Submenu</span>
              <Dropdown.Menu>
                <Dropdown.Item>List Item</Dropdown.Item>
                <Dropdown.Item>List Item</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown.Item>
            <Dropdown.Item>List Item</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

        <Menu.Menu position="right">
            <Visibility condition={this.props.currentUserInfo != null}>
                <Menu.Item name={"Hi, " + this.props.currentUserInfo?.userInfo?.nickName} />
            </Visibility>

          <Menu.Item name="logout" onClick={logout} />
        </Menu.Menu>
      </Menu>
    );
  }
}



const mapStateToProps = (props) => {
    return {
        sidebarVisible: props.main.sidebarVisible,
        currentUserInfo: props.auth.currentUserInfo
    };
};

const TopNavComponent = compose(
  withRouter,
  withAuthentication,
  connect(mapStateToProps, {
    logoutUser,
    resetStates,
    toggleSidebar,
  }),
)(TopNavBase);

export default TopNavComponent;
