import {
  CLOSE_STORE_MODAL,
  FILTER_STORES,
  GET_SIMPLE_STORE_NAMES_FAIL,
  GET_SIMPLE_STORE_NAMES_SUCCESS,
  GET_STORE_DETAILS_ACTIVE_DELIVERIES,
  GET_STORE_DETAILS_ACTIVE_DELIVERIES_FAIL,
  GET_STORE_DETAILS_ACTIVE_DELIVERIES_SUCCESS,
  GET_STORE_DETAILS_ACTIVE_INSTORE,
  GET_STORE_DETAILS_ACTIVE_INSTORE_FAIL,
  GET_STORE_DETAILS_ACTIVE_INSTORE_SUCCESS,
  GET_STORE_DETAILS_EMPLOYEE,
  GET_STORE_DETAILS_EMPLOYEE_FAIL,
  GET_STORE_DETAILS_EMPLOYEE_SUCCESS,
  GET_STORE_INFO_LIST,
  GET_STORE_INFO_LIST_FAIL,
  GET_STORE_INFO_LIST_SUCCESS,
  OPEN_STORE_MODAL,
  RESET_STORE,
  SORT_STORES,
  STORE_UPDATE_PER_PAGE,
} from '../actions/types';
import { StoreActionTypes } from '../interfaces/actions/store-action-interface';

const PAGINATION_INITIAL_STATE = {
  currentPage: 1,
  totalPage: 1,
  perPage: 20,
  totalEntities: 0,
  filters: {
    state: '',
    om: '',
    dm: '',
  },
  sort: {
    key: 'store',
    ascending: true,
  },
};

const MODAL_INITIAL_STATE = {
  idx: -1,
  storeDetails: {
    employees: {
      manager: 0,
      insider: 0,
      rider: 0,
      updated: 0,
    },
    activeDeliveries: [],
    activeInStore: [],
    status: {
      employeeLoading: true,
      activeDeliveriesLoading: true,
      activeInStoreLoading: true,
    },
  },
};

const SHARED_INITIAL_STATE = {
  stores: [],
};

const LIST_STORE_INITIAL_STATE = {
  stores: [],
  pagination: PAGINATION_INITIAL_STATE,
  modal: MODAL_INITIAL_STATE,
  error: '',
  loading: true,
  updated: 0,
};

const INITIAL_STATE = {
  list: LIST_STORE_INITIAL_STATE,
  shared: SHARED_INITIAL_STATE,
};

export default (state = INITIAL_STATE, action: StoreActionTypes) => {
  switch (action.type) {
    case SORT_STORES:
    case FILTER_STORES:
      return {
        ...state,
        list: {
          ...state.list,
          pagination: action.payload.data,
        },
      };
    case GET_STORE_INFO_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case GET_STORE_INFO_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          stores: action.payload.stores ?? [],
          pagination: {
            ...state.list.pagination,
            totalEntities:
              action.payload.pagination.totalEntities ??
              state.list.pagination.totalEntities,
            currentPage:
              action.payload.pagination.currentPage ??
              state.list.pagination.currentPage,
            totalPage:
              action.payload.pagination.totalPage ??
              state.list.pagination.totalPage,
          },
          updated: action.payload.updated,
        },
      };
    case GET_STORE_INFO_LIST_FAIL:
      return state;
    case OPEN_STORE_MODAL:
      return {
        ...state,
        list: {
          ...state.list,
          modal: {
            ...state.list.modal,
            idx: action.payload.idx,
          },
        },
      };
    case CLOSE_STORE_MODAL:
      return {
        ...state,
        list: {
          ...state.list,
          modal: MODAL_INITIAL_STATE,
        },
      };
    case GET_STORE_DETAILS_EMPLOYEE:
      return {
        ...state,
        list: {
          ...state.list,
          modal: {
            ...state.list.modal,
            storeDetails: {
              ...state.list.modal.storeDetails,
              status: {
                ...state.list.modal.storeDetails.status,
                employeeLoading: true,
              },
            },
          },
        },
      };
    case GET_STORE_DETAILS_EMPLOYEE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          modal: {
            ...state.list.modal,
            storeDetails: {
              ...state.list.modal.storeDetails,
              employees: {
                manager: action.payload.manager,
                insider: action.payload.insider,
                rider: action.payload.rider,
                updated: action.payload.lastUpdate,
              },
              status: {
                ...state.list.modal.storeDetails.status,
                employeeLoading: false,
              },
            },
          },
        },
      };
    case GET_STORE_DETAILS_EMPLOYEE_FAIL:
      return state;
    case GET_STORE_DETAILS_ACTIVE_DELIVERIES:
      return {
        ...state,
        list: {
          ...state.list,
          modal: {
            ...state.list.modal,
            storeDetails: {
              ...state.list.modal.storeDetails,
              status: {
                ...state.list.modal.storeDetails.status,
                activeDeliveriesLoading: true,
              },
            },
          },
        },
      };
    case GET_STORE_DETAILS_ACTIVE_DELIVERIES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          modal: {
            ...state.list.modal,
            storeDetails: {
              ...state.list.modal.storeDetails,
              activeDeliveries:
                action.payload.orders ??
                state.list.modal.storeDetails.activeDeliveries,
              status: {
                ...state.list.modal.storeDetails.status,
                activeDeliveriesLoading: false,
              },
            },
          },
        },
      };
    case GET_STORE_DETAILS_ACTIVE_DELIVERIES_FAIL:
      return state;
    case GET_STORE_DETAILS_ACTIVE_INSTORE:
      return {
        ...state,
        list: {
          ...state.list,
          modal: {
            ...state.list.modal,
            storeDetails: {
              ...state.list.modal.storeDetails,
              status: {
                ...state.list.modal.storeDetails.status,
                activeInStoreLoading: true,
              },
            },
          },
        },
      };
    case GET_STORE_DETAILS_ACTIVE_INSTORE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          modal: {
            ...state.list.modal,
            storeDetails: {
              ...state.list.modal.storeDetails,
              activeInStore:
                action.payload.orders ??
                state.list.modal.storeDetails.activeInStore,
              status: {
                ...state.list.modal.storeDetails.status,
                activeInStoreLoading: false,
              },
            },
          },
        },
      };
    case GET_STORE_DETAILS_ACTIVE_INSTORE_FAIL:
      return state;
    case GET_SIMPLE_STORE_NAMES_SUCCESS:
      return {
        ...state,
        shared: {
          ...state.shared,
          stores: action.payload.stores,
        },
      };
    case GET_SIMPLE_STORE_NAMES_FAIL:
      return state;
    case RESET_STORE:
      return INITIAL_STATE;
    case STORE_UPDATE_PER_PAGE:
      return {
        ...state,
        list: {
          ...state.list,
          pagination: {
            ...state.list.pagination,
            perPage: action.payload.data.perPage,
          },
        },
      };
    default:
      return state;
  }
};
