import React, { FunctionComponent } from 'react'
import { Table } from 'semantic-ui-react'
import { StorePercentageTableBodyProps } from '../../../interfaces/props/dashboards/store-percentage-table-props'
import { StorePercentageTableRow } from './store-percentage-table-row'
import { generateEmptyRow } from '../../table/utils'

export const StorePercentageTableBody: FunctionComponent<StorePercentageTableBodyProps> = (props) => {
  return (
    <Table.Body>
      {
        props.stores.length === 0
          ? generateEmptyRow()
          : props.stores.map((s, idx) => <StorePercentageTableRow openStoreModal={props.openStoreModal} store={s} key={idx}/>)
      }
    </Table.Body>
  );
}
