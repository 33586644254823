export const PAGES = '/dashboard';
export const SIGN_IN = '/signin';
export const PASSWORD_FORGET = '/forgetpassword';
export const DASHBOARD = '/dashboard/main';
export const STORES = '/dashboard/stores';
export const PRODUCTS = '/dashboard/products';
export const CATEGORIES = '/dashboard/categories';
export const REPORT = '/dashboard/report';
export const SALES = '/dashboard/sales';
export const SALESCHANNEL = '/dashboard/saleschannel';
export const POSSETTING = '/dashboard/possetting';
export const USERS = '/dashboard/users';
export const ADDUSER = '/dashboard/users/add';
export const EDITUSER = '/dashboard/users/edit';

export const NOTFOUND = '/404';
export const RESTRICTED = '/403';
