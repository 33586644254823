import Firebase from 'components/Firebase'
import { AuthApiService } from 'services/api/auth-api-service'
import axios, { AxiosResponse } from 'axios'
import { useCallback, useEffect } from 'react'
import { GET_SIMPLE_STORE_NAMES_SUCCESS } from 'actions/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'interfaces/states/redux/root-state'

interface ApiSimpleStore {
  storeId: string;
  storeName: string;
  storeCode: string;
}

const queryStores = (firebase: Firebase) => AuthApiService.withFirebaseToken(firebase, (token) => {
  return axios.get('/store/list', {
    headers: {
      Authorization: token,
    }
  }).then((resp: AxiosResponse<ApiSimpleStore[]>) => resp.data);
})

export const useAllStores = (firebase: Firebase) => {
  const dispatch = useDispatch();
  const stores = useSelector((state: RootState) => state.store.shared.stores)

  const mutate = useCallback(() => {
    queryStores(firebase)
    .then((stores) => {
      dispatch({
        type: GET_SIMPLE_STORE_NAMES_SUCCESS,
        payload: {
          stores: stores,
        },
      });
    })
  }, [firebase, dispatch])

  useEffect(() => {
    mutate();
  }, [mutate]);

  return { data: stores, mutate: mutate };
}