import React, { FunctionComponent } from 'react'
import { Table } from 'semantic-ui-react'
import { InactiveTableRowProps } from '../../../interfaces/props/dashboards/inactive-table-props'
import { formatTime } from '../../../utils/time-formatter'

export const InactiveTableRow: FunctionComponent<InactiveTableRowProps> = (props) => {
  const lastUpdate = props.store.lastOperationUpdate === undefined ? 'N/A' : formatTime(props.store.lastOperationUpdate);

  return (
    <Table.Row className={'clickable'} onClick={() => props.openStoreModal({storeId: props.store.storeId, storeName: props.store.storeName, storeCode: props.store.storeCode})}>
      <Table.Cell>{`${props.store.storeId} ${props.store.storeCode} ${props.store.storeName}`}</Table.Cell>
      <Table.Cell>{lastUpdate}</Table.Cell>
    </Table.Row>
  )
}
