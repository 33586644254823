import React from 'react'

interface CellValueProps {
  unit?: string;
  value: string | number | undefined;
}

export const processSalesValue = (value: string | number | undefined) => {
  if (typeof (value) === 'string') {
    return value
  } else if (typeof (value) === 'number') {
    return value.toLocaleString('en-us',
      { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  } else {
    return ''
  }
}

export const CellValue = (props: CellValueProps) => {
  return props.unit ? <>{props.unit} <span style={{ float: 'right' }}>{processSalesValue(
    props.value)}</span></> : <>{processSalesValue(props.value)}</>
}
