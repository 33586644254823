import {
  CLOSE_DASHBOARD_STORE_MODAL,
  GET_ABSENT_RIDER_STORES_FAIL,
  GET_ABSENT_RIDER_STORES_SUCCESS,
  GET_DASHBOARD,
  GET_DASHBOARD_FAIL,
  GET_DASHBOARD_STORE_DETAILS_ACTIVE_DELIVERIES,
  GET_DASHBOARD_STORE_DETAILS_ACTIVE_DELIVERIES_SUCCESS,
  GET_DASHBOARD_STORE_DETAILS_ACTIVE_INSTORES,
  GET_DASHBOARD_STORE_DETAILS_ACTIVE_INSTORES_SUCCESS,
  GET_DASHBOARD_STORE_DETAILS_EMPLOYEE,
  GET_DASHBOARD_STORE_DETAILS_EMPLOYEE_SUCCESS,
  GET_DASHBOARD_SUCCESS,
  GET_FIRST_PERCENTAGE_STORES_FAIL,
  GET_FIRST_PERCENTAGE_STORES_SUCCESS,
  GET_PENDING_ORDER_STORES_FAIL,
  GET_PENDING_ORDER_STORES_SUCCESS,
  GET_SECOND_PERCENTAGE_STORES_FAIL,
  GET_SECOND_PERCENTAGE_STORES_SUCCESS,
  GET_SERVICE_CALL_STORES_FAIL,
  GET_SERVICE_CALL_STORES_SUCCESS,
  GET_STOP_ORDER_STORES_FAIL,
  GET_STOP_ORDER_STORES_SUCCESS,
  OPEN_DASHBOARD_STORE_MODAL,
  RESET_DASHBOARD,
  SET_DASHBOARD_FILTERS,
} from '../actions/types';
import { DashboardActionTypes } from '../interfaces/actions/dashboard-action-interface';
import { DashboardState } from '../interfaces/states/redux/dashboard-state';

const MODAL_INITIAL_STATE = {
  store: {
    storeId: '',
    storeName: '',
    storeCode: '',
  },
  storeDetails: {
    employees: {
      manager: 0,
      insider: 0,
      rider: 0,
      updated: 0,
    },
    activeDeliveries: [],
    activeInStore: [],
    status: {
      employeeLoading: true,
      activeDeliveriesLoading: true,
      activeInStoreLoading: true,
    },
  },
};

const INITIAL_STATE: DashboardState = {
  list: {
    firstPercentageStores: [],
    secondPercentageStores: [],
    absentRiderStores: [],
    inactiveStores: [],
    stopOrderStores: [],
    serviceCallStores: [],
    pendingOrderStores: [],
    updated: 0,
  },
  filters: {
    om: '',
    dm: '',
    state: '',
  },
  status: {
    list: 'INITIAL',
  },
  modal: MODAL_INITIAL_STATE,
};

export default (
  state = INITIAL_STATE,
  action: DashboardActionTypes,
): DashboardState => {
  switch (action.type) {
    case RESET_DASHBOARD:
      return INITIAL_STATE;
    case SET_DASHBOARD_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case GET_DASHBOARD:
      return {
        ...state,
        status: {
          ...state.status,
          list: 'PROCESSING',
        },
      };
    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          firstPercentageStores: action.payload.ratioOver500Stores,
          secondPercentageStores: action.payload.ratioOver1000Stores,
          inactiveStores: action.payload.inactiveStores,
          absentRiderStores: action.payload.absentRiderStores,
          pendingOrderStores: action.payload.pendingOrderStores,
          stopOrderStores: action.payload.stopOrderStores,
          serviceCallStores: action.payload.serviceCallStores,
          updated: action.payload.updated,
        },
        status: {
          ...state.status,
          list: 'SUCCESS',
        },
      };
    case GET_DASHBOARD_FAIL:
      return {
        ...state,
        status: {
          ...state.status,
          list: 'FAILED',
        },
      };
    case GET_FIRST_PERCENTAGE_STORES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          firstPercentageStores:
            action.payload?.data.stores ?? state.list.firstPercentageStores,
        },
      };
    case GET_FIRST_PERCENTAGE_STORES_FAIL:
      return state;
    case GET_SECOND_PERCENTAGE_STORES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          secondPercentageStores:
            action.payload?.data.stores ?? state.list.firstPercentageStores,
        },
      };
    case GET_SECOND_PERCENTAGE_STORES_FAIL:
      return state;
    case GET_ABSENT_RIDER_STORES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          absentRiderStores:
            action.payload?.data.stores ?? state.list.absentRiderStores,
        },
      };
    case GET_ABSENT_RIDER_STORES_FAIL:
      return state;
    case GET_STOP_ORDER_STORES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          stopOrderStores:
            action.payload?.data.stores ?? state.list.stopOrderStores,
        },
      };
    case GET_STOP_ORDER_STORES_FAIL:
      return state;
    case GET_SERVICE_CALL_STORES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          serviceCallStores:
            action.payload?.data.stores ?? state.list.serviceCallStores,
        },
      };
    case GET_SERVICE_CALL_STORES_FAIL:
      return state;
    case GET_PENDING_ORDER_STORES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          pendingOrderStores:
            action.payload?.data.stores ?? state.list.pendingOrderStores,
        },
      };
    case GET_PENDING_ORDER_STORES_FAIL:
      return state;
    case OPEN_DASHBOARD_STORE_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          store: action.payload.store,
        },
      };
    case CLOSE_DASHBOARD_STORE_MODAL:
      return {
        ...state,
        modal: MODAL_INITIAL_STATE,
      };
    case GET_DASHBOARD_STORE_DETAILS_EMPLOYEE:
      return {
        ...state,
        modal: {
          ...state.modal,
          storeDetails: {
            ...state.modal.storeDetails,
            status: {
              ...state.modal.storeDetails.status,
              employeeLoading: true,
            },
          },
        },
      };
    case GET_DASHBOARD_STORE_DETAILS_ACTIVE_DELIVERIES:
      return {
        ...state,
        modal: {
          ...state.modal,
          storeDetails: {
            ...state.modal.storeDetails,
            status: {
              ...state.modal.storeDetails.status,
              activeDeliveriesLoading: true,
            },
          },
        },
      };
    case GET_DASHBOARD_STORE_DETAILS_ACTIVE_INSTORES:
      return {
        ...state,
        modal: {
          ...state.modal,
          storeDetails: {
            ...state.modal.storeDetails,
            status: {
              ...state.modal.storeDetails.status,
              activeInStoreLoading: true,
            },
          },
        },
      };
    case GET_DASHBOARD_STORE_DETAILS_EMPLOYEE_SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          storeDetails: {
            ...state.modal.storeDetails,
            employees: {
              manager: action.payload.manager,
              insider: action.payload.insider,
              rider: action.payload.rider,
              updated: action.payload.lastUpdate,
            },
            status: {
              ...state.modal.storeDetails.status,
              employeeLoading: false,
            },
          },
        },
      };
    case GET_DASHBOARD_STORE_DETAILS_ACTIVE_DELIVERIES_SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          storeDetails: {
            ...state.modal.storeDetails,
            activeDeliveries:
              action.payload.orders ??
              state.modal.storeDetails.activeDeliveries,
            status: {
              ...state.modal.storeDetails.status,
              activeDeliveriesLoading: false,
            },
          },
        },
      };
    case GET_DASHBOARD_STORE_DETAILS_ACTIVE_INSTORES_SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          storeDetails: {
            ...state.modal.storeDetails,
            activeInStore:
              action.payload.orders ?? state.modal.storeDetails.activeInStore,
            status: {
              ...state.modal.storeDetails.status,
              activeInStoreLoading: false,
            },
          },
        },
      };
    default:
      return state;
  }
};
