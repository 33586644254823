import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";

import "semantic-ui-less/semantic.less";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import store from "./store";
import "./services";
import App from "./App";
import Firebase, { FirebaseContext } from "./components/Firebase";

import "./index.css";

const Component = () => (
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <FirebaseContext.Provider value={new Firebase()}>
        <Router>
          <App />
        </Router>
      </FirebaseContext.Provider> 
    </Provider>
  </I18nextProvider>
);

const render = () => {
  ReactDOM.render(<Component />, document.getElementById("root"));
};

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
// serviceWorker.register();
