import { GetDashboardResponse } from '../../interfaces/requests/dashboards'
import { GetDashboardSuccessActionResponse } from '../../interfaces/dtos/dashboards/get-dashboard'
import {ServiceCallStore, StopOrderStore} from "../../interfaces/entities/dashboards/store";

// const ratioToPercentage = (r: number) => `${(r*100).toFixed(2)}%`;

/**
 * operationType to milliseconds
 * */
const durationMap: Record<string, number> = {
  '1HOUR': 1000 * 60 * 60,
  '45MINS': 1000 * 60 * 45,
  '1.5HOUR': 1000 * 60 * 90,
  '2HOUR': 1000 * 60 * 120,
  '2.5HOUR': 1000 * 60 * 150,
}

const getTargetMillis = (base: number) => (delta: number | undefined) => delta === undefined ? 0 : base + delta;

export const transformDashboard = (data: GetDashboardResponse, updated: number): GetDashboardSuccessActionResponse => {
  const ratioOver500Stores = data.ratioOver500.map(s => ({
    storeName: s.storeName,
    storeCode: s.storeCode,
    storeId: s.storeId,
    ratio: `${s.ratio}%`,
    orderCount: s.orderCount,
    insiderCount: s.insiderCount,
    riderCount: s.riderCount,
  }))

  const ratioOver1000Stores = data.ratioOver1000.map(s => ({
    storeName: s.storeName,
    storeCode: s.storeCode,
    storeId: s.storeId,
    ratio: `${s.ratio}%`,
    orderCount: s.orderCount,
    insiderCount: s.insiderCount,
    riderCount: s.riderCount,
  }))

  const absentRiderStores = data.zeroRider.map(s => ({
    storeName: s.storeName,
    storeCode: s.storeCode,
    storeId: s.storeId,
    since: s.since?.$date ?? 0,
    duration: '', // deprecated
  }))

  const inactiveStores = data.inactiveStore.map(s => ({
    storeName: s.storeName,
    storeCode: s.storeCode,
    storeId: s.storeId,
    lastOperationUpdate: s.lastOperationUpdate?.$date ?? 0,
  }))

  const pendingOrderStores = data.pendingOrders.map(s => ({
    storeName: s.storeName,
    storeCode: s.storeCode,
    storeId: s.storeId,
    deliveryCount: s.deliveryCount,
    carryOutCount: s.carryOutCount,
  }))

  const stopOrderStores: StopOrderStore[] = data.stopOrder.map((s) => ({
    storeName: s.storeName,
    storeId: s.storeId,
    storeCode: s.storeCode,
    since: s.createTimestamp.$date,
    category: s.category,
  }))

  const serviceCallStores = data.serviceCall.map((s): ServiceCallStore => ({
    storeName: s.storeName,
    storeId: s.storeId,
    storeCode: s.storeCode,
    category: s.category,
    operationType: s.operationType,
    until: getTargetMillis(s.createTimestamp.$date)(durationMap[s.operationType]),
  }))

  return {
    ratioOver500Stores: ratioOver500Stores,
    ratioOver1000Stores: ratioOver1000Stores,
    absentRiderStores: absentRiderStores,
    inactiveStores: inactiveStores,
    stopOrderStores: stopOrderStores,
    serviceCallStores: serviceCallStores,
    pendingOrderStores: pendingOrderStores,
    updated: updated,
  }
}
