import axios from 'axios';
import { EXPIRATION } from 'constants/variable';
import { Modal } from 'semantic-ui-react';
import { DEBUG } from '../config';
import * as ROUTES from '../constants/routes';
import * as LocalStorage from '../utils/localstorage';
import { hideLoading, showLoading } from './LoadingActions';
import {
  BACKEND_LOGOUT_USER,
  BACKEND_LOGOUT_USER_FAIL,
  BACKEND_LOGOUT_USER_SUCCESS,
  CREATE_ADMIN,
  CREATE_ADMIN_FAIL,
  CREATE_ADMIN_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  EDIT_ADMIN,
  EDIT_ADMIN_FAIL,
  EDIT_ADMIN_SUCCESS,
  FIREBASE_LOGOUT_USER,
  FIREBASE_LOGOUT_USER_FAIL,
  FIREBASE_LOGOUT_USER_SUCCESS,
  GET_FCM_PERMISSION,
  GET_FCM_PERMISSION_FAIL,
  GET_FCM_PERMISSION_SUCCESS,
  GET_FCM_TOKEN,
  GET_FCM_TOKEN_FAIL,
  GET_FCM_TOKEN_SUCCESS,
  GET_USER_INFO,
  GET_USER_INFO_FAIL,
  GET_USER_INFO_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_FAIL,
  LOGIN_USER_SUCCESS,
  REGISTER_USER_BACKEND,
  REGISTER_USER_BACKEND_FAIL,
  REGISTER_USER_BACKEND_SUCCESS,
  REGISTER_USER_FIREBASE,
  REGISTER_USER_FIREBASE_FAIL,
  REGISTER_USER_FIREBASE_SUCCESS,
  REQUEST_FIREBASE_CHANGE_PASSWORD,
  REQUEST_FIREBASE_CHANGE_PASSWORD_FAIL,
  REQUEST_FIREBASE_CHANGE_PASSWORD_SUCCESS,
  REQUEST_FIREBASE_RESET_PASSWORD,
  REQUEST_FIREBASE_RESET_PASSWORD_FAIL,
  REQUEST_FIREBASE_RESET_PASSWORD_SUCCESS,
  RESET_DASHBOARD,
  RESET_LOGIN_FORM,
  RESET_SESSION,
  RESET_SIGNUP_FORM,
  RESET_STORE,
  RESET_USER,
  START_WEBSOCKET,
  STOP_WEBSOCKET,
  UPDATE_NOTIFICATION_TOKEN,
  UPDATE_NOTIFICATION_TOKEN_FAIL,
  UPDATE_NOTIFICATION_TOKEN_SUCCESS,
  UPLOAD_ADMIN_PROFILE_PICTURE,
  UPLOAD_ADMIN_PROFILE_PICTURE_FAIL,
  UPLOAD_ADMIN_PROFILE_PICTURE_SUCCESS,
} from './types';
import Firebase from '../components/Firebase'

// ----------------------------------------------------------------
// ------------------------- Login --------------------------------
//-----------------------------------------------------------------

export const resetLoginForm = () => {
  return { type: RESET_LOGIN_FORM };
};

export const resetSession = () => {
  return { type: RESET_SESSION };
};

export const loginUser = ({ userName, password }, { firebase, history }) => {
  return async (dispatch, _) => {
    dispatch(showLoading());

    await dispatch(loginViaFirebase(userName, password, firebase))
      .then(() => {
        return dispatch(getUserInfo(firebase, history));
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoading());
        // throw error;
      });
  };
};

// ----------------------------------------------------------------
// ------------------------- FIREBASE LOGIN ---------------------
//-----------------------------------------------------------------

export const loginViaFirebase = (username, password, firebase) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_USER });
    return firebase
      .doSignInWithEmailAndPassword(username.trim(), password.trim())
      .then((response) => loginViaFirebaseSuccess(dispatch, response))
      .catch((error) => loginViaFirebaseFail(dispatch, error));
  };
};

const loginViaFirebaseSuccess = (dispatch, user) => {
  LocalStorage.put(EXPIRATION, Date.now());
  dispatch({
    type: LOGIN_USER_SUCCESS,
    payload: user,
  });
};

const loginViaFirebaseFail = (dispatch, error) => {
  const { code } = error;
  let errorMsg = '';
  switch (code) {
    case 'auth/wrong-password':
    case 'auth/user-not-found':
      errorMsg = 'Email or password is invalid.';
      break;
    default:
      errorMsg = 'An error has occurred.';
  }

  dispatch({
    type: LOGIN_USER_FAIL,
    payload: errorMsg,
  });
  Modal.error({
    title: 'Login Error',
    content: error.message,
  });
  // throw error;
};

// ----------------------------------------------------------------
// ------------------------- BACKEND VALIDATE ---------------------
//-----------------------------------------------------------------

const getUserInfo = (firebase, history) => {
  return (dispatch) => {
    firebase.getIdToken().then(async (token) => {
      dispatch({
        type: GET_USER_INFO,
      });

      try {
        const response = await axios({
          method: 'get',
          url: '/user',
          headers: { Authorization: token },
        });
        return getUserInfoSuccess(dispatch, response, firebase, history);
      } catch (error) {
        console.log(error);
        return getUserInfoFail(dispatch, error);
      }
    });
  };
};

export const getCurrentUser = (firebase, dispatch) =>
  dispatch === undefined
    ? async (dispatch) => getCurrentUserFC(firebase, dispatch)
    : getCurrentUserFC(firebase, dispatch);

const getCurrentUserFC = (firebase, dispatch) => {
  return firebase.getIdToken().then(token => {
    return axios.get('user', {
      headers: {
        Authorization: token,
      },
    }).then((resp) => {
      dispatch({
        type: GET_USER_INFO_SUCCESS,
        payload: resp,
      });
    }).catch((err) => {
      console.error(err);
      dispatch({
        type: GET_USER_INFO_FAIL,
        payload: 'An error occurred.',
      });
    });
  })
}

const getUserInfoSuccess = (dispatch, response, firebase, history) => {
  dispatch(hideLoading());
  console.log(response.data);
  // if(response.data && response.data.userType && response.data.userType.includes('B') && response.data.state === 'A'){
  // TODO: user type checking
  if (response.data && response.data.userType) {
    dispatch({
      type: START_WEBSOCKET,
    });
    history.push(ROUTES.STORES);
  } else {
    // TODO: modal causing error
    /*Modal.error({
			title: 'Login Error',
			content: "Not Allowed"
		});*/
    LocalStorage.remove('authUser');
    firebase.doSignOut();
  }
  dispatch({
    type: GET_USER_INFO_SUCCESS,
    payload: response,
  });
};

const getUserInfoFail = (dispatch, error) => {
  console.error(error)
  dispatch(hideLoading());
  // not axios error
  if (error.response === undefined) {
    dispatch({
      type: GET_USER_INFO_FAIL,
      payload: 'An error occurred.',
    });
    return;
  }

  // handle axios error
  switch (error.response.status) {
    case 403:
      dispatch({
        type: GET_USER_INFO_FAIL,
        payload: 'User not authorized for this realm.',
      });
      break;
    default:
      dispatch({
        type: GET_USER_INFO_FAIL,
        payload: 'An error occurred.',
      });
  }
};

// ----------------------------------------------------------------
// ------------------------- SignUp --------------------------------
//-----------------------------------------------------------------

export const resetSignUpForm = () => {
  return { type: RESET_SIGNUP_FORM };
};

// ----------------------------------------------------------------
// ------------------------- FIREBASE & BACKEND SIGNUP ------------
//-----------------------------------------------------------------

export const signUpUser = (user, { firebase }) => {
  return async (dispatch, getState) => {
    await dispatch(
      registerUserFirebase(
        user.userInfo.email,
        user.userInfo.password,
        firebase,
      ),
    )
      .then(() => {
        const firebaseUser = getState().auth.firebaseUser;
        return dispatch(registerUserBackend(firebase, firebaseUser, user));
      })
      .catch((error) => {
        Modal.error({
          title: 'Add Admin Error',
          content: error.message,
        });
        // throw error;
      });
  };
};

// ----------------------------------------------------------------
// ------------------------- FIREBASE SIGNUP ---------------------
//-----------------------------------------------------------------

export const registerUserFirebase = (email, password, firebase) => {
  return (dispatch) => {
    dispatch({ type: REGISTER_USER_FIREBASE });
    return firebase
      .doCreateUserWithEmailAndPassword(email.trim(), password.trim())
      .then((user) => {
        onRegisterFirebaseSuccess(dispatch, user);
      })
      .catch((error) => onRegisterFirebaseFail(dispatch, error));
  };
};

const onRegisterFirebaseSuccess = (dispatch, user) => {
  dispatch({
    type: REGISTER_USER_FIREBASE_SUCCESS,
    payload: user,
  });
};

const onRegisterFirebaseFail = (dispatch, error) => {
  dispatch({ type: REGISTER_USER_FIREBASE_FAIL });
  throw error;
};

// ----------------------------------------------------------------
// ------------------------- BACKEND SIGNUP ---------------------
//-----------------------------------------------------------------

const registerUserBackend = (firebase, firebaseUser, user) => {
  return (dispatch) => {
    firebase.getIdToken().then(async (token) => {
      dispatch({
        type: REGISTER_USER_BACKEND,
      });

      user.userId = firebaseUser.user.uid;

      try {
        const response = await axios({
          method: 'put',
          url: '/user',
          headers: { Authorization: token },
          data: user,
        });
        return onRegisterBackendSuccess(dispatch, response);
      } catch (error) {
        return onRegisterBackendFail(dispatch, error);
      }
    });
  };
};

const onRegisterBackendSuccess = (dispatch, response) => {
  dispatch({ type: REGISTER_USER_BACKEND_SUCCESS });
};

const onRegisterBackendFail = (dispatch, error) => {
  dispatch({ type: REGISTER_USER_BACKEND_FAIL });
  // throw error;
};

// ----------------------------------------------------------------
// ------------------------- Upload Profile Picture---------------
//-----------------------------------------------------------------

export const uploadAdminProfileImage = (profilePictureInfo, { firebase }) => {
  return async (dispatch, getState) => {
    // const id = getState().listing.newListingInfo.id;
    if (!profilePictureInfo.file) return;

    await firebase.getIdToken().then(async (token) => {
      dispatch({
        type: UPLOAD_ADMIN_PROFILE_PICTURE,
      });

      const formData = new FormData();
      formData.append('picture', profilePictureInfo.file);

      return axios({
        method: 'put',
        url: `/user/profile`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: token,
        },
        data: formData,
      })
        .then((response) => uploadAdminProfileImageSuccess(dispatch, response))
        .catch((error) => uploadAdminProfileImageFail(dispatch, error));
    });
  };
};

const uploadAdminProfileImageSuccess = (dispatch, response) => {
  dispatch({
    type: UPLOAD_ADMIN_PROFILE_PICTURE_SUCCESS,
    payload: response,
  });
};

const uploadAdminProfileImageFail = (dispatch, error) => {
  dispatch({
    type: UPLOAD_ADMIN_PROFILE_PICTURE_FAIL,
    payload: error,
  });
};

// ----------------------------------------------------------------
// ------------------------- Logout --------------------------------
//-----------------------------------------------------------------

export const logoutUser = ({ firebase, history }) => {
  return async (dispatch) => {
    LocalStorage.clear();
    firebase.doSignOut();
    resetStates(dispatch);
    history.push(ROUTES.SIGN_IN);
  };
};

export const resetStates = (dispatch) =>
  dispatch === undefined
    ? async (dispatch) => resetStatesFC(dispatch)
    : resetStatesFC(dispatch);

const resetStatesFC = (dispatch) => {
  dispatch({
    type: RESET_STORE,
  });
  dispatch({
    type: RESET_DASHBOARD,
  });
  dispatch({
    type: RESET_USER,
  });
  dispatch({
    type: RESET_SESSION,
  });
};

export const logoutBackendUser = ({ firebase, history }) => {
  return async (dispatch, getState) => {
    dispatch(backEndLogout(firebase))
      .then(() => {
        return dispatch(firebaseLogoutUser(firebase));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const backEndLogout = (firebase) => {
  const notifToken = LocalStorage.get('notification-token');
  return async (dispatch) => {
    await firebase.getIdToken().then(async (token) => {
      dispatch({
        type: BACKEND_LOGOUT_USER,
      });
      dispatch({
        type: STOP_WEBSOCKET,
      });
      return axios({
        method: 'post',
        url: `/user/logout`,
        headers: { Authorization: token },
        data: { notificationToken: notifToken || '' },
      })
        .then((response) => backEndLogoutSuccess(dispatch, response))
        .catch((error) => backEndLogoutFail(dispatch, error));
    });
  };
};

const backEndLogoutSuccess = (dispatch, response) => {
  dispatch({
    type: BACKEND_LOGOUT_USER_SUCCESS,
    payload: response,
  });
};

const backEndLogoutFail = (dispatch, error) => {
  dispatch({
    type: BACKEND_LOGOUT_USER_FAIL,
    payload: error,
  });
};

const firebaseLogoutUser = (firebase) => {
  return async (dispatch) => {
    dispatch({ type: FIREBASE_LOGOUT_USER });

    await firebase
      .doSignOut()
      .then((response) => firebaseLogoutUserSuccess(dispatch, response))
      .catch((error) => firebaseLogoutUserFail(dispatch, error));
  };
};

const firebaseLogoutUserSuccess = (dispatch, response) => {
  LocalStorage.clear();

  dispatch({
    type: FIREBASE_LOGOUT_USER_SUCCESS,
    payload: response,
  });
};

const firebaseLogoutUserFail = (dispatch, error) => {
  dispatch({ type: FIREBASE_LOGOUT_USER_FAIL });
};

// ----------------------------------------------------------------
// ------------------------- Reset Password --------------------------------
//-----------------------------------------------------------------

export const sendRequestResetPassword = (email, { firebase }) => {
  return async (dispatch) => {
    dispatch(showLoading());

    dispatch({ type: REQUEST_FIREBASE_RESET_PASSWORD });

    await firebase
      .doPasswordReset(email.trim())
      .then((response) => sendRequestResetPasswordSuccess(dispatch, response))
      .catch((error) => sendRequestResetPasswordFail(dispatch, error));
  };
};

const sendRequestResetPasswordSuccess = (dispatch, response) => {
  dispatch(hideLoading());
  Modal.success({
    title: 'Email Sent',
    content: 'Please check email for password reset.',
  });
  dispatch({
    type: REQUEST_FIREBASE_RESET_PASSWORD_SUCCESS,
    payload: response,
  });
};

const sendRequestResetPasswordFail = (dispatch, error) => {
  Modal.error({
    title: 'Request Error',
    content: error.message,
  });
  dispatch(hideLoading());
  dispatch({ type: REQUEST_FIREBASE_RESET_PASSWORD_FAIL });
};

// ----------------------------------------------------------------
// ------------------------- Change Password --------------------------------
//-----------------------------------------------------------------

export const sendRequestChangePassword = (password, { firebase }) => {
  return async (dispatch) => {
    dispatch(showLoading());

    dispatch({ type: REQUEST_FIREBASE_CHANGE_PASSWORD });

    await firebase
      .doPasswordUpdate(password)
      .then((response) => sendRequestChangePasswordSuccess(dispatch, response))
      .catch((error) => sendRequestChangePasswordFail(dispatch, error));
  };
};

const sendRequestChangePasswordSuccess = (dispatch, response) => {
  dispatch(hideLoading());
  Modal.success({
    title: 'Password Updated',
    content: 'Please use the updated password on the next login.',
  });
  dispatch({
    type: REQUEST_FIREBASE_CHANGE_PASSWORD_SUCCESS,
    payload: response,
  });
};

const sendRequestChangePasswordFail = (dispatch, error) => {
  dispatch(hideLoading());
  Modal.error({
    title: 'Reset Error',
    content: error.message,
  });
  dispatch({ type: REQUEST_FIREBASE_CHANGE_PASSWORD_FAIL });
};

// ----------------------------------------------------------------
// ------------------------- Add admin  ---------------------------
//-----------------------------------------------------------------

export const createAdmin = (user, { firebase }) => {
  return async (dispatch) => {
    await firebase.getIdToken().then(async (token) => {
      dispatch({
        type: CREATE_ADMIN,
      });

      return axios({
        method: 'put',
        url: '/user/backend',
        headers: { Authorization: token },
        data: user,
      })
        .then((response) => createAdminSuccess(dispatch, response))
        .catch((error) => createAdminFail(dispatch, error));
    });
  };
};

const createAdminSuccess = (dispatch, response) => {
  dispatch({
    type: CREATE_ADMIN_SUCCESS,
    payload: response,
  });
};

const createAdminFail = (dispatch, error) => {
  dispatch({
    type: CREATE_ADMIN_FAIL,
    payload: error,
  });
};

//--------------------------------------------------------
//---------------EDIT_ADMIN -------------------
//--------------------------------------------------------

export const editAdminAccountInfo = (
  {
    userInfo,
    vehicleConfigInfo,
    listingItemInfo,
    bookingInfo,
    paymentInfo,
    promoProfileInfo,
    user,
    settingInfo,
  },
  { firebase, selectedUser },
) => {
  let userReq = {
    ...selectedUser,
    userRole: userInfo.userRole,
    userInfo: {
      ...selectedUser.userInfo,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      msisdn: userInfo.msisdn,
      dob: { $date: userInfo.dob.valueOf() },
      permissionInfo: {
        vehicleConfigInfo: vehicleConfigInfo.list,
        listingItemInfo: listingItemInfo.list,
        bookingInfo: bookingInfo.list,
        paymentInfo: paymentInfo.list,
        promoProfileInfo: promoProfileInfo.list,
        user: user.list,
        settingInfo: settingInfo.list,
      },
    },
  };

  return async (dispatch) => {
    await firebase.getIdToken().then(async (token) => {
      dispatch({
        type: EDIT_ADMIN,
      });

      return axios({
        method: 'post',
        url: `/user/${selectedUser.userId}`,
        headers: { Authorization: token },
        data: userReq,
      })
        .then((response) => editAdminAccountInfoSuccess(dispatch, response))
        .catch((error) => editAdminAccountInfoFail(dispatch, error));
    });
  };
};

const editAdminAccountInfoSuccess = (dispatch, response) => {
  dispatch({
    type: EDIT_ADMIN_SUCCESS,
    payload: response.data,
  });
};

const editAdminAccountInfoFail = (dispatch, error) => {
  dispatch({
    type: EDIT_ADMIN_FAIL,
    payload: error,
  });
};

// ----------------------------------------------------------------
// ------------------------- delete user  ---------------------------
//-----------------------------------------------------------------

export const deleteUser = (user, { firebase }) => {
  return async (dispatch) => {
    await firebase.getIdToken().then(async (token) => {
      dispatch({
        type: DELETE_USER,
      });

      return axios({
        method: 'delete',
        url: `/user/${user.userId}`,
        headers: { Authorization: token },
      })
        .then((response) => deleteUserSuccess(dispatch, response))
        .catch((error) => deleteUserFail(dispatch, error));
    });
  };
};

const deleteUserSuccess = (dispatch, response) => {
  dispatch({
    type: DELETE_USER_SUCCESS,
    payload: response,
  });
};

const deleteUserFail = (dispatch, error) => {
  dispatch({
    type: DELETE_USER_FAIL,
    payload: error,
  });
};

// ----------------------------------------------------------------
// ------------------------- INIT FCM NOTIFICATION ----------------
//-----------------------------------------------------------------

export const askForPermissioToReceiveNotifications = ({ firebase }) => {
  return async (dispatch, getState) => {
    dispatch(getFCMPermission(firebase))
      .then(() => {
        return dispatch(getTokenAndUpdateBackend(firebase));
      })
      .catch((error) => {
        dispatch({ type: GET_FCM_PERMISSION_FAIL, payload: error });
      });
  };
};

// ----------------------------------------------------------------
// ------------------------- INIT FCM TOKEN ------------------------
//-----------------------------------------------------------------

const getTokenAndUpdateBackend = (firebase) => {
  return async (dispatch, getState) => {
    dispatch(getFCMToken(firebase))
      .then(() => {
        return dispatch(updateUserNotificationToken(firebase));
      })
      .catch((error) => {
        throw error;
      });
  };
};

// ----------------------------------------------------------------
// ------------------------- FCM PERMISSION ---------------------
//-----------------------------------------------------------------

const getFCMPermission = (firebase) => {
  if (firebase.isMessagingSupport()) {
    return async (dispatch) => {
      dispatch({ type: GET_FCM_PERMISSION });
      await firebase
        .FCMrequestPermission()
        .then((response) =>
          dispatch({ type: GET_FCM_PERMISSION_SUCCESS, payload: response }),
        )
        .catch((error) =>
          dispatch({ type: GET_FCM_PERMISSION_FAIL, payload: error }),
        );
    };
  } else {
    return async (dispatch) => {
      throw new Error('Messaging Not Supported');
    };
    // return async dispatch => {
    // 	dispatch({ type: GET_FCM_PERMISSION_FAIL });
    // }
  }
};

// ----------------------------------------------------------------
// ------------------------- FCM TOKEN REQUEST ---------------------
//-----------------------------------------------------------------

const getFCMToken = (firebase) => {
  return async (dispatch) => {
    dispatch({ type: GET_FCM_TOKEN });
    await firebase
      .FCMGetIdToken()
      .then((token) => {
        localStorage.setItem('notification-token', token);
        dispatch({ type: GET_FCM_TOKEN_SUCCESS, payload: token });
        return token;
      })
      .catch((error) => {
        if (error.code === 'messaging/permission-blocked') {
          Modal.error({
            title: 'Please Unblock Notification Request Manually',
            content: error.message,
          });
        } else {
          if (DEBUG)
            Modal.error({
              title: 'Error Occurred',
              content: error.message,
            });
        }
        dispatch({ type: GET_FCM_TOKEN_FAIL, payload: error });
      });
  };
};

// ----------------------------------------------------------------
// -------- BACKEND UPDATE NOTIFICATION TOKEN---------------------
//-----------------------------------------------------------------

export const updateUserNotificationToken = (firebase) => {
  return async (dispatch, getState) => {
    const notificationToken = await getState().auth.notificationToken;
    if (!notificationToken) return;
    firebase.getIdToken().then((token) => {
      dispatch({
        type: UPDATE_NOTIFICATION_TOKEN,
      });

      return axios({
        method: 'post',
        url: '/user/notification',
        headers: { Authorization: token },
        data: { notificationToken, deviceType: 'Web' },
      })
        .then((response) =>
          updateUserNotificationTokenSuccess(dispatch, response),
        )
        .catch((error) => updateUserNotificationTokenFail(dispatch, error));
    });
  };
};

const updateUserNotificationTokenSuccess = (dispatch, response) => {
  dispatch({
    type: UPDATE_NOTIFICATION_TOKEN_SUCCESS,
    payload: response,
  });
};

const updateUserNotificationTokenFail = (dispatch, error) => {
  dispatch({
    type: UPDATE_NOTIFICATION_TOKEN_FAIL,
    payload: error,
  });
};
