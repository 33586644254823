import React from 'react';
import { Table } from 'semantic-ui-react';
import Visibility from "../utils/visibility";

interface OrderInfoTableHeaderProps {
  column?: string;
  direction?: 'ascending' | 'descending';
  userEmail?: any;

  handleSort(column: string): void;
}

export const StoreTableHeader: React.FC<OrderInfoTableHeaderProps> = (
  props,
) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell
        width={1}
        rowSpan="2"
        textAlign="center"
        sorted={props.column === 'om' ? props.direction : undefined}
        onClick={() => props.handleSort('om')}
      >
        OM
      </Table.HeaderCell>
      <Table.HeaderCell
        width={1}
        rowSpan="2"
        textAlign="center"
        sorted={props.column === 'dm' ? props.direction : undefined}
        onClick={() => props.handleSort('dm')}
      >
        DM
      </Table.HeaderCell>
      <Table.HeaderCell
        width={3}
        rowSpan="3"
        textAlign="center"
        sorted={props.column === 'store' ? props.direction : undefined}
        onClick={() => props.handleSort('store')}
      >
        Store
      </Table.HeaderCell>
      <Table.HeaderCell width={1} textAlign={'center'} colSpan="2">
        Order
      </Table.HeaderCell>
      <Table.HeaderCell width={1} textAlign={'center'} colSpan="2">
        On The Road
      </Table.HeaderCell>
      <Table.HeaderCell
        width={1}
        rowSpan="2"
        textAlign="center"
        sorted={props.column === 'insider' ? props.direction : undefined}
        onClick={() => props.handleSort('insiderAndManager')}
      >
        Insider +<br />
        Manager
      </Table.HeaderCell>
      <Table.HeaderCell
        width={1}
        rowSpan="2"
        textAlign="center"
        sorted={props.column === 'rider' ? props.direction : undefined}
        onClick={() => props.handleSort('rider')}
      >
        Rider
      </Table.HeaderCell>
      <Visibility condition={['developer@whenso.com'].includes(props.userEmail)}>
      <Table.HeaderCell
          width={1}
          rowSpan="2"
          textAlign="center"
          sorted={props.column === 'actualRider' ? props.direction : undefined}
          onClick={() => props.handleSort('actualRider')}
      >
        Rider (Hour Labour)
      </Table.HeaderCell>
      </Visibility>
      <Table.HeaderCell width={1} rowSpan="2" />
    </Table.Row>
    <Table.Row>
      <Table.HeaderCell
        textAlign="center"
        sorted={props.column === 'orderDelivery' ? props.direction : undefined}
        onClick={() => props.handleSort('orderDelivery')}
      >
        Delivery
      </Table.HeaderCell>
      <Table.HeaderCell
        textAlign="center"
        sorted={props.column === 'orderCarryOut' ? props.direction : undefined}
        onClick={() => props.handleSort('orderCarryOut')}
      >
        Carry Out
      </Table.HeaderCell>
      <Table.HeaderCell
        textAlign="center"
        sorted={props.column === 'roadRider' ? props.direction : undefined}
        onClick={() => props.handleSort('roadRider')}
      >
        Rider
      </Table.HeaderCell>

      <Table.HeaderCell
        textAlign="center"
        sorted={props.column === 'roadOrder' ? props.direction : undefined}
        onClick={() => props.handleSort('roadOrder')}
      >
        Order
      </Table.HeaderCell>

    </Table.Row>
  </Table.Header>
);
