import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { Pagination, Table } from 'semantic-ui-react';
import { getUserListFC } from '../../../actions/UserActions';
import {
  UserPaginationProps,
  UserTableFooterProps,
} from '../../../interfaces/props/users/user-table-props';
import { generateRecords } from '../../../utils/table/footer';

const handleOnPageChange = (
  index: number,
  firebase: any,
  dispatch: Dispatch,
  pagination: UserPaginationProps,
) => {
  let committing: UserPaginationProps = {
    ...pagination,
  };

  committing.currentPage = index;

  if (pagination.currentPage === committing.currentPage) {
    return;
  }

  getUserListFC(firebase, dispatch, committing);
};

export const UserTableFooter: FunctionComponent<UserTableFooterProps> = (
  props,
) => {
  const dispatch = useDispatch();
  const { totalEntities, totalPage, currentPage, perPage } = props.pagination;
  const records = generateRecords(
    totalEntities === 0,
    currentPage === totalPage,
    totalEntities,
    perPage,
    currentPage,
  );

  return (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan={'100%'}>
          <div
            className={'footer-records'}
          >{`Showing ${records.start} to ${records.end} of ${records.total} records.`}</div>
          <div className={'pull-right'}>
            <Pagination
              totalPages={props.pagination.totalPage}
              firstItem={null}
              lastItem={null}
              activePage={props.pagination.currentPage}
              onPageChange={(e, d) =>
                handleOnPageChange(
                  d.activePage as number,
                  props.firebase,
                  dispatch,
                  props.pagination,
                )
              }
            />
          </div>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  );
};
