import { useDispatch, useSelector } from 'react-redux'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import { AuthApiService } from 'services/api/auth-api-service'
import Firebase from 'components/Firebase'
import SalesApiService from 'services/api/sales-api-service'
import { RootState } from 'interfaces/states/redux/root-state'
import { formatTime } from 'utils/time-formatter'
import { POLLING_RATE } from 'constants/variable'
import SalesAction from 'actions/SalesAction'
import useFilterSalesMutation from "./use-filter-sales-mutation";
import {v4 as uuidV4} from "uuid";

interface Options {
  forceRefresh: boolean;
}

export const useSalesReport = (firebase: Firebase, options?: Options) => {
  const dispatch = useDispatch()
  const interval = useRef<NodeJS.Timeout>()
  const [updateTime, setUpdateTime] = useState(0);
  const data = useSelector(
    (state: RootState) => state.sales.report)

  const filteredInfo = useSelector(
      (state: RootState) => state.sales.filtered)

  const filterHash = useMemo(() => {
    return filteredInfo != null ? filteredInfo.filter + filteredInfo.value + filteredInfo.text : "";
  }, [filteredInfo])

  const { mutation } = useFilterSalesMutation(firebase)

  const queryFilterReport = useCallback((filter: string, value: string, text: string, id: string) => {
    return mutation(filter, value).then((data) => {
      dispatch(SalesAction.GetFilteredReportSuccess(filter, value, text, id, data))
      const now = new Date();
      setUpdateTime(now.getTime());

    }).catch(err => {
      dispatch(SalesAction.GetFilteredReportFail(err))
    })
  }, [])

  const fetchReport = useCallback(() => {
    //dispatch(SalesAction.GetReport())
    const now = new Date();
    setUpdateTime(now.getTime());
    const date = formatTime(now.getTime(), 'DATE_ONLY_VAR1');


    if(filteredInfo != null) {

      const id = uuidV4();
      queryFilterReport(filteredInfo.filter, filteredInfo.value, filteredInfo.text,  id)
          .then(() => {

          })
          .then(() => {

          });


    } else {
      AuthApiService.withFirebaseToken(firebase,
          SalesApiService.getReport(date)).then((data) => {
        dispatch(SalesAction.GetReportSuccess(data))
      }).catch((err) => {
        dispatch(SalesAction.GetReportFail(err))
      })
    }

  }, [dispatch, filterHash, firebase]);

  useEffect(() => {

    if (interval.current) {
      clearTimeout(interval.current);
    }
    fetchReport();
    interval.current = setInterval(() => {
      fetchReport();
    }, POLLING_RATE.THIRTY_SECONDS);

  }, [dispatch, fetchReport, firebase]);

  useEffect(() => {
    return () => {
      if (interval.current) {
        clearTimeout(interval.current);
      }
    }
  }, []);


  return { data, lastUpdated: updateTime }
}
