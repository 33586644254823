import React, { Component } from "react";

import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Header,
  Table,
  Rating,
  Container,
  Button,
  Dropdown,
  Input,
  Icon,
  Segment
} from 'semantic-ui-react';

const options = [
  { key: 1, text: 'Choice 1', value: 1 },
  { key: 2, text: 'Choice 2', value: 2 },
  { key: 3, text: 'Choice 3', value: 3 },
];

class ProductsPageBase extends Component {
  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <>
        <Header size='large'>Products</Header>
        <Segment vertical>
          <Button primary >
            <Icon name='plus' /> Add New Product
        </Button>
          <Button basic >
            Import Product
        </Button>
        </Segment>
        <Segment vertical>
          <Input
            action={
              <Dropdown button basic floating options={options} simple item defaultValue={1} />
            }
            icon='search'
            iconPosition='left'
            placeholder='Search...'
          />
        </Segment>

        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine>Evidence Rating</Table.HeaderCell>
              <Table.HeaderCell>Effect</Table.HeaderCell>
              <Table.HeaderCell>Efficacy</Table.HeaderCell>
              <Table.HeaderCell>Consensus</Table.HeaderCell>
              <Table.HeaderCell>Comments</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Header as='h2' textAlign='center'>
                  A
          </Header>
              </Table.Cell>
              <Table.Cell singleLine>Power Output</Table.Cell>
              <Table.Cell>
                <Rating icon='star' defaultRating={3} maxRating={3} />
              </Table.Cell>
              <Table.Cell textAlign='right'>
                80% <br />
                <a href='#'>18 studies</a>
              </Table.Cell>
              <Table.Cell>
                Creatine supplementation is the reference compound for increasing
                muscular creatine levels; there is variability in this increase,
                however, with some nonresponders.
        </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as='h2' textAlign='center'>
                  A
          </Header>
              </Table.Cell>
              <Table.Cell singleLine>Weight</Table.Cell>
              <Table.Cell>
                <Rating icon='star' defaultRating={3} maxRating={3} />
              </Table.Cell>
              <Table.Cell textAlign='right'>
                100% <br />
                <a href='#'>65 studies</a>
              </Table.Cell>
              <Table.Cell>
                Creatine is the reference compound for power improvement, with numbers
                from one meta-analysis to assess potency
        </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </>
    );
  }
}

const mapStateToProps = (props) => {
  return props;
};

const ProductsPage = compose(
  withRouter,
  connect(mapStateToProps, null)
)(ProductsPageBase);

export default ProductsPage;
