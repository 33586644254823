export const userTypeDictionary: { [key: string]: string } = {
  OM: 'OM',
  DM: 'DM',
  AD: 'ADMIN',
  CSC_AD: 'CSC ADMIN',
  CSC_USR: 'CSC USER',
  OP: 'OPERATION'
};

export const platformDictionary: { [key: string]: string } = {
  W: 'Web',
  C: 'Customer Service',
  O: 'Over the Counter',
  FP: 'Food Panda',
  GF: 'Grab Food',
};

export const userSortKeyDictionary: { [key: string]: string } = {
  Nickname: 'nickname',
  Email: 'email',
  'Full Name': 'fullName',
};
