import React from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import { IDEAL_ITEM_COUNT } from '../../constants/variable';
import { StoreTableRowProps } from '../../interfaces/props/stores/store-table-props';
import Visibility from "../utils/visibility";
import {CellValue} from "../sales/table/cell-value";

const calculateRiderSymptomRatio = (
  orderCount: number,
  employeeCount: number,
) => orderCount / employeeCount;
const calculateInsiderSymptomRatio = (
  makelineItemCount: number,
  insiderAndManagerCount: number,
) => makelineItemCount / IDEAL_ITEM_COUNT / insiderAndManagerCount;

export const StoreTableRow: React.FC<StoreTableRowProps> = (props) => {
  // ratios
  const riderSymptomRatio = calculateRiderSymptomRatio(
    props.store.deliveryCount,
    props.store.riderCount,
  );
  const insiderSymptomRatio = calculateInsiderSymptomRatio(
    props.store.makelineItemCount,
    props.store.insiderAndManagerCount,
  );

  // conditions
  const is150SymptomRatio = (ratio: number) => ratio >= 1.5 && ratio < 1.75;
  const is175SymptomRatio = (ratio: number) => ratio >= 1.75 && ratio < 2;
  const is200SymptomRatio = (ratio: number) => ratio >= 2;

  return (
    <Table.Row>
      <Table.Cell>{props.store.OM}</Table.Cell>
      <Table.Cell>{props.store.DM}</Table.Cell>
      <Table.Cell>
        <div
          style={{ whiteSpace: 'nowrap' }}
        >{`${props.store.storeId} ${props.store.storeCode} ${props.store.storeName}`}</div>
      </Table.Cell>
      <Table.Cell textAlign={'center'}>{props.store.deliveryCountExcludeOTR}</Table.Cell>
      <Table.Cell textAlign={'center'}>{props.store.carryOutCount}</Table.Cell>
      <Table.Cell textAlign={'center'}>{props.store.otrRiderCount}</Table.Cell>
      <Table.Cell textAlign={'center'}>{props.store.otrOrderCount}</Table.Cell>
      <Table.Cell
        textAlign={'center'}
        warning={is175SymptomRatio(insiderSymptomRatio)}
        negative={is200SymptomRatio(insiderSymptomRatio)}
        positive={is150SymptomRatio(insiderSymptomRatio)}
      >
        {props.store.insiderAndManagerCount}
      </Table.Cell>
      <Table.Cell
        textAlign={'center'}
        warning={is175SymptomRatio(riderSymptomRatio)}
        negative={is200SymptomRatio(riderSymptomRatio)}
        positive={is150SymptomRatio(riderSymptomRatio)}
      >
        {props.store.riderCount}
      </Table.Cell>
      <Visibility condition={['developer@whenso.com'].includes(props.userEmail)}>
      <Table.Cell
        textAlign={'center'}>
          <CellValue value={props.store.actualRider}></CellValue>
      </Table.Cell>
      </Visibility>
      <Table.Cell>
        <Button icon title={'details'} onClick={() => props.openOrderModal()}>
          <Icon name={'book'} />
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};
