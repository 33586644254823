import { TableHeader } from 'components/table/table-header';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { DeliveryOrder } from '../../../interfaces/entities/stores/modal/order';
import { formatTime } from '../../../utils/time-formatter';
import { generateEmptyRow } from '../../table/utils';
import { OrderDetails } from './store-details-modal';

const headers = [
  'Order Number',
  'Platform',
  'Customer Name',
  'Contact No',
  'Timed/Now',
  'Makeline Items',
  'Order Time',
  'Delivery Time',
  'OTD Time',
  'ETA Time',
  'Rider',
  'Order Status',
];

export const DeliveryOrders = ({
  activeDeliveries,
  setOrder,
}: {
  activeDeliveries: DeliveryOrder[];
  setOrder: (order: OrderDetails | undefined) => (e: any, d: any) => void;
}) => (
  <Table unstackable celled selectable sortable structured>
    <TableHeader headers={headers} alignment={'center'} />
    <Table.Body>
      {activeDeliveries.length === 0
        ? generateEmptyRow()
        : activeDeliveries.map((d, idx) => (
            <DeliveryOrderRow key={idx} delivery={d} setOrder={setOrder} />
          ))}
    </Table.Body>
  </Table>
);

const processTime = (millis: number) =>
  millis === 0 ? 'N/A' : formatTime(millis, 'TIME_ONLY');

const DeliveryOrderRow = ({
  delivery,
  setOrder,
}: {
  delivery: DeliveryOrder;
  setOrder: (order: OrderDetails | undefined) => (e: any, d: any) => void;
}) => (
  <Table.Row
    className={'clickable'}
    onClick={setOrder({
      orderNumber: delivery.orderId,
      activities: delivery.activityInfo,
    })}
  >
    <Table.Cell>{delivery.orderId}</Table.Cell>
    <Table.Cell>{delivery.platform}</Table.Cell>
    <Table.Cell>{delivery.customerName}</Table.Cell>
    <Table.Cell>{delivery.contact}</Table.Cell>
    <Table.Cell>{delivery.timedNow}</Table.Cell>
    <Table.Cell>{delivery.pieCount}</Table.Cell>
    <Table.Cell>{processTime(delivery.orderTime)}</Table.Cell>
    <Table.Cell>{processTime(delivery.deliveryTime)}</Table.Cell>
    <Table.Cell>{processTime(delivery.dispatchTime)}</Table.Cell>
    <Table.Cell>{processTime(delivery.ETA)}</Table.Cell>
    <Table.Cell>{delivery.rider}</Table.Cell>
    <Table.Cell>{delivery.status}</Table.Cell>
  </Table.Row>
);
