import { SalesActionType } from 'interfaces/actions/sales-action-interface'
import FilteredInfo from 'interfaces/dtos/sales/filtered-info'
import ApiReportData from "../interfaces/apis/sales/report-data";

type ApiStatus = 'DEFAULT' | 'LOADING' | 'SUCCESS' | 'ERROR';

export interface SalesState {
  report: ApiReportData | undefined;
  filtered: FilteredInfo | undefined;
//  filtered: FilteredInfo[];
  status: {
    report: ApiStatus;
    filtered: ApiStatus;
  }
}

const INITIAL_STATE: SalesState = {
  report: undefined,
  filtered: undefined,
  status: {
    report: 'DEFAULT',
    filtered: 'DEFAULT'
  }
}

export default (state = INITIAL_STATE, action: SalesActionType) => {
  switch (action.type) {
    case 'GET_REPORT':
      return {
        ...state,
        status: {
          ...state.status,
          report: 'LOADING',
        },
      }
    case 'GET_REPORT_SUCCESS':
      return {
        ...state,
        report: action.payload,
        status: {
          ...state.status,
          report: 'SUCCESS',
        }
      }
    case 'RESET_REPORT':
      return {
        ...state,
        report: undefined,
        filtered: undefined,
        status: {
          ...state.status,
          report: 'DEFAULT',
        },
      }
    case 'GET_FILTERED_REPORT_SUCCESS':

      // const index = state.filtered.findIndex((v) => v.id === action.payload.id);
      // const now = new Date();
      // if (index >= 0) {
      //   return {
      //     ...state,
      //     filtered: replace(state.filtered, index, { ...action.payload, lastUpdate: now.getTime() }),
      //   }
      // }
      //
      // return {
      //   ...state,
      //   filtered: [...state.filtered, { ...action.payload, lastUpdate: now.getTime() }],
      // }

      return {
        ...state,
        report: action.payload.report,
        filtered : {
          filter: action.payload.filter,
          value: action.payload.value,
          text: action.payload.text,
          id: action.payload.id
        },
        status: {
          ...state.status,
          report: 'SUCCESS',
        }
      }

    case 'REMOVE_FILTERED_REPORT':
      return {
        ...state,
        filtered: undefined
      };
    case 'RESET_FILTERED_REPORT':
      return {
        ...state,
        filtered: undefined
      };
    default:
      return state;
  }
}
