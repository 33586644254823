import React, { Component } from "react";

import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react';
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withFirebase } from '../../components/Firebase'
import { withAuthorization } from '../../components/Session'

class NotFoundPageBase extends Component {

  render() {
    return (
      <Segment
        textAlign='center'
        style={{ minHeight: 700, padding: '1em 0em' }}
        vertical
      >
        <Container text>
          <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column >
              <Header
                as='h1'
                content='404'
                style={{
                  fontSize: '4em',
                  fontWeight: 'normal',
                  marginBottom: 0,
                }}
              />
              <Header
                as='h2'
                content='Page not Found'
                style={{
                  fontSize: '1.7em',
                  fontWeight: 'normal',
                }}
              />
            </Grid.Column>
          </Grid>
        </Container>
      </Segment>
    );
  }
}

const NotFoundPage = compose(
  withRouter
)(NotFoundPageBase);

export default NotFoundPage;
