import React, { FunctionComponent, useMemo, useState } from 'react'
import { Table } from 'semantic-ui-react'
import { ServiceCallTableRowProps } from 'interfaces/props/dashboards/service-call-table-props'
import useInterval from 'hooks/utils/use-interval'

const prefixed = (data: string) => (isNegative: boolean) => isNegative ? `-${data}` : data;

const getTextColor = (isCountUp: boolean) => isCountUp ? 'red' : 'black';

export const ServiceCallTableRow: FunctionComponent<ServiceCallTableRowProps> = (props) => {
  const { store: { until } } = props;
  const [difference, setDifference] = useState(0);
  const isCountUp = useMemo(() => difference < 0 , [difference])
  const duration = useMemo(() => new Date(difference < 0 ? difference * -1 : difference).toISOString().substr(11, 8), [difference]);
  useInterval(1000, () => {
    if (until === 0) return;
    setDifference(until - Date.now());
  })

  return <Table.Row>
    <Table.Cell>{`${props.store.storeId} ${props.store.storeCode} ${props.store.storeName}`}</Table.Cell>
    <Table.Cell>{props.store.operationType}</Table.Cell>
    <Table.Cell>{props.store.category}</Table.Cell>
    <Table.Cell style={{ color: until === 0 ? 'black' : getTextColor(isCountUp) }}>{until !== 0 ? prefixed(duration)(isCountUp) : 'INVALID'}</Table.Cell>
  </Table.Row>
}
