import { Container, Grid, Header, Segment } from 'semantic-ui-react'
import { compose } from 'recompose';
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class RestrictedPageBase extends Component {
  render() {
    return (
    <Segment
      textAlign='center'
      style={{ minHeight: 700, padding: '1em 0em' }}
      vertical
    >
      <Container text>
        <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
          <Grid.Column >
            <Header
              as='h1'
              content='403'
              style={{
                fontSize: '4em',
                fontWeight: 'normal',
                marginBottom: 0,
              }}
            />
            <Header
              as='h2'
              content='Forbidden'
              style={{
                fontSize: '1.7em',
                fontWeight: 'normal',
              }}
            />
          </Grid.Column>
        </Grid>
      </Container>
    </Segment>
    );
  }
}

const RestrictedPage = compose(
  withRouter
)(RestrictedPageBase);

export default RestrictedPage;
