import { OrderResponse, SimpleStore } from "interfaces/requests/stores";

export const orderComparer = (a: OrderResponse, b: OrderResponse) => {
  const orderAId = a.orderId ?? "0";
  const orderBId = b.orderId ?? "0";

  const aIdNum = parseInt(orderAId);
  const bIdNum = parseInt(orderBId);

  return aIdNum - bIdNum;
};

export const simpleStoreComparer = (a: SimpleStore, b: SimpleStore) => {
  const storeAId = a.storeId;
  const storeBId = b.storeId;

  const aIdNum = parseInt(storeAId);
  const bIdNum = parseInt(storeBId);

  return aIdNum - bIdNum;
};

export const stringIdComparer = (a: string, b: string) => {
  const aNum = parseInt(a);
  const bNum = parseInt(b);

  return aNum - bNum;
};
