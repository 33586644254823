import React from 'react'
import { Table } from "semantic-ui-react"

export const generateHeaderCells = (headers: string[]) => {
  return headers.map((hc, idx) => <Table.HeaderCell key={idx}>{hc}</Table.HeaderCell>)
}

export const generateEmptyRow = () => {
  return (
    <Table.Row>
      <Table.Cell textAlign={'center'} colSpan={'100%'}>No Results</Table.Cell>
    </Table.Row>
  )
}
