export const SHOW_LOADING = 'LOADING/SHOW';
export const HIDE_LOADING = 'LOADING/HIDE';

export const SET_SIDEBAR_VISIBILITY = 'set_sidebar_visibility';

export const NEXT_STEP = 'NEXT_STEP';
export const PREV_STEP = 'PREV_STEP';
export const GO_TO_STEP = 'GO_TO_STEP';

export const UPDATE_TASK_LOG = 'UPDATE_TASK_LOG';
export const UPDATE_MOCK_CARRY_OUT_INFO = 'UPDATE_MOCK_CARRY_OUT_INFO';

// -----------------------------------------------------------------------
// ---------AUTH----------------------------------------------------------
// -----------------------------------------------------------------------
export const INVALIDATE_SESSION = 'invalidate_session';
export const RESET_SESSION = 'reset_session';

export const RESET_LOGIN_FORM = 'reset_login_form';

export const LOGIN_USER = 'login_user';
export const LOGIN_USER_SUCCESS = 'login_user_success';
export const LOGIN_USER_FAIL = 'login_user_fail';

export const RESET_SIGNUP_FORM = 'reset_signup_form';

export const REGISTER_USER_FIREBASE = 'register_user_firebase';
export const REGISTER_USER_FIREBASE_SUCCESS = 'register_user_firebase_success';
export const REGISTER_USER_FIREBASE_FAIL = 'register_user_firebase_fail';

export const REGISTER_USER_BACKEND = 'register_user_backend';
export const REGISTER_USER_BACKEND_SUCCESS = 'register_user_backend_success';
export const REGISTER_USER_BACKEND_FAIL = 'register_user_backend_fail';

export const CREATE_ADMIN = 'create_admin';
export const CREATE_ADMIN_SUCCESS = 'create_admin_success';
export const CREATE_ADMIN_FAIL = 'create_admin_fail';

export const EDIT_ADMIN = 'EDIT_admin';
export const EDIT_ADMIN_SUCCESS = 'EDIT_admin_success';
export const EDIT_ADMIN_FAIL = 'EDIT_admin_fail';

export const DELETE_USER_RESET = 'delete_user_reset';
export const DELETE_USER = 'delete_user';
export const DELETE_USER_SUCCESS = 'delete_user_success';
export const DELETE_USER_FAIL = 'delete_user_fail';

export const GET_USER_INFO = 'get_user_info';
export const GET_USER_INFO_SUCCESS = 'get_user_info_success';
export const GET_USER_INFO_FAIL = 'get_user_info_fail';

export const REQUEST_FIREBASE_RESET_PASSWORD =
  'request_firebase_reset_password';
export const REQUEST_FIREBASE_RESET_PASSWORD_SUCCESS =
  'request_firebase_reset_password_success';
export const REQUEST_FIREBASE_RESET_PASSWORD_FAIL =
  'request_firebase_reset_password_fail';

export const REQUEST_FIREBASE_CHANGE_PASSWORD =
  'request_firebase_change_password';
export const REQUEST_FIREBASE_CHANGE_PASSWORD_SUCCESS =
  'request_firebase_change_password_success';
export const REQUEST_FIREBASE_CHANGE_PASSWORD_FAIL =
  'request_firebase_change_password_fail';

export const UPLOAD_ADMIN_PROFILE_PICTURE = 'upload_admin_profile_picture';
export const UPLOAD_ADMIN_PROFILE_PICTURE_SUCCESS =
  'upload_admin_profile_picture_success';
export const UPLOAD_ADMIN_PROFILE_PICTURE_FAIL =
  'upload_admin_profile_picture_fail';

export const BACKEND_LOGOUT_USER = 'backend_logout_user';
export const BACKEND_LOGOUT_USER_SUCCESS = 'backend_logout_user_success';
export const BACKEND_LOGOUT_USER_FAIL = 'backend_logout_user_fail';

export const FIREBASE_LOGOUT_USER = 'firebase_logout_user';
export const FIREBASE_LOGOUT_USER_SUCCESS = 'firebase_logout_user_success';
export const FIREBASE_LOGOUT_USER_FAIL = 'firebase_logout_user_fail';

// -----------------------------------------------------------------------
// ---------NOTIFICATION---------------------------------------------------
// -----------------------------------------------------------------------

export const GET_NOTIFICATION_INFO_LIST = 'get_notification_info_list';
export const GET_NOTIFICATION_INFO_LIST_SUCCESS =
  'get_notification_info_list_success';
export const GET_NOTIFICATION_INFO_LIST_FAIL =
  'get_notification_info_list_fail';

export const GET_FCM_PERMISSION = 'get_fcm_permission';
export const GET_FCM_PERMISSION_SUCCESS = 'get_fcm_permission_success';
export const GET_FCM_PERMISSION_FAIL = 'get_fcm_permission_fail';

export const GET_FCM_TOKEN = 'get_fcm_token';
export const GET_FCM_TOKEN_SUCCESS = 'get_fcm_token_success';
export const GET_FCM_TOKEN_FAIL = 'get_fcm_token_fail';

export const UPDATE_NOTIFICATION_TOKEN = 'update_notification_token';
export const UPDATE_NOTIFICATION_TOKEN_SUCCESS =
  'update_notification_token_success';
export const UPDATE_NOTIFICATION_TOKEN_FAIL = 'update_notification_token_fail';

// ------------------------------------------------------------------------
// --------- STORE LIST ---------------------------------------------------
// ------------------------------------------------------------------------
export const RESET_STORE = 'reset_store';

export const GET_STORE_INFO_LIST = 'get_store_info_list';
export const GET_STORE_INFO_LIST_SUCCESS = 'get_store_info_list_success';
export const GET_STORE_INFO_LIST_FAIL = 'get_store_info_list_fail';

export const GET_STORE_DETAILS = 'get_store_details';
export const OPEN_STORE_MODAL = 'open_store_modal';
export const CLOSE_STORE_MODAL = 'close_store_modal';

export const GET_STORE_DETAILS_EMPLOYEE = 'get_store_details_employee';
export const GET_STORE_DETAILS_EMPLOYEE_SUCCESS =
  'get_store_details_employee_success';
export const GET_STORE_DETAILS_EMPLOYEE_FAIL =
  'get_store_details_employee_fail';

export const GET_STORE_DETAILS_ACTIVE_DELIVERIES =
  'get_store_details_active_deliveries';
export const GET_STORE_DETAILS_ACTIVE_DELIVERIES_SUCCESS =
  'get_store_details_active_deliveries_success';
export const GET_STORE_DETAILS_ACTIVE_DELIVERIES_FAIL =
  'get_store_details_active_deliveries_fail';

export const GET_STORE_DETAILS_ACTIVE_INSTORE =
  'get_store_details_active_instore';
export const GET_STORE_DETAILS_ACTIVE_INSTORE_SUCCESS =
  'get_store_details_active_instore_success';
export const GET_STORE_DETAILS_ACTIVE_INSTORE_FAIL =
  'get_store_details_active_instore_fail';

export const STORE_UPDATE_PER_PAGE = 'store_update_per_page';
export const FILTER_STORES = 'filter_stores';
export const SORT_STORES = 'sort_stores';

export const GET_SIMPLE_STORE_NAMES = 'get_simple_store_names';
export const GET_SIMPLE_STORE_NAMES_SUCCESS = 'get_simple_store_names_success';
export const GET_SIMPLE_STORE_NAMES_FAIL = 'get_simple_store_names_failed';

export const EDIT_USER = 'edit_user';
export const EDIT_USER_SUCCESS = 'edit_user_success';
export const EDIT_USER_FAIL = 'edit_user_fail';
export const EDIT_USER_RESET = 'edit_user_reset';

export const EDIT_GET_USER = 'edit_get_user';
export const EDIT_GET_USER_SUCCESS = 'edit_get_user_success';
export const EDIT_GET_USER_FAIL = 'edit_get_user_fail';
export const EDIT_GET_USER_RESET = 'edit_get_user_reset';

export const EDIT_USER_REALM = 'edit_user_realm';
export const EDIT_USER_REALM_SUCCESS = 'edit_user_realm_success';
export const EDIT_USER_REALM_FAIL = 'edit_user_realm_fail';
export const RESET_EDIT_USER_REALM = 'reset_edit_user_realm';

export const GET_ORDER_STATUS = 'get_order_status';
export const GET_ORDER_STATUS_SUCCESS = 'get_order_status_success';
export const GET_ORDER_STATUS_FAIL = 'get_order_status_fail';

export const GET_CUSTOMER_ARRIVED_STATUS = 'get_customer_arrived_status';
export const GET_CUSTOMER_ARRIVED_STATUS_SUCCESS =
  'get_customer_arrived_status_success';
export const GET_CUSTOMER_ARRIVED_STATUS_FAIL =
  'get_customer_arrived_status_fail';

export const UPDATE_CUSTOMER_VEHICLE_DETAILS =
  'update_customer_vehicle_details_status';
export const UPDATE_CUSTOMER_VEHICLE_DETAILS_SUCCESS =
  'update_customer_vehicle_details_status_success';
export const UPDATE_CUSTOMER_VEHICLE_DETAILS_FAIL =
  'update_customer_vehicle_details_status_fail';

export const ORDER_NOT_RECEIVED_COMPLETED = 'order_not_received_completed';
export const ORDER_NOT_RECEIVED_COMPLETED_SUCCESS =
  'order_not_received_completed_success';
export const ORDER_NOT_RECEIVED_COMPLETED_FAIL =
  'order_not_received_completed_fail';

export const CUSTOMER_CLICKED_DRIVEUP = 'customer_clicked_driveup';
export const CUSTOMER_CLICKED_DRIVEUP_SUCCESS =
  'customer_clicked_driveup_success';
export const CUSTOMER_CLICKED_DRIVEUP_FAIL = 'customer_clicked_driveup_fail';

export const CUSTOMER_CLICKED_WALKUP = 'customer_clicked_walkup';
export const CUSTOMER_CLICKED_WALKUP_SUCCESS =
  'customer_clicked_walkup_success';
export const CUSTOMER_CLICKED_WALKUP_FAIL = 'customer_clicked_walkup_fail';

export const GET_CHECK_STORE_CODE = 'get_check_store_code';
export const GET_CHECK_STORE_CODE_SUCCESS = 'get_check_store_code_success';
export const GET_CHECK_STORE_CODE_FAIL = 'get_check_store_code_fail';

export const CLAIM_MANAGER_CODE = 'claim_manager_code';
export const CLAIM_MANAGER_CODE_SUCCESS = 'claim_manager_code_success';
export const CLAIM_MANAGER_CODE_FAIL = 'claim_manager_code_fail';

// -----------------------------------------------------------------------
// ---------WEBSOCKET---------------------------------------------------
// -----------------------------------------------------------------------
export const START_WEBSOCKET = 'start_websocket';
export const STOP_WEBSOCKET = 'stop_websocket';

export const CARRYOUT_ORDER_UPDATED = 'carryout_order_updated';

export const CUSTOMER_ORDER_SENT = 'customer_order_sent';
export const CUSTOMER_ORDER_FINISH = 'customer_order_finish';

export const REFRESH = 'REFRESH';

// -----------------------------------------------------------------------
// ---------------USERS---------------------------------------------------
// -----------------------------------------------------------------------
export const RESET_USER = 'reset_user';

export const GET_USER_LIST = 'get_user_list';
export const GET_USER_LIST_SUCCESS = 'get_user_list_success';
export const GET_USER_LIST_FAIL = 'get_user_list_fail';

export const FILTER_USER_LIST = 'filter_user_list';

export const GET_USER_TYPES = 'get_user_types';
export const GET_USER_TYPES_SUCCESS = 'get_user_types_success';
export const GET_USER_TYPES_FAIL = 'get_user_types_fail';

export const GET_SUPERIORS = 'get_superiors';
export const GET_SUPERIORS_SUCCESS = 'get_superiors_success';
export const GET_SUPERIORS_FAIL = 'get_superiors_fail';

export const GET_SUBORDINATES = 'get_subordinates';
export const GET_SUBORDINATES_SUCCESS = 'get_subordinates_success';
export const GET_SUBORDINATES_FAIL = 'get_subordinates_fail';

export const ADD_USER = 'add_user';
export const ADD_USER_SUCCESS = 'add_user_success';
export const ADD_USER_FAIL = 'add_user_fail';
export const ADD_USER_RESET = 'add_user_reset';

export const USER_UPDATE_PER_PAGE = 'user_update_per_page';

export const RESET_TOGGLE_USER_ACTIVATION = 'reset_toggle_user_activation';
export const TOGGLE_USER_ACTIVATION = 'toggle_user_activation';
export const TOGGLE_USER_ACTIVATION_SUCCESS = 'toggle_user_activation_success';
export const TOGGLE_USER_ACTIVATION_FAIL = 'toggle_user_activation_fail';

export const SORT_USERS = 'sort_users';

// -----------------------------------------------------------------------
// ---------------DASHBOARD-----------------------------------------------
// -----------------------------------------------------------------------
export const GET_FIRST_PERCENTAGE_STORES = 'get_first_percentage_stores';
export const GET_FIRST_PERCENTAGE_STORES_SUCCESS =
  'get_first_percentage_stores_success';
export const GET_FIRST_PERCENTAGE_STORES_FAIL =
  'get_first_percentage_stores_fail';

export const GET_SECOND_PERCENTAGE_STORES = 'get_second_percentage_stores';
export const GET_SECOND_PERCENTAGE_STORES_SUCCESS =
  'get_second_percentage_stores_success';
export const GET_SECOND_PERCENTAGE_STORES_FAIL =
  'get_second_percentage_stores_fail';

export const GET_ABSENT_RIDER_STORES = 'get_absent_rider_stores';
export const GET_ABSENT_RIDER_STORES_SUCCESS =
  'get_absent_rider_stores_success';
export const GET_ABSENT_RIDER_STORES_FAIL = 'get_absent_rider_stores_fail';

export const GET_STOP_ORDER_STORES = 'get_stop_order_stores';
export const GET_STOP_ORDER_STORES_SUCCESS = 'get_stop_order_stores_success';
export const GET_STOP_ORDER_STORES_FAIL = 'get_stop_order_stores_fail';

export const GET_SERVICE_CALL_STORES = 'get_service_call_stores';
export const GET_SERVICE_CALL_STORES_SUCCESS =
  'get_service_call_stores_success';
export const GET_SERVICE_CALL_STORES_FAIL = 'get_service_call_stores_fail';

export const GET_PENDING_ORDER_STORES = 'get_pending_order_stores';
export const GET_PENDING_ORDER_STORES_SUCCESS =
  'get_pending_order_stores_success';
export const GET_PENDING_ORDER_STORES_FAIL = 'get_pending_order_stores_fail';

export const GET_DASHBOARD = 'get_dashboard';
export const GET_DASHBOARD_SUCCESS = 'get_dashboard_success';
export const GET_DASHBOARD_FAIL = 'get_dashboard_fail';

export const SET_DASHBOARD_FILTERS = 'set_dashboard_filters';

export const OPEN_DASHBOARD_STORE_MODAL = 'open_dashboard_store_modal';
export const CLOSE_DASHBOARD_STORE_MODAL = 'close_dashboard_store_modal';

export const GET_DASHBOARD_STORE_DETAILS = 'get_dashboard_store_details';
export const GET_DASHBOARD_STORE_DETAILS_EMPLOYEE =
  'get_dashboard_store_details_employee';
export const GET_DASHBOARD_STORE_DETAILS_EMPLOYEE_SUCCESS =
  'get_dashboard_store_details_employee_success';
export const GET_DASHBOARD_STORE_DETAILS_ACTIVE_INSTORES =
  'get_dashboard_store_details_active_instores';
export const GET_DASHBOARD_STORE_DETAILS_ACTIVE_INSTORES_SUCCESS =
  'get_dashboard_store_details_active_instores_success';
export const GET_DASHBOARD_STORE_DETAILS_ACTIVE_DELIVERIES =
  'get_dashboard_store_details_active_deliveries';
export const GET_DASHBOARD_STORE_DETAILS_ACTIVE_DELIVERIES_SUCCESS =
  'get_dashboard_store_details_active_deliveries_success';

export const RESET_DASHBOARD = 'reset_dashboard';

// public routes
export const DO_PASSWORD_RESET = 'do_password_reset';
export const DO_PASSWORD_RESET_SUCCESS = 'do_password_rese_successt';
export const DO_PASSWORD_RESET_FAIL = 'do_password_reset_fail';
export const RESET_DO_PASSWORD_RESET = 'reset_do_password_reset';
